import axios from "axios";

import { CONFIG } from "../common/consts";
import { commonJs } from "../common/scripts";

export const API_HANDLER = {
  // User Login
  login: async (args = {}) => {
    // args = {
    //   uname: "test123",
    //   password: "123456",
    // };

    const response = await commonJs.callApi("/login", "POST", args);
    return response;

    // return {
    //   success: true,
    //   response: {
    //     accessToken: "65456464444",
    //     user: {
    //       id: 1234,
    //       name: "",
    //       role: "admin1",
    //     },
    //   },
    //   reason: "",
    // };
  },

  // User Session End
  logout: async () => {
    const response = await commonJs.callApi("/logout", "GET", {});
    return response;

    // return {
    //   success: true,
    //   reason: "",
    //   response: {},
    // };
  },

  // Check Login
  checklogin: async (args = {}) => {
    const response = await commonJs.callApi(
      "/checklogin",
      CONFIG.API_METHODS.POST,
      args
    );
    return response;
  },

  // Get States List
  // SQL Query: "SELECT MGL_STATE_HDRID,STATE_NAME,STATE_CODE FROM mgl_state_hdr WHERE ACTIVE='T' ORDER BY STATE_NAME;"
  getStatesList: async (args = {}) => {
    const response = await commonJs.callApi("/route/states", "GET", args);
    return response;

    // return {
    //   success: true,
    //   response: [
    //     {
    //       id: 100234, // MGL_STATE_HDRID
    //       name: "Karnataka", // STATE_NAME
    //       code: "kn", // STATE_CODE
    //     },
    //     {
    //       id: 100235,
    //       name: "Maharastra",
    //       code: "maha",
    //     },
    //   ],
    //   reason: "",
    // };
  },

  // Get Cities List
  // SQL Query: "SELECT MGL_CITY_HDRID,CITY_NAME,CITY_CODE FROM mgl_city_hdr WHERE ACTIVE='T' AND STATE=10011 ORDER BY CITY_NAME;"
  getCitiesList: async (args = {}) => {
    // args = {
    //   stateId: 10011,
    // };

    const response = await commonJs.callApi("/route/cities", "GET", args);
    return response;

    // return {
    //   success: true,
    //   response: [
    //     {
    //       id: 100234, // MGL_CITY_HDRID
    //       name: "Bengaluru", // CITY_NAME
    //       code: "bng", // CITY_CODE
    //     },
    //     {
    //       id: 100235,
    //       name: "Maharastra",
    //       code: "mhs",
    //     },
    //   ],
    //   reason: "",
    // };
  },

  // Get Customers List
  // SQL Query: "SELECT MFR_PARTY_HDRID,PARTY_NAME,PARTY_CODE FROM mfr_party_hdr WHERE CANCEL='F' ORDER BY PARTY_NAME;"
  getCustomersList: async (args = {}) => {
    const response = await commonJs.callApi("/customer/", "GET", args);
    return response;

    // return {
    //   success: true,
    //   response: [
    //     {
    //       customerId: 100234, // MFR_PARTY_HDRID
    //       customerName: "XYZ Pvt Ltd.", // PARTY_NAME
    //       customerCode: "xyz", // PARTY_CODE
    //     },
    //     {
    //       customerId: 100235,
    //       customerName: "ABC Pvt Ltd.",
    //       customerCode: "abc",
    //     },
    //   ],
    //   reason: "",
    // };
  },

  getConsigneeList: async (args = {}) => {
    const response = await commonJs.callApi(
      "/customer/consigneelist",
      "GET",
      args
    );
    return response;
  },

  getTrack: async (args = {}) => {
    try {
      const res = await axios.post(
        "https://fleetlogix.vaschan.com/api/v1/api_unity",
        args
      );
      return res.data;
    } catch (error) {
      return error;
    }
  },

  getVehicleList: async (args = {}) => {
    args = {
      cmd: "getvehicles",
      key: "65482a2f0a1b49718195999500f96df2",
    };
    try {
      const res = await axios.post(
        "https://fleetlogix.vaschan.com/api/v1/api_unity",
        args
      );
      return res.data;
    } catch (error) {
      return error;
    }
  },

  // Get All Place Master Records
  // SQL Query: "SELECT MGL_PLACE_HDRID,PLACENAME,PLACECODE,LAT,LNG,CITY,STATE,ADDRESS1,PINCODE FROM mgl_place_hdr ORDER BY PLACENAME;"
  getPlaceMaster: async (args = {}) => {
    // args = {
    //   pageNo: 1,
    //   pageLength: 100,
    //   searchQry: "place1", // "" => Dont Search (Search by "PLACENAME")
    //   sortingOrder: "asc", // ( Sort by "PLACENAME" )
    // };

    const response = await commonJs.callApi("/route/places", "GET", args);
    return response;

    // return {
    //   success: true,
    //   totalRows: 3,
    //   response: [
    //     {
    //       placeId: 1001, // MGL_PLACE_HDRID
    //       placeName: "Place 1", // PLACENAME
    //       placeCode: "001", // PLACECODE
    //       location: {
    //         lat: 15.961329081596647, // LAT
    //         lng: 75.41015625, // LNG
    //       },
    //       cityId: 100234, // CITY
    //       stateId: 100234, // STATE
    //       pincode: 524324, // PINCODE
    //       address: "Dommanahalli, Bangalore, Karnataka, India - 500899", // ADDRESS1
    //       customerPlace: "bangalore", // CUSTPLACE
    //       customerId: 100235,
    //     },
    //     {
    //       placeId: 1002, // MGL_PLACE_HDRID
    //       placeName: "Place 2", // PLACENAME
    //       placeCode: "002", // PLACECODE
    //       location: {
    //         lat: 14.519780046326085, // LAT
    //         lng: 75.89355468750001, // LNG
    //       },
    //       cityId: 100234, // CITY
    //       stateId: 100234, // STATE
    //       pincode: 524324, // PINCODE
    //       address: "Dommanahalli, Bangalore, Karnataka, India - 500899", // ADDRESS1
    //       customerPlace: "bangalore", // CUSTPLACE
    //       customerId: 100235,
    //     },
    //     {
    //       placeId: 1003, // MGL_PLACE_HDRID
    //       placeName: "Place 3", // PLACENAME
    //       placeCode: "003", // PLACECODE
    //       location: {
    //         lat: 17.696361965403323, // LAT
    //         lng: 74.57519531250001, // LNG
    //       },
    //       cityId: 100234, // CITY
    //       stateId: 100234, // STATE
    //       pincode: 524324, // PINCODE
    //       address: "Dommanahalli, Bangalore, Karnataka, India - 500899", // ADDRESS1
    //       customerPlace: "bangalore", // CUSTPLACE
    //       customerId: 100235,
    //     },
    //   ],
    //   reason: "",
    // };
  },

  // Add / Edit Place Master
  // SQL Query: "INSERT INTO mgl_place_hdr(MGL_PLACE_HDRID, CANCEL, SOURCEID,
  //                                          USERNAME, CREATEDBY, APP_LEVEL, APP_DESC,
  //                                          PLACENAME, PLACECODE, STATE, REGION, CITY,
  //                                          PINCODE, PHONE, ACTIVE,
  //                                          LAT, LNG,
  //                                          GOOGLE_AD, ADDRESS1)
  //                                values(14431000010143, 'F', 0,
  //                                          'swamy', 'swamy', 1, 1,
  //                                          'abc_bommasandra_bangalore_kn', 'DD26', 10007000000017, 0, 14344000000000,
  //                                          580890, 0, 'T',
  //                                          26.6573866, 88.4151583,
  //                                          'https://www.google.com/maps?q=26.6573866,88.4151583','Bangalore, Karnataka, India');"
  managePlaceMaster: async (args) => {
    // args = {
    //   placeId: 1001, // MGL_PLACE_HDRID ( -1 => If it is new place )
    //   state: 110099, // STATE
    //   city: 10011, // CITY
    //   customerId: 100235,
    //   pincode: 575019, // PINCODE
    //   placeName: "Place 1", // PLACENAME
    //   placeCode: "PL-01", // PLACECODE
    //   customerPlace: "Bangalore", // CUSTPLACE
    //   lat: 12.34335, // LAT
    //   lng: 74.456565, // LNG
    //   address: "Dommanahalli, Bangalore, Karnataka, India - 500899", // ADDRESS1
    //   addressLink: "https://www.google.com/maps?q=26.6573866,88.4151583", // GOOGLE_AD
    // };

    const response = await commonJs.callApi("/route/places", "POST", args);
    return response;

    // return {
    //   success: true,
    //   response: {},
    //   reason: "",
    // };
  },

  // Get Dashboard Information
  // SQL Query: ""
  getDashboadInfo: async () => {
    const response = await commonJs.callApi(
      "/dashboard/getdashboardsummary",
      "GET",
      {}
    );
    return response;

    return {
      success: true,
      response: {
        availableForLoading: 100,
        loadAssignedVehicles: 90,
        waitingForUnload: 10,
        inTransit: 60,
        runningDelay: 5,
        accidentOrRepair: 2,
        vehiclesWithoutDriver: 8,
        driversWithoutVehicle: 20,
        readyForLoading: 8,
        waitingForVehicleDeparture: 4,
      },
      reason: "",
    };
  },

  // Get Own Vehicles Information
  getOwnVehiclesInfo: async (args = {}) => {
    args = {
      pageNo: 1,
      pageLength: 15,
      sortingColumn: "vehicleno",
      sortingOrder: "asc",
      searchQry: "KA-15",
    };
    return {
      success: true,
      response: {
        records: [
          {
            id: 87675667,
            vehicleNumber: "KA-15-6547",
            loadStatus: "EMPTY",
            currentLocation: "Mangalore",
            driverName: "Rakesh Agarwal",
            driverNumber: "9876543210",
          },
        ],
        totalRows: 1,
      },
      reason: "",
    };
  },

  fileUpload: async (args = {}) => {
    // const args = {
    //   file: {
    //     name: "",
    //     data: base64,
    //     type: "jpg",
    //     size: 577756,
    //   },
    //   category: "indent", // driver, lr, indent, pod, other
    // };

    const bearerToken = "";
    const config = {
      timeout: CONFIG.API_TIMEOUT,
      headers: {
        Authorization: "Bearer " + bearerToken,
      },
    };
    return await axios.post("http://localhost:8000/v1/upload", args, config);
    const response = await commonJs.callApi(
      "/drivers/uploadfiles",
      "POST",
      args
    );
    return response;
  },

  // SET FCM Token to the logged in user
  setFcmToken: async (args = {}) => {
    // args = {
    //   fcmToken: "fDpaxP9pjHkmoQqVLQcqui:APA91bEtjhn3l85r00BkdZLJs5",
    //   platform: "web",
    // };
    const response = await commonJs.callApi("/updateFcmToken", "POST", args);
    return response;
  },

  getCustomerPlaceCode: async (args = {}) => {
    const response = await commonJs.callApi(
      "/customer/getCustomerPlaceCode",
      "GET",
      args
    );
    return response;
  },

  getAllEmployees: async (args = {}) => {
    const response = await commonJs.callApi("/hr/getemployees", "GET", args);
    return response;
  },

  getOfficeZones: async (args = {}) => {
    const config = {
      timeout: CONFIG.API_TIMEOUT,
      headers: {
        "Content-Type": "application/json",
      },
    };
    config.params = args;
    const response = await axios.get(
      CONFIG.FLEET_API_URL + "/v1/unity/getuserlocations",
      config
    );
    return response.data;
  },

  editEmployee: async (args = {}) => {
    const response = await commonJs.callApi(
      "/hr/editemployee",
      CONFIG.API_METHODS.POST,
      args
    );
    return response;
  },

  saveUser: async (args = {}) => {
    const response = await commonJs.callApi(
      "/webadmin/manageuser",
      CONFIG.API_METHODS.POST,
      args
    );
    return response;
  },

  getAllManagers: async (args = {}) => {
    const response = await commonJs.callApi("/hr/getmanagers", "GET", args);
    return response;
  },

  getEmpAttnList: async (args = {}) => {
    const response = await commonJs.callApi("/hr/empattnlist", "GET", args);
    return response;
  },

  getEmpShiftDetails: async (args = {}) => {
    const response = await commonJs.callApi("/hr/empshiftinfo", "GET", args);
    return response;
  },

  manageCheckIn: async (args = {}) => {
    const response = await commonJs.callApi(
      "/hr/managecheckin",
      CONFIG.API_METHODS.POST,
      args
    );
    return response;
  },

  // GET Leave Requests
  getLeaveRequests: async (args = {}) => {
    const response = await commonJs.callApi(
      "/office-user/leaverequests",
      CONFIG.API_METHODS.GET,
      args
    );
    return response;
  },

  // Manage Leave Request
  manageLeaveRequest: async (args = {}) => {
    const response = await commonJs.callApi(
      "/office-user/manageleaverequest",
      CONFIG.API_METHODS.POST,
      args
    );
    return response;
  },
};
