import React, { Component } from "react";
import { withSnackbar } from "notistack";
import { CircularProgress, IconButton, Tooltip } from "@material-ui/core";
import {
  AddBox as AddIcon,
  Edit as EditIcon,
  Replay as ReloadIcon,
} from "@material-ui/icons";

import Btn from "../../../ui/button/Btn";
import UiDatePicker from "../../../ui/uidatepicker/UiDatePicker";
import AttnGrid from "./AttnGrid";

import { LABELS, NOTIFY, FORMATS } from "../../../../common/consts";
import { API_HANDLER } from "../../../../apihandlers";
import { dateFunctions } from "../../../../common/datefunctions";
import { commonJs } from "../../../../common/scripts";

class AttnDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fdt: dateFunctions.getDate("thismonth", "from", FORMATS.SQL_DATE),
      tdt: dateFunctions.getDate("thismonth", "to", FORMATS.SQL_DATE),
      attnDt: dateFunctions.getDate("today", "from", FORMATS.SQL_DATE),
      rows: null,
      filteredRows: [],
      columns: [
        {
          name: "slNo",
          title: "Sl No.",
        },
        {
          name: "attnDate",
          title: "Attendance Date",
        },
        {
          name: "inTime",
          title: "In Time",
        },
        {
          name: "outTime",
          title: "Out Time",
        },
        {
          name: "inReason",
          title: "In Reason",
        },
        {
          name: "outReason",
          title: "Out Reason",
        },
        {
          name: "inLoc",
          title: "In Location",
        },
        {
          name: "outLoc",
          title: "Out Location",
        },
        {
          name: "status",
          title: "Status",
        },
        {
          name: "options",
          title: "Options",
        },
      ],
      defaultSorting: [{ columnName: "slNo", direction: "asc" }],
      loading: false,
    };

    this._tableColumnWidths = [
      {
        columnName: "slNo",
        width: 100,
      },
      { columnName: "attnDate", width: 150 },
      { columnName: "inTime", width: 100 },
      { columnName: "outTime", width: 100 },
      { columnName: "inReason", width: 100 },
      { columnName: "outReason", width: 100 },
      { columnName: "inLoc", width: 150 },
      { columnName: "outLoc", width: 150 },
      { columnName: "status", width: 100 },
      { columnName: "options", width: 100 },
    ];

    this._tableColumnExtensions = [
      {
        columnName: "slNo",
        align: "center",
      },
      { columnName: "attnDate", align: "left" },
      { columnName: "inTime", align: "center" },
      { columnName: "outTime", align: "center" },
      { columnName: "inReason", align: "center" },
      { columnName: "outReason", align: "center" },
      { columnName: "inLoc", align: "left" },
      { columnName: "outLoc", align: "left" },
      { columnName: "status", align: "center" },
      { columnName: "options", align: "center" },
    ];

    this._sortingDisabledColumns = [
      { columnName: "options", sortingEnabled: false },
    ];
  }

  handleFdtDateChange = (type, fdt) => {
    this.setState({
      fdt,
    });
  };

  handleTdtDateChange = (type, tdt) => {
    this.setState({
      tdt,
    });
  };

  handleAttnDateChange = (type, attnDt) => {
    this.setState({
      attnDt,
    });
  };

  reloadGrid = () => {
    const { fdt, tdt } = this.state;
    const { empId } = this.props.employee;
    const args = {
      empId,
      fdt: dateFunctions.getFormatedDate(
        fdt,
        FORMATS.SQL_DATE,
        FORMATS.SQL_DATE_ONLY
      ),
      tdt: dateFunctions.getFormatedDate(
        tdt,
        FORMATS.SQL_DATE,
        FORMATS.SQL_DATE_ONLY
      ),
    };
    this.setState({
      loading: true,
    });
    const self = this;
    API_HANDLER.getEmpAttnList(args).then((res) => {
      console.log("getEmpAttnList: ", args, res);
      if (res.success) {
        const { records } = res.response;
        const rows = records.map((row, i) => {
          row.slNo = i + 1;
          row.options = self.generateOptions(row);
          return row;
        });
        self.setState({
          loading: false,
          rows,
        });
      } else {
        commonJs.showNotify(self.props, NOTIFY.ERROR, res.reason);
      }
    });
  };

  generateOptions = (rec = {}) => {
    return (
      <div className="options">
        <Tooltip title="Edit" placement="bottom">
          <IconButton
            className="dataGridIcon"
            onClick={() => this.showEmployeeDialog(rec)}
          >
            <EditIcon />
          </IconButton>
        </Tooltip>
      </div>
    );
  };

  showEmployeeDialog = (rec = {}) => {
    this._attnGrid.showAddAttnDialog(rec);
  };

  render() {
    const { fdt, tdt, loading, rows, columns, filteredRows, defaultSorting } =
      this.state;
    const { employee } = this.props;
    const gridHeight = window.innerHeight * 0.6;
    console.log("AttnDialog: ", employee);
    return (
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div className="formGroup" style={{ width: "100%" }}>
          <div className="mB5">
            <label className="formLabel">{LABELS.DATE_RANGE + " :"}</label>
          </div>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div style={{ marginRight: 25 }}>
              <UiDatePicker
                label=""
                dt={fdt}
                type="from"
                disablePast={false}
                disableFuture={false}
                disabled={false}
                datepicker={true}
                handleDateChange={this.handleFdtDateChange}
              />
            </div>
            <div style={{ marginRight: 25 }}>
              <UiDatePicker
                label=""
                dt={tdt}
                type="from"
                disablePast={false}
                disableFuture={false}
                disabled={false}
                datepicker={true}
                handleDateChange={this.handleTdtDateChange}
              />
            </div>
            <Btn className={"primaryBtn saveBtn"} onClick={this.reloadGrid}>
              {LABELS.LOAD}
            </Btn>
          </div>
        </div>
        {loading ? (
          <div
            className="formGroup"
            style={{
              width: "100%",
              height: gridHeight,
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress className={"primary"} size="1.5rem" />
          </div>
        ) : (
          <div
            className="formGroup"
            style={{ width: "100%", height: gridHeight }}
          >
            <AttnGrid
              childRef={(ref) => (this._attnGrid = ref)}
              rows={rows}
              columns={columns}
              filteredRows={filteredRows}
              sortingDisabledColumns={this._sortingDisabledColumns}
              tableColumnExtensions={this._tableColumnExtensions}
              tableColumnWidths={this._tableColumnWidths}
              defaultSorting={defaultSorting}
              selectedEmployee={employee}
              reloadGrid={this.reloadGrid}
            />
          </div>
        )}
      </div>
    );
  }
}

export default withSnackbar(AttnDialog);
