import React, { Component } from "react";
import { withSnackbar } from "notistack";
import { connect } from "react-redux";

import Btn from "../../../ui/button/Btn";
import Txt from "../../../ui/textbox/Txt";
import UiDatePicker from "../../../ui/uidatepicker/UiDatePicker";

import {
  LABELS,
  NOTIFY,
  SUCCESS,
  FORMATS,
  CONFIG,
} from "../../../../common/consts";
import { API_HANDLER } from "../../../../apihandlers";
import { dateFunctions } from "../../../../common/datefunctions";
import { commonJs } from "../../../../common/scripts";

class ManageAttnDialog extends Component {
  constructor(props) {
    super(props);
    const { record } = this.props;
    let attnDt = dateFunctions.getDate("today", "from", FORMATS.SQL_DATE);
    if (record.attnDate) {
      attnDt = record.attnDate;
    }
    let selectedAttnStatus = "";
    if (record.status) {
      selectedAttnStatus = record.status;
    }
    let attnInTime =
      dateFunctions.getDate("today", "from", FORMATS.SQL_DATE_ONLY) +
      " 09:30:00";
    if (record.inTime) {
      attnInTime =
        dateFunctions.getFormatedDate(
          attnDt,
          FORMATS.SQL_DATE,
          FORMATS.SQL_DATE_ONLY
        ) +
        " " +
        record.inTime +
        ":00";
    }
    let attnOutTime =
      dateFunctions.getDate("today", "from", FORMATS.SQL_DATE_ONLY) +
      " 18:30:00";
    if (record.outTime) {
      attnOutTime =
        dateFunctions.getFormatedDate(
          attnDt,
          FORMATS.SQL_DATE,
          FORMATS.SQL_DATE_ONLY
        ) +
        " " +
        record.outTime +
        ":00";
    }
    this.state = {
      attnDt,
      attnInTime,
      attnOutTime,
      loading: false,
      selectedAttnStatus,
    };
  }

  componentDidMount() {
    const { record } = this.props;
    if (!record.attnBasicId) {
      this.getShiftDetails();
    }
  }

  getShiftDetails = () => {
    const { shiftId } = this.props.selectedEmployee;
    const self = this;
    API_HANDLER.getEmpShiftDetails({ shiftId }).then((res) => {
      if (res.success) {
        const { inTime, outTime } = res.response;
        self.setState({
          attnInTime:
            dateFunctions.getDate("today", "from", FORMATS.SQL_DATE_ONLY) +
            " " +
            inTime,
          attnOutTime:
            dateFunctions.getDate("today", "from", FORMATS.SQL_DATE_ONLY) +
            " " +
            outTime,
        });
      }
    });
  };

  handleAttnDateChange = (type, attnDt) => {
    this.setState({
      attnDt,
    });
  };

  setStatus = (selectedAttnStatus = "") => {
    this.setState({
      selectedAttnStatus,
    });
  };

  handleAttnInTimeChange = (type, attnInTime) => {
    this.setState({
      attnInTime,
    });
  };

  handleAttnOutTimeChange = (type, attnOutTime) => {
    this.setState({
      attnOutTime,
    });
  };

  saveAttendance = () => {
    const { attnDt, selectedAttnStatus, attnInTime, attnOutTime } = this.state;
    const { record, loginReducer, selectedEmployee } = this.props;
    const { userName } = loginReducer;
    const { empId, empName } = selectedEmployee;
    const inLatLng = document.getElementById("inLatLng").value.split(",");
    const outLatLng = document.getElementById("outLatLng").value.split(",");
    const inLoc = document.getElementById("inLoc").value.trim();
    const outLoc = document.getElementById("outLoc").value.trim();
    const inReason = document.getElementById("inReason").value.trim();
    const outReason = document.getElementById("outReason").value.trim();
    // Validations
    if (selectedAttnStatus === "") {
      commonJs.showNotify(
        this.props,
        NOTIFY.WARNING,
        "Please select attendance status..!"
      );
      return;
    }
    // Intime should be less than the out time
    let inTimeTotal = 0;
    let inTime = dateFunctions.getFormatedDate(
      attnInTime,
      FORMATS.SQL_DATE,
      "HH:mm"
    );
    let inTimeArr = inTime.split(":");
    inTimeTotal = Number(inTimeArr[0]) * 60 + Number(inTimeArr[1]); // Total Minutes

    let outTimeTotal = 0;
    let outTime = dateFunctions.getFormatedDate(
      attnOutTime,
      FORMATS.SQL_DATE,
      "HH:mm"
    );
    let outTimeArr = outTime.split(":");
    outTimeTotal = Number(outTimeArr[0]) * 60 + Number(outTimeArr[1]); // Total Minutes
    if (inTimeTotal >= outTimeTotal) {
      commonJs.showNotify(
        this.props,
        NOTIFY.WARNING,
        "In Time should be less than the Out Time..!"
      );
      return;
    }

    const args = {
      attnBasicId: record.attnBasicId || "",
      uname: userName || "",
      empId,
      empName,
      attnDt,
      statusText: selectedAttnStatus,
      inTime,
      outTime,
      inLatLng,
      outLatLng,
      inLoc,
      outLoc,
      inReason,
      outReason,
    };
    console.log("saveAttendance: ", args);
    const self = this;
    API_HANDLER.manageCheckIn(args).then((res) => {
      console.log("manageCheckIn: ", res);
      if (res.success) {
        commonJs.showNotify(
          self.props,
          NOTIFY.SUCCESS,
          SUCCESS.RECORD_SAVED_SUCCESSFUL
        );
        self.props.reloadGrid();
        self.props.onCloseDialog();
      } else {
        commonJs.showNotify(self.props, NOTIFY.ERROR, "Error: " + res.reason);
      }
    });
  };

  render() {
    const { attnDt, selectedAttnStatus, attnInTime, attnOutTime } = this.state;
    const { selectedEmployee, record } = this.props;
    console.log("ManageAttnDialog: ", selectedEmployee, record);
    return (
      <div style={{ display: "flex", flexDirection: "column", marginTop: -10 }}>
        <div className="formGroup" style={{ width: "100%" }}>
          <div className="mB5">
            <label className="formLabel">{LABELS.ATTENDANCE_DATE + " :"}</label>
          </div>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <UiDatePicker
              label=""
              dt={attnDt}
              type="from"
              disablePast={false}
              disableFuture={true}
              disabled={record.attnBasicId ? true : false}
              datepicker={true}
              handleDateChange={this.handleAttnDateChange}
            />
          </div>
        </div>
        <div className="formGroup" style={{ width: "100%" }}>
          <div className="mB5">
            <label className="formLabel">
              {LABELS.ATTENDANCE_STATUS + " :"}
            </label>
          </div>
          <div style={{ display: "flex", flexDirection: "row" }}>
            {CONFIG.ATTENDANCE_STATUS_LIST.map((v, i) => {
              return (
                <Btn
                  key={"status-" + i}
                  className={
                    "statusBtn " +
                    (selectedAttnStatus === v.value ? "active" : "")
                  }
                  onClick={() => this.setStatus(v.value)}
                >
                  {v.text}
                </Btn>
              );
            })}
          </div>
        </div>
        <div
          className="formGroup"
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div style={{ width: "45%" }}>
            <div className="mB5">
              <label className="formLabel">{LABELS.IN_TIME + " :"}</label>
            </div>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <UiDatePicker
                label=""
                dt={attnInTime}
                type="from"
                disablePast={false}
                disableFuture={false}
                disabled={false}
                datepicker={false}
                handleDateChange={this.handleAttnInTimeChange}
              />
            </div>
          </div>
          <div style={{ width: "45%" }}>
            <div className="mB5">
              <label className="formLabel">{LABELS.OUT_TIME + " :"}</label>
            </div>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <UiDatePicker
                label=""
                dt={attnOutTime}
                type="from"
                disablePast={false}
                disableFuture={false}
                disabled={false}
                datepicker={false}
                handleDateChange={this.handleAttnOutTimeChange}
              />
            </div>
          </div>
        </div>
        <div className="formGroup" style={{ width: "100%" }}>
          <div className="mB5">
            <label className="formLabel">{LABELS.IN_LOCATION + " :"}</label>
          </div>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <Txt
              id="inLoc"
              placeHolder={"Enter Check In Location"}
              defaultValue={record.inLoc || ""}
            />
          </div>
        </div>
        <div className="formGroup" style={{ width: "100%" }}>
          <div className="mB5">
            <label className="formLabel">{LABELS.OUT_LOCATION + " :"}</label>
          </div>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <Txt
              id="outLoc"
              placeHolder={"Enter Check Out Location"}
              defaultValue={record.outLoc || ""}
            />
          </div>
        </div>
        <div
          className="formGroup"
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div style={{ width: "45%" }}>
            <div className="mB5">
              <label className="formLabel">{LABELS.IN_LAT_LNG + " :"}</label>
            </div>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <Txt
                id="inLatLng"
                placeHolder={"Enter Latitude, Longitude"}
                defaultValue={
                  record.inLat ? record.inLat + "," + record.inLng : ""
                }
              />
            </div>
          </div>
          <div style={{ width: "45%" }}>
            <div className="mB5">
              <label className="formLabel">{LABELS.OUT_LAT_LNG + " :"}</label>
            </div>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <Txt
                id="outLatLng"
                placeHolder={"Enter Latitude, Longitude"}
                defaultValue={
                  record.outLat ? record.outLat + "," + record.outLng : ""
                }
              />
            </div>
          </div>
        </div>
        <div
          className="formGroup"
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div style={{ width: "45%" }}>
            <div className="mB5">
              <label className="formLabel">{LABELS.IN_REASON + " :"}</label>
            </div>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <Txt
                id="inReason"
                placeHolder={"Enter Reason"}
                defaultValue={record.inReason || ""}
              />
            </div>
          </div>
          <div style={{ width: "45%" }}>
            <div className="mB5">
              <label className="formLabel">{LABELS.OUT_REASON + " :"}</label>
            </div>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <Txt
                id="outReason"
                placeHolder={"Enter Reason"}
                defaultValue={record.outReason || ""}
              />
            </div>
          </div>
        </div>
        <div
          className="formGroup"
          style={{
            width: "100%",
            marginTop: 25,
            display: "flex",
            justifyContent: "center",
            marginBottom: 5,
          }}
        >
          <Btn className={"primaryBtn saveBtn"} onClick={this.saveAttendance}>
            {LABELS.SAVE}
          </Btn>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loginReducer: state.loginReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(ManageAttnDialog));
