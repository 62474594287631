import React, { Component } from "react";
import { connect } from "react-redux";

import LoadingPopOver from "./LoadingPopOver";

class LoadingDiv extends Component {
  render() {
    const { loading } = this.props.pageReducer;
    return (
      <React.Fragment>
        {loading && <LoadingPopOver>Please wait...</LoadingPopOver>}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    pageReducer: state.pageReducer,
  };
};

export default connect(mapStateToProps)(LoadingDiv);
