import React, { Component } from "react";
import { Popup } from "react-leaflet";
import { connect } from "react-redux";

import { DEFAULTS, LABELS } from "../../common/consts";
import { PLACE_ACTIONS } from "../../actions/PlaceActions";
import { MAP_ACTIONS } from "../../actions/MapActions";
import { MAP_FORM_ACTIONS } from "../../actions/MapFormActions";
import { API_HANDLER } from "../../apihandlers";

class MapPopup extends Component {
  showRoute = (lat, lng) => {
    window.open(
      "https://maps.google.com/maps?saddr=" + lat + "," + lng + "&daddr="
    );
  };

  shareLocation = () => {
    const { vehicle } = this.props;
    this.props.shareVehicle(vehicle.imei);
  };

  trackVehicle = () => {
    const { vehicle } = this.props;
    console.log("trackVehicle: ", vehicle, this.props);
    this.props.showTrack("today", vehicle.imei);
  };

  setSelectedPlace = () => {
    const { data, mapFormReducer } = this.props;
    const { stateList } = mapFormReducer;

    this.props.setPlaceInfo({
      placeInfo: data,
    });

    this.props.setTmpMarker({
      tmpMarker: { lat: data.location.lat, lng: data.location.lng },
    });

    // Set Selected Values
    let selectedState = stateList.filter((s) => {
      return s.id === data.stateId;
    });
    if (selectedState.length > 0) {
      selectedState = selectedState[0];
    } else {
      selectedState = {};
    }

    // async => make separate function
    this.editCity(selectedState);
  };

  editCity = (selectedState = {}) => {
    const self = this;
    const { data } = this.props;
    const { cityList } = this.props.mapFormReducer;
    let selectedCity = cityList.filter((c) => {
      return c.id === data.cityId;
    });
    if (cityList.length === 0) {
      API_HANDLER.getCitiesList({
        stateId: selectedState.id,
        pageNo: 1,
        pageLength: DEFAULTS.PAGE_LENGTH,
        searchQry: "",
        sortingOrder: DEFAULTS.SORTING_ORDER,
      }).then((res) => {
        if (res.success) {
          const { records } = res.response;
          const cityList = records;
          self.props.setCities({
            cityList,
          });
          selectedCity = cityList.filter((c) => {
            return c.id === data.cityId;
          });
          if (selectedCity.length > 0) {
            selectedCity = selectedCity[0];
          } else {
            selectedCity = {};
          }
          self.editCustomer(selectedState, selectedCity);
        }
      });
    } else {
      if (selectedCity.length > 0) {
        selectedCity = selectedCity[0];
      } else {
        selectedCity = {};
      }
      self.editCustomer(selectedState, selectedCity);
    }
  };

  editCustomer = (selectedState = {}, selectedCity = {}) => {
    const self = this;
    const { data } = this.props;
    const { customerList } = this.props.mapFormReducer;
    let selectedCustomer = customerList.filter((c) => {
      return c.id === data.customerId;
    });
    if (selectedCustomer.length > 0) {
      selectedCustomer = selectedCustomer[0];
    } else {
      selectedCustomer = {};
    }
    self.setEdit(selectedState, selectedCity, selectedCustomer);
  };

  setEdit = (selectedState = {}, selectedCity = {}, selectedCustomer = {}) => {
    const { data } = this.props;
    this.props.setSelectedValue({
      selectedState,
      selectedCity,
      selectedCustomer,
    });
    console.log("setEdit: ", selectedState, selectedCity, selectedCustomer);
    setTimeout(() => {
      document.getElementById("gSearchByPincode").value = data.pincode;
      document.getElementById("placeCode").value = data.placeCode;
      document.getElementById("customerPlace").value = data.customerPlace;
      document.getElementById("placeName").value = data.placeName;
      document.getElementById("latitude").value = data.location.lat;
      document.getElementById("longitude").value = data.location.lng;
      document.getElementById("address").value = data.address;
    }, 1000);
  };

  render() {
    const { data } = this.props;
    return (
      <Popup>
        <div className="markerPopup">
          <table>
            <tbody>
              <tr>
                <td colSpan="2">
                  <div style={{ display: "flex" }}>
                    <h4>
                      <span className="popupName">{data.placeName}</span>
                    </h4>
                  </div>
                  <p className="popupAddress" style={{ minWidth: 250 }}>
                    {data.address}
                  </p>
                </td>
              </tr>
              <tr>
                <td className="w40p pB5">{LABELS.MAP_POSITION}</td>
                <td className="w60p pB5">
                  {": "}&nbsp;&nbsp;
                  <a
                    href={
                      "//maps.google.com/maps?q=" +
                      data.location.lat +
                      "," +
                      data.location.lng
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {Number(data.location.lat).toFixed(6)}°,{" "}
                    {Number(data.location.lng).toFixed(6)}°
                  </a>
                </td>
              </tr>
              <tr>
                <td className="w40p pB5">{LABELS.PLACE_NAME}</td>
                <td className="w60p pB5">
                  {": "}&nbsp; {data.placeName}
                </td>
              </tr>
              <tr>
                <td className="w40p pB5">{LABELS.PLACE_CODE}</td>
                <td className="w60p pB5">
                  {": "}&nbsp; {data.placeCode}
                </td>
              </tr>
              <tr>
                <td className="w40p pB5">{LABELS.CUSTOMER_NAME}</td>
                <td className="w60p pB5">
                  {": "}&nbsp; {data.customerName}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </Popup>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loginReducer: state.loginReducer,
    mapFormReducer: state.mapFormReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setPlaceInfo: (values) => {
      dispatch({
        type: PLACE_ACTIONS.SET_PLACE_INFO,
        values,
      });
    },
    setTmpMarker: (values) => {
      dispatch({
        type: MAP_ACTIONS.SET_TMP_MARKER,
        values,
      });
    },
    setStates: (values) => {
      dispatch({
        type: MAP_FORM_ACTIONS.SET_STATES,
        values,
      });
    },
    setCities: (values) => {
      dispatch({
        type: MAP_FORM_ACTIONS.SET_CITIES,
        values,
      });
    },
    setCustomers: (values) => {
      dispatch({
        type: MAP_FORM_ACTIONS.SET_CUSTOMERS,
        values,
      });
    },
    setCityState: (values) => {
      dispatch({
        type: MAP_FORM_ACTIONS.SET_CITY_STATE,
        values,
      });
    },
    setSelectedValue: (values) => {
      dispatch({
        type: MAP_FORM_ACTIONS.SET_SELECTED_VALUES,
        values,
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MapPopup);
