import React, { Component } from "react";
import { withSnackbar } from "notistack";
import { connect } from "react-redux";
// Leaflet
import { Tooltip, Marker, Polyline } from "react-leaflet";
import L from "leaflet";
import "react-leaflet-markercluster/dist/styles.min.css";
import "leaflet/dist/leaflet.css";

import { COLORS } from "../../common/consts";

// Images
import routeStart from "../../images/mapImages/markers/route_start.png";
import routeStop from "../../images/mapImages/markers/route_stop.png";
import routeEnd from "../../images/mapImages/markers/route_end.png";
import directionIcon from "../../images/mapImages/markers/direction-marker.png";

// Custom CSS
// import "./styles/fleetmap.css";

class Trackline extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  getMapMarker = (status, angle = 0) => {
    let markerUrl = routeStart;
    let markerSize = 16;
    if (status === "stop") {
      markerUrl = routeStop;
    } else if (status === "end") {
      markerUrl = routeEnd;
    } else if (status === "direction") {
      markerUrl = directionIcon;
      markerSize = 12;
    }

    const html =
      "<div class='trackMarker' style='transform: rotate(" +
      angle.toString() +
      "deg);'><img src='" +
      markerUrl +
      "' alt='' width='" +
      markerSize +
      "px'/></div>";

    const customMarker = L.divIcon({
      html: html,
      iconSize: new L.Point(markerSize, markerSize),
      iconAnchor: null,
      popupAnchor: [0, -3],
      className: "trackMarker m-" + status,
    });

    return customMarker;
  };

  getTracklinePoints = (s, index, marker = "stop") => {
    return (
      <Marker
        position={[s.latitude, s.longitude]}
        icon={this.getMapMarker(marker, s.angle)}
        className={"track-marker"}
        key={marker + "tmarker-" + index}
      >
        <Tooltip direction="right" offset={[10, -2]} permanent>
          <span>{s.duration}</span>
        </Tooltip>
      </Marker>
    );
  };

  render() {
    const { trackInfo } = this.props.trackReducer;

    const positions = [];
    if (trackInfo.route) {
      trackInfo.route.map((r) => {
        positions.push([Number(r[1]), Number(r[2])]);
        return r;
      });
    }

    let stops = [];
    if (trackInfo.stops) {
      stops = trackInfo.stops.map((s) => {
        return {
          latitude: s[2],
          longitude: s[3],
          angle: 0,
          duration: s[8],
        };
      });
    }

    return (
      positions.length > 0 && (
        <React.Fragment>
          <Polyline
            positions={positions}
            color={COLORS.RED}
            weight="3"
            ref={(ref) => {
              this._mapPolyline = ref;
            }}
          />

          <Marker
            position={positions[0]}
            icon={this.getMapMarker("start")}
            className="track-marker"
          >
            <Tooltip direction="right" offset={[10, -2]} permanent>
              <span>{"Start Point"}</span>
            </Tooltip>
          </Marker>

          <Marker
            position={positions[positions.length - 1]}
            icon={this.getMapMarker("end")}
            className="track-marker"
          >
            <Tooltip direction="right" offset={[10, -2]} permanent>
              <span>{"End Point"}</span>
            </Tooltip>
          </Marker>

          {stops.map((s, index) => {
            return this.getTracklinePoints(s, index, "stop");
          })}
        </React.Fragment>
      )
    );
  }
}

const mapStateToProps = (state) => {
  return {
    trackReducer: state.trackReducer,
    mapReducer: state.mapReducer,
  };
};

export default connect(mapStateToProps)(withSnackbar(Trackline));
