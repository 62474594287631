import React, { Component } from "react";
import { connect } from "react-redux";

import UiDialog from "../../ui/dialogbox/UiDialog";
import MapForm from "../MapForm";

import { PLACE_ACTIONS } from "../../../actions/PlaceActions";
import { MAP_ACTIONS } from "../../../actions/MapActions";

class FormDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDialog: false,
    };
  }

  componentDidMount() {
    const { childRef } = this.props;
    childRef(this);
  }

  componentWillUnmount() {
    const { childRef } = this.props;
    childRef(undefined);
  }

  closeDialog = () => {
    this.setState({
      showDialog: false,
    });
    this.props.setPlaceInfo({
      placeInfo: {},
    });
    this.props.setTmpMarker({ tmpMarker: {} });
  };

  showDialog = () => {
    this.setState({
      showDialog: true,
    });
  };

  onSave = () => {
    console.log("onSave: ", this);
  };

  render() {
    const { showDialog } = this.state;
    const { updateRegion, placeReducer } = this.props;
    const { placeInfo } = placeReducer;
    console.log("mapform: ", placeInfo);
    return (
      <UiDialog
        id={"dashDialog"}
        title={
          placeInfo.placeId
            ? "Edit Place : " + placeInfo.placeName
            : "Add Place"
        }
        open={showDialog}
        maxWidth={"sm"}
        draggable={true}
        disableBackdrop={true}
        classNameWrapper={"popupDialog"}
        classNameBody={"popupDialogBody"}
        onCloseDialog={this.closeDialog}
      >
        <MapForm
          childRef={(ref) => (this._mapForm = ref)}
          updateRegion={updateRegion}
          closeDialog={this.closeDialog}
        />
      </UiDialog>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    placeReducer: state.placeReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setPlaceMaster: (values) => {
      dispatch({
        type: PLACE_ACTIONS.SET_PLACE_MASTER,
        values,
      });
    },
    setPlaceInfo: (values) => {
      dispatch({
        type: PLACE_ACTIONS.SET_PLACE_INFO,
        values,
      });
    },
    setTmpMarker: (values) => {
      dispatch({
        type: MAP_ACTIONS.SET_TMP_MARKER,
        values,
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FormDialog);
