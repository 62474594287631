import React, { Component } from "react";
import { TextField } from "@material-ui/core";

import "./txt.css";

class Txt extends Component {
  render() {
    const {
      id,
      disabled,
      rows,
      onKeyPress,
      onFocus,
      autoCapitalize,
      placeHolder,
      defaultValue,
    } = this.props;
    let isMultiline = false;
    if (rows) {
      isMultiline = true;
    }
    return (
      <TextField
        id={id}
        variant="outlined"
        size="small"
        className="txtbox"
        defaultValue={defaultValue || ""}
        disabled={disabled || false}
        multiline={isMultiline}
        rows={rows}
        onKeyUp={onKeyPress}
        onFocus={onFocus}
        placeholder={placeHolder || ""}
        style={{
          textTransform: autoCapitalize ? "uppercase" : "initial",
        }}
      />
    );
  }
}

export default Txt;
