import { combineReducers } from "redux";

import loginReducer from "./LoginReducer";
import mapReducer from "./MapReducer";
import pageReducer from "./PageReducer";
import trackReducer from "./TrackReducer";
import placeReducer from "./PlaceReducer";
import mapFormReducer from "./MapFormReducer";

export default combineReducers({
  loginReducer,
  mapReducer,
  pageReducer,
  trackReducer,
  placeReducer,
  mapFormReducer,
});
