import firebase from "firebase";

const config = {
  apiKey: "AIzaSyDG1nPD8UX5SMYVtj11SN-TIN9dQ-XYb1E",
  authDomain: "unity-fms-1cd31.firebaseapp.com",
  projectId: "unity-fms-1cd31",
  storageBucket: "unity-fms-1cd31.appspot.com",
  messagingSenderId: "328015320565",
  appId: "1:328015320565:web:37009148206f2ce5e5fcbc",
};

firebase.initializeApp(config);

let messaging;

if (firebase.messaging.isSupported()) {
  messaging = firebase.messaging();
}

// Handle incoming messages. Called when:
// - a message is received while the app has focus
// - the user clicks on an app notification created by a service worker
//   `messaging.onBackgroundMessage` handler.
messaging.onMessage((payload) => {
  console.log("[Web Push]: serviceWorker: Message received. ", payload);
});

export { messaging };
