import React, { Component } from "react";
import { connect } from "react-redux";
import PlacesAutocomplete, {
  geocodeByAddress,
} from "react-places-autocomplete";
import { withSnackbar } from "notistack";
import { Checkbox } from "@material-ui/core";

import Txt from "../../ui/textbox/Txt";
import Btn from "../../ui/button/Btn";
import Autocomplete from "../../ui/autocomplete/AutoComplete";

import {
  DEFAULTS,
  ERRORS,
  LABELS,
  NOTIFY,
  SUCCESS,
  WARNINGS,
} from "../../../common/consts";
import { API_HANDLER } from "../../../apihandlers";
import { commonJs } from "../../../common/scripts";
import { PAGE_ACTIONS } from "../../../actions/PageActions";
import { PLACE_ACTIONS } from "../../../actions/PlaceActions";
import { MAP_ACTIONS } from "../../../actions/MapActions";
import { MAP_FORM_ACTIONS } from "../../../actions/MapFormActions";

class MapForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      address: "",
      updateLatLng: false,
    };
  }

  componentDidMount() {
    const { childRef } = this.props;
    childRef(this);
    const { placeInfo } = this.props.placeReducer;
    const { stateList, customerList } = this.props.mapFormReducer;

    if (placeInfo.placeId) {
      let selectedState = stateList.filter((s) => {
        return s.id === placeInfo.stateId;
      });
      if (selectedState.length > 0) {
        selectedState = selectedState[0];
      } else {
        selectedState = {};
      }
      this.props.setState({
        selectedState,
      });

      let selectedCustomer = customerList.filter((s) => {
        return s.id === placeInfo.customerId;
      });
      if (selectedCustomer.length > 0) {
        selectedCustomer = selectedCustomer[0];
      } else {
        selectedCustomer = {};
      }
      this.props.setCustomer({
        selectedCustomer,
      });

      const self = this;
      API_HANDLER.getCitiesList({
        stateId: selectedState.id,
        pageNo: 1,
        pageLength: DEFAULTS.PAGE_LENGTH,
        searchQry: "",
        sortingOrder: DEFAULTS.SORTING_ORDER,
      }).then((res) => {
        if (res.success) {
          const { records } = res.response;
          self.props.setCities({
            cityList: records,
          });
          let selectedCity = records.filter((s) => {
            return s.id === placeInfo.cityId;
          });
          if (selectedCity.length > 0) {
            selectedCity = selectedCity[0];
          } else {
            selectedCity = {};
          }
          self.props.setCity({
            selectedCity,
          });
          document.getElementById("gSearchByPincode").value = placeInfo.pincode;
          document.getElementById("address").value = placeInfo.address;
          document.getElementById("latitude").value = placeInfo.location.lat;
          document.getElementById("longitude").value = placeInfo.location.lng;
          document.getElementById("placeCode").value =
            placeInfo.placeCode || "";
          document.getElementById("customerPlace").value =
            placeInfo.customerPlace;
          document.getElementById("placeName").value = placeInfo.placeName;
        }
      });
    }
  }

  componentWillUnmount() {
    const { childRef } = this.props;
    childRef(undefined);
  }

  getCityList = (stateId = null) => {
    const self = this;
    API_HANDLER.getCitiesList({
      stateId,
      pageNo: 1,
      pageLength: DEFAULTS.PAGE_LENGTH,
      searchQry: "",
      sortingOrder: DEFAULTS.SORTING_ORDER,
    }).then((res) => {
      if (res.success) {
        const { records } = res.response;
        self.props.setCities({
          cityList: records,
        });
      }
    });
  };

  saveRecord = () => {
    const { updateLatLng } = this.state;
    const { selectedState, selectedCity, selectedCustomer, selectedConsignee } =
      this.props.mapFormReducer;
    const { placeInfo } = this.props.placeReducer;
    const lat = Number(document.getElementById("latitude").value);
    const lng = Number(document.getElementById("longitude").value);

    this.generatePlaceName();

    let args = {
      placeId: placeInfo.placeId || -1, // MGL_PLACE_HDRID ( -1 => If it is new place )
      state: selectedState.id || -1, // STATE
      city: selectedCity.id || -1, // CITY
      customerId: selectedCustomer.id || -1, // CUSTOMERID
      pincode: Number(document.getElementById("gSearchByPincode").value.trim()), // PINCODE
      placeName: document.getElementById("placeName").value.trim(), // PLACENAME
      placeCode: document.getElementById("placeCode").value.trim(), // PLACECODE
      customerPlace: document.getElementById("customerPlace").value.trim(), // CUSTPLACE
      lat: lat, // LAT
      lng: lng, // LNG
      address: document.getElementById("address").value.trim(), // ADDRESS1
      addressLink: "https://www.google.com/maps?q=" + lat + "," + lng, // GOOGLE_AD
      partyPlaceCode: selectedCustomer.prefix || "",
    };

    // validations
    let errorCount = 0;
    if (args.state === -1) {
      errorCount++;
    }
    if (args.city === -1) {
      errorCount++;
    }
    if (args.pincode === 0) {
      errorCount++;
    }
    if (args.placeName === "") {
      errorCount++;
    }
    if (args.placeCode === "") {
      errorCount++;
    }
    if (args.customerPlace === "") {
      errorCount++;
    }
    if (!selectedConsignee.id && args.customerId !== 14609000000000) {
      errorCount++;
    }
    if (errorCount > 0) {
      commonJs.showNotify(
        this.props,
        NOTIFY.WARNING,
        WARNINGS.PLEASE_ENTER_MANDATORY_FIELDS
      );
      return;
    }

    if (isNaN(args.pincode)) {
      commonJs.showNotify(this.props, NOTIFY.WARNING, "Invalid Pincode");
      return;
    }

    // If Edit confirm lat,lng
    if (!updateLatLng && args.placeId > -1) {
      args.lat = placeInfo.location.lat;
      args.lng = placeInfo.location.lng;
    }

    const self = this;
    this.props.setLoading({ loading: true });
    console.log("managePlaceMaster: ", args);
    API_HANDLER.managePlaceMaster(args)
      .then((res) => {
        self.props.setLoading({ loading: false });
        if (res.success) {
          commonJs.showNotify(
            self.props,
            NOTIFY.SUCCESS,
            SUCCESS.RECORD_SAVED_SUCCESSFUL
          );
          // close form
          self.props.closeDialog();
        } else {
          commonJs.showNotify(
            self.props,
            NOTIFY.ERROR,
            ERRORS.RECORD_NOT_SAVED
          );
        }
      })
      .catch((err) => {
        console.log("err: ", err);
        commonJs.showNotify(self.props, NOTIFY.ERROR, ERRORS.RECORD_NOT_SAVED);
      });
  };

  onSearch = (lat = 0, lng = 0) => {
    this.props.setTmpMarker({ tmpMarker: { lat, lng } });
    this.props.updateRegion(lat, lng, DEFAULTS.PLACE_ZOOM);
  };

  onAddressSelect = (address) => {
    let self = this;
    this.setState({
      address,
    });
    geocodeByAddress(address)
      .then((results) => {
        self.props.setAddress({
          addressJson: results,
        });
        self.setLatLng(results[0].geometry.location);
        self.setCityState(results[0].address_components);
        document.getElementById("address").value = results[0].formatted_address;
      })
      .catch((error) => console.error("Error", error));
  };

  onAddressChange = (address) => {
    this.setState({
      address,
    });
  };

  setCityState = (addressList = []) => {
    console.log("setCityState: ", addressList);
    let city = "";
    let state = "";
    let pincode = "";
    for (let i = 0; i < addressList.length; i++) {
      city = commonJs.getCityNameFromAddress(addressList);
      if (addressList[i].types.indexOf("administrative_area_level_1") > -1) {
        state = addressList[i].long_name;
      }
      if (addressList[i].types.indexOf("postal_code") > -1) {
        pincode = addressList[i].short_name;
      }
    }
    if (city === "") {
      city = state;
    }
    document.getElementById("gSearchByPincode").value = pincode;
    document.getElementById("customerPlace").value = city;

    const { stateList } = this.props.mapFormReducer;
    // get state id
    let selectedState = stateList.filter(
      (s) => s.name.toUpperCase().trim() === state.toUpperCase().trim()
    );
    if (selectedState.length > 0) {
      selectedState = selectedState[0];
      const self = this;
      API_HANDLER.getCitiesList({
        stateId: selectedState.id,
        pageNo: 1,
        pageLength: DEFAULTS.PAGE_LENGTH,
        searchQry: "",
        sortingOrder: DEFAULTS.SORTING_ORDER,
      }).then((res) => {
        if (res.success) {
          const { records } = res.response;
          const cityList = records;
          // get city id
          let selectedCity = cityList.filter(
            (c) => c.name.toUpperCase().trim() === city.toUpperCase().trim()
          );
          console.log("filter: ", cityList, city);
          self.props.setCities({
            cityList: records,
          });
          self.props.setCityState({
            selectedCity,
            selectedState,
          });
        }
      });
    }
  };

  setLatLng = (addressObj = {}) => {
    document.getElementById("latitude").value = addressObj.lat();
    document.getElementById("longitude").value = addressObj.lng();
    this.onSearch(addressObj.lat(), addressObj.lng());
  };

  onChangeState = (val) => {
    let v = val;
    if (!val) {
      v = {};
    } else {
      this.getCityList(val.id);
    }
    let addressText = "";
    if (v.name) {
      addressText = v.name + ",";
    }
    this.props.setState({
      selectedState: v,
    });
    this.setState({
      address: addressText,
    });
  };

  onChangeCity = (val) => {
    const { selectedState } = this.props.mapFormReducer;
    let v = val;
    if (!val) {
      v = {};
    }
    let addressText = "";
    if (selectedState.name) {
      addressText += selectedState.name + ",";
    }
    if (v.name) {
      addressText += v.name + ",";
    }
    this.setState({
      address: addressText,
    });
    this.props.setCity({
      selectedCity: v,
    });
  };

  onChangeCustomer = (val) => {
    let v = val;
    if (!val) {
      v = {};
    }
    this.props.setCustomer({
      selectedCustomer: v,
    });
    this.props.setConsignees({
      consigneeList: [],
    });
    this.props.setSelectedConsignee({
      selectedConsignee: {},
    });
    // Get Consignee List
    const self = this;
    if (v.id) {
      API_HANDLER.getConsigneeList({
        customerId: v.id,
      }).then((res) => {
        if (res.success) {
          self.props.setConsignees({
            consigneeList: res.response,
          });
        }
        // Get Party Place Code
        self.getPlaceCode(v);
      });
    }
  };

  getPlaceCode = (v = {}) => {
    const self = this;
    const { placeInfo } = this.props.placeReducer;
    console.log("getPlaceCode: ", v);
    if (!placeInfo.placeId) {
      const ele = document.getElementById("placeCode");
      ele.value = "";
      if (v.prefix) {
        API_HANDLER.getCustomerPlaceCode({
          prefix: v.prefix,
        }).then((res) => {
          if (res.success) {
            if (ele) {
              ele.value = res.response;
            }
          }
        });
      } else {
        commonJs.showNotify(
          self.props,
          NOTIFY.WARNING,
          WARNINGS.PARTY_PLACE_CODE_NOT_FOUND
        );
      }
    }
  };

  onChangeConsignee = (val) => {
    let v = val;
    if (!val) {
      v = {};
    }
    this.props.setSelectedConsignee({
      selectedConsignee: v,
    });
    const self = this;
    setTimeout(() => {
      self.generatePlaceName();
    }, 500);
  };

  onPincodeChange = (e) => {
    const { selectedState, selectedCity } = this.props.mapFormReducer;
    let addressText = "";
    if (selectedState.name) {
      addressText += selectedState.name + ",";
    }
    if (selectedCity.name) {
      addressText += selectedCity.name + ",";
    }
    addressText += e.target.value + ",";
    this.setState({
      address: addressText,
    });
  };

  generatePlaceName = () => {
    const { selectedState, selectedCity, selectedCustomer, selectedConsignee } =
      this.props.mapFormReducer;
    const { placeInfo } = this.props.placeReducer;

    // Don't Generate Place Name for EDIT
    if (placeInfo.placeId) {
      return;
    }

    const customerPlace = document.getElementById("customerPlace").value || "";

    let placeText = "";
    if (selectedCustomer.name) {
      placeText += selectedCustomer.code + "_";
    }
    if (selectedConsignee.name && selectedCustomer.id !== 14609000000000) {
      placeText += selectedConsignee.name + "_";
    }
    if (customerPlace !== "") {
      placeText += customerPlace + "_";
    }
    if (selectedCity.name) {
      placeText += selectedCity.name + "_";
    }
    if (selectedState.name) {
      placeText += selectedState.code;
    }

    document.getElementById("placeName").value = placeText.toUpperCase();
  };

  toggleUpdateLatLng = () => {
    const { updateLatLng } = this.state;
    this.setState({
      updateLatLng: !updateLatLng,
    });
  };

  render() {
    const { address, updateLatLng } = this.state;
    const { mapReducer, placeReducer, mapFormReducer } = this.props;
    const { tmpMarker } = mapReducer;
    const { placeInfo } = placeReducer;
    const {
      stateList,
      selectedState,
      cityList,
      selectedCity,
      customerList,
      selectedCustomer,
      consigneeList,
      selectedConsignee,
    } = mapFormReducer;
    console.log("MapForm:", tmpMarker);
    return (
      <React.Fragment>
        <div
          style={{
            flexDirection: "column",
            paddingRight: 10,
          }}
        >
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div className="formGroup" style={{ width: "45%" }}>
              <div className="mB5">
                <label className="formLabel">
                  {LABELS.STATE + " :"}
                  <span className="red">{" *"}</span>
                </label>
              </div>
              <Autocomplete
                id="gSearchByState"
                style={{ width: "100%" }}
                list={stateList}
                defaultValue={selectedState}
                multiple={false}
                size="small"
                onChange={this.onChangeState}
              />
            </div>
            <div className="formGroup" style={{ width: "45%" }}>
              <div className="mB5">
                <label className="formLabel">
                  {LABELS.CITY + " :"}
                  <span className="red">{" *"}</span>
                </label>
              </div>
              <Autocomplete
                id="gSearchByCity"
                style={{ width: "100%" }}
                list={cityList}
                defaultValue={selectedCity}
                multiple={false}
                size="small"
                onChange={this.onChangeCity}
              />
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div className="formGroup" style={{ width: "45%" }}>
              <div className="mB5">
                <label className="formLabel">
                  {LABELS.PINCODE + " :"}
                  <span className="red">{" *"}</span>
                </label>
              </div>
              <Txt id="gSearchByPincode" onKeyPress={this.onPincodeChange} />
            </div>
          </div>
          <div className="formGroup" style={{ width: "100%" }}>
            <div className="mB5">
              <label className="formLabel">
                {"Search " + LABELS.LOCATION + " :"}
              </label>
            </div>
            <PlacesAutocomplete
              value={address}
              searchOptions={{
                componentRestrictions: {
                  country: "in",
                },
              }}
              onChange={this.onAddressChange}
              onSelect={this.onAddressSelect}
            >
              {({
                getInputProps,
                suggestions,
                getSuggestionItemProps,
                loading,
              }) => (
                <div className="txt">
                  <input
                    {...getInputProps({
                      placeholder: "Search",
                      className: "",
                      id: "gSearchPlace",
                    })}
                  />
                  <div className="autocomplete-dropdown-container">
                    {suggestions.map((suggestion) => {
                      const className = suggestion.active
                        ? "suggestion-item--active"
                        : "suggestion-item";
                      // inline style for demonstration purpose
                      const style = suggestion.active
                        ? { backgroundColor: "#fafafa", cursor: "pointer" }
                        : { backgroundColor: "#ffffff", cursor: "pointer" };
                      return (
                        <div
                          {...getSuggestionItemProps(suggestion, {
                            className,
                            style,
                          })}
                        >
                          <span>{suggestion.description}</span>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </PlacesAutocomplete>
          </div>
        </div>

        <div
          style={{
            flexDirection: "column",
            paddingRight: 10,
          }}
        >
          <div className="formGroup">
            <div className="mB5">
              <label className="formLabel">
                {LABELS.ADDRESS + " :"}
                <span className="red">{" *"}</span>
              </label>
            </div>
            <Txt id="address" rows={2} />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              marginBottom: 5,
            }}
          >
            <div className="formGroup width45">
              <div className="mB5">
                <label className="formLabel">
                  {LABELS.LATITUDE + " :"}
                  <span className="red">{" *"}</span>
                </label>
              </div>
              <Txt id="latitude" disabled={true} />
            </div>
            <div className="formGroup width45">
              <div className="mB5">
                <label className="formLabel">
                  {LABELS.LONGITUDE + " :"}
                  <span className="red">{" *"}</span>
                </label>
              </div>
              <Txt id="longitude" disabled={true} />
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            {tmpMarker.lat !== undefined && (
              <div className="formGroup" style={{ width: "45%" }}>
                <div className="mB5">
                  <label className="formLabel">
                    {LABELS.PLACE_CODE + " :"}
                    <span className="red">{" *"}</span>
                  </label>
                </div>
                <Txt id="placeCode" autoCapitalize={true} />
              </div>
            )}
            {tmpMarker.lat !== undefined && (
              <div className="formGroup" style={{ width: "45%" }}>
                <div className="mB5">
                  <label className="formLabel">
                    {LABELS.CUSTOMER_PLACE + " :"}
                    <span className="red">{" *"}</span>
                  </label>
                </div>
                <Txt id="customerPlace" autoCapitalize={true} />
              </div>
            )}
          </div>
          <div
            className="formGroup"
            style={{ display: tmpMarker.lat ? "block" : "none" }}
          >
            <div className="mB5">
              <label className="formLabel">
                {LABELS.CUSTOMER_NAME + " :"}
                <span className="red">{" *"}</span>
              </label>
            </div>
            <Autocomplete
              id="customerName"
              style={{ width: "100%", fontSize: 12 }}
              list={customerList}
              defaultValue={selectedCustomer}
              multiple={false}
              size="small"
              onChange={this.onChangeCustomer}
            />
          </div>
          <div
            className="formGroup"
            style={{ display: tmpMarker.lat ? "block" : "none" }}
          >
            <div className="mB5">
              <label className="formLabel">
                {LABELS.CONSIGNEE_NAME + " :"}
                <span className="red">{" *"}</span>
              </label>
            </div>
            <Autocomplete
              id="consigneeName"
              style={{ width: "100%", fontSize: 12 }}
              list={consigneeList}
              defaultValue={selectedConsignee}
              multiple={false}
              size="small"
              onChange={this.onChangeConsignee}
            />
          </div>
          <div
            className="formGroup"
            style={{ display: tmpMarker.lat ? "block" : "none" }}
          >
            <div className="mB5">
              <label className="formLabel">
                {LABELS.PLACE_NAME + " :"}
                <span className="red">{" *"}</span>
              </label>
            </div>
            <Txt id="placeName" onFocus={this.generatePlaceName} />
          </div>
          {tmpMarker.lat !== undefined && (
            <div
              className="formGroup"
              style={{
                marginLeft: -10,
                display: placeInfo.placeId ? "flex" : "none",
                alignItems: "center",
                fontSize: 14,
                fontWeight: "500",
              }}
            >
              <Checkbox
                checked={updateLatLng}
                className="chkBox"
                onChange={this.toggleUpdateLatLng}
              />
              <label onClick={this.toggleUpdateLatLng} id="updateLatLng">
                {"Update Latitude & Longitude"}
              </label>
            </div>
          )}
          {tmpMarker.lat !== undefined && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                width: "100%",
                marginTop: 20,
              }}
            >
              <div>
                <Btn className={"primaryBtn saveBtn"} onClick={this.saveRecord}>
                  {placeInfo.placeId ? "UPDATE" : "ADD"}
                </Btn>
              </div>
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    mapReducer: state.mapReducer,
    placeReducer: state.placeReducer,
    mapFormReducer: state.mapFormReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setAddress: (values) => {
      dispatch({
        type: PLACE_ACTIONS.SET_ADDRESS,
        values,
      });
    },
    setMapPoints: (values) => {
      dispatch({
        type: MAP_ACTIONS.SET_MAP_POINTS,
        values,
      });
    },
    setTmpMarker: (values) => {
      dispatch({
        type: MAP_ACTIONS.SET_TMP_MARKER,
        values,
      });
    },
    setLoading: (values) => {
      dispatch({
        type: PAGE_ACTIONS.SET_LOADING,
        values,
      });
    },
    setPlaceInfo: (values) => {
      dispatch({
        type: PLACE_ACTIONS.SET_PLACE_INFO,
        values,
      });
    },
    setPlaceMaster: (values) => {
      dispatch({
        type: PLACE_ACTIONS.SET_PLACE_MASTER,
        values,
      });
    },
    setStates: (values) => {
      dispatch({
        type: MAP_FORM_ACTIONS.SET_STATES,
        values,
      });
    },
    setCities: (values) => {
      dispatch({
        type: MAP_FORM_ACTIONS.SET_CITIES,
        values,
      });
    },
    setCustomers: (values) => {
      dispatch({
        type: MAP_FORM_ACTIONS.SET_CUSTOMERS,
        values,
      });
    },
    setCityState: (values) => {
      dispatch({
        type: MAP_FORM_ACTIONS.SET_CITY_STATE,
        values,
      });
    },
    setCity: (values) => {
      dispatch({
        type: MAP_FORM_ACTIONS.SET_SELECTED_CITY,
        values,
      });
    },
    setState: (values) => {
      dispatch({
        type: MAP_FORM_ACTIONS.SET_SELECTED_STATE,
        values,
      });
    },
    setCustomer: (values) => {
      dispatch({
        type: MAP_FORM_ACTIONS.SET_SELECTED_CUSTOMER,
        values,
      });
    },
    setConsignees: (values) => {
      dispatch({
        type: MAP_FORM_ACTIONS.SET_CONSIGNEES,
        values,
      });
    },
    setSelectedValues: (values) => {
      dispatch({
        type: MAP_FORM_ACTIONS.SET_SELECTED_VALUES,
        values,
      });
    },
    setSelectedConsignee: (values) => {
      dispatch({
        type: MAP_FORM_ACTIONS.SET_SELECTED_CONSIGNEE,
        values,
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(MapForm));
