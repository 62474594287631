import React, { memo } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";

import appLogo from "../../../images/logo/logo.png";

const PageLoader = (props) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        height: "100%",
        zIndex: 99999999,
        flexDirection: "column",
      }}
    >
      <img src={appLogo} width={150} style={{ marginBottom: 15 }} alt="" />
      <CircularProgress className={"primary"} size="1.5rem" />
    </div>
  );
};

export default memo(PageLoader);
