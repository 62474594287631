import React, { Component } from "react";
import jQuery from "jquery";
import { connect } from "react-redux";
import { CircularProgress, Tab, Tabs } from "@material-ui/core";
import "datatables.net-bs4";
import "datatables.net-buttons-bs4";
import "datatables.net-buttons/js/buttons.html5";
import "datatables.net-buttons/js/buttons.print";
import "datatables.net-buttons/js/buttons.flash";

import EmployeesGrid from "./Hr/EmployeesGrid";
import LeaveRequests from "./Hr/LeaveRequests";

import { LABELS } from "../../common/consts";

import "./styles.css";
import "./reports.css";

var $ = jQuery;

class HrDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      activeTab: 0,
    };
  }

  componentDidMount() {
    console.log("Mount");
  }

  onTabChange = (e, val) => {
    this.setState({
      activeTab: val,
    });
  };

  render() {
    const { loading, activeTab } = this.state;
    const { userName } = this.props.loginReducer;
    return (
      <div id="adminDashboard">
        <div style={{ width: "95%", margin: "auto" }}>
          <h4
            style={{
              fontSize: 24,
              margin: 0,
              paddingTop: 25,
              paddingBottom: 15,
            }}
          >
            {"Dashboard"}
          </h4>
        </div>

        {/* Menus */}
        {loading ? (
          <div
            style={{
              width: "100%",
              height: 300,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress className={"primary"} size="1.5rem" />
          </div>
        ) : (
          <div style={{ width: "100%", margin: "auto", paddingLeft: "2.5%" }}>
            <Tabs
              value={activeTab}
              indicatorColor="primary"
              textColor="primary"
              onChange={this.onTabChange}
              className="uiTab"
            >
              <Tab label="Employees" />
              <Tab label={LABELS.LEAVE_REQUESTS} />
            </Tabs>
            {activeTab === 0 && <EmployeesGrid />}
            {activeTab === 1 && <LeaveRequests />}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loginReducer: state.loginReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(HrDashboard);
