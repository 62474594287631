import React, { Component } from "react";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  TimePicker,
} from "@material-ui/pickers";

import UiDialog from "../dialogbox/UiDialog";
import AutoComplete from "../autocomplete/AutoComplete";

import { dateFunctions } from "../../../common/datefunctions";
import { FORMATS } from "../../../common/consts";

import "./uidatepicker.css";

class UiDatePicker extends Component {
  constructor(props) {
    super(props);
    let hrs = [],
      mins = [],
      secs = [];
    for (let i = 0; i < 24; i++) {
      let hr = i.toString();
      if (i < 10) {
        hr = "0" + hr;
      }
      hrs.push({ id: hr, name: hr });
    }
    for (let i = 0; i < 60; i++) {
      let min = i.toString();
      if (i < 10) {
        min = "0" + min;
      }
      mins.push({ id: min, name: min });
      secs.push({ id: min, name: min });
    }
    const { type, dt } = this.props;
    this.state = {
      showTimeDialog: false,
      selectedHr:
        type === "from" ? { id: "00", name: "00" } : { id: "23", name: "23" },
      selectedMin:
        type === "from" ? { id: "00", name: "00" } : { id: "59", name: "59" },
      selectedSec:
        type === "from" ? { id: "00", name: "00" } : { id: "59", name: "59" },
      hrs: hrs,
      mins: mins,
      secs: secs,
      selectedDate: dt,
    };
  }

  handleDateChange = (type, dt, dateonly) => {
    if (!dateonly) {
      this.setState({
        showTimeDialog: true,
        selectedDate: moment(dt).format(FORMATS.SQL_DATE),
      });
    } else {
      let selectedDate = moment(dt).format(FORMATS.SQL_DATE);
      this.setState({
        selectedDate: selectedDate,
      });
      this.props.handleDateChange(type, selectedDate);
    }
  };

  toggleShowTime = () => {
    const { showTimeDialog } = this.state;
    this.setState({
      showTimeDialog: !showTimeDialog,
    });
  };

  setTime = () => {
    const { selectedHr, selectedMin, selectedSec, selectedDate } = this.state;

    let hh = "00";
    let mm = "00";
    let ss = "00";
    if (selectedHr) {
      hh = selectedHr.id;
    }
    if (selectedMin) {
      mm = selectedMin.id;
    }
    if (selectedSec) {
      ss = selectedSec.id;
    }
    let rslt =
      dateFunctions.getFormatedDate(
        selectedDate,
        FORMATS.SQL_DATE,
        "YYYY-MM-DD"
      ) +
      " " +
      hh +
      ":" +
      mm +
      ":" +
      ss;
    this.props.handleDateChange(this.props.type, rslt);
    this.setState({
      showTimeDialog: false,
    });
  };

  uppendTime = () => {};

  onChangeHr = (val) => {
    this.setState({
      selectedHr: val,
    });
  };

  onChangeMin = (val) => {
    this.setState({
      selectedMin: val,
    });
  };

  onChangeSec = (val) => {
    this.setState({
      selectedSec: val,
    });
  };

  handleTimeChange = (val) => {
    const rslt = moment(val).format("YYYY-MM-DD HH:mm:") + "00";
    this.props.handleDateChange(this.props.type, rslt);
  };

  render() {
    const {
      label,
      type,
      datepicker,
      datetimePicker,
      disablePast,
      disableFuture,
      disabled,
      maxDate,
      minDate,
      dt,
    } = this.props;

    const {
      showTimeDialog,
      selectedHr,
      hrs,
      selectedMin,
      mins,
      selectedSec,
      secs,
    } = this.state;

    console.log("dt: ", dt);

    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        {datepicker ? (
          <KeyboardDatePicker
            disableToolbar={false}
            disablePast={disablePast}
            disableFuture={disableFuture}
            autoOk={false}
            variant="dialog"
            label={label}
            format="yyyy-MM-dd"
            helperText=""
            onChange={(dt) => {
              this.handleDateChange(type, dt, true);
            }}
            className="datetime-picker"
            allowKeyboardControl={true}
            ampm={true}
            disabled={disabled}
            maxDate={maxDate}
            minDate={minDate}
            value={dt}
          />
        ) : datetimePicker ? (
          <KeyboardDatePicker
            disableToolbar={false}
            disablePast={disablePast}
            disableFuture={disableFuture}
            autoOk={false}
            variant="dialog"
            label={label}
            format="dd-MM-yyyy HH:mm:ss"
            helperText=""
            onChange={(dt) => {
              this.handleDateChange(type, dt, false);
            }}
            className="datetime-picker"
            allowKeyboardControl={true}
            ampm={true}
            disabled={disabled}
            maxDate={maxDate}
            minDate={minDate}
            value={dt}
          />
        ) : (
          <TimePicker
            className="timePicker"
            value={dt}
            format="HH:mm"
            ampm={false}
            onChange={this.handleTimeChange}
          />
        )}
        <UiDialog
          open={showTimeDialog}
          onCloseSettings={this.toggleShowTime}
          title={"Select Time"}
          maxWidth="xs"
          fullWidth={true}
          saveButton={true}
          cancelButton={true}
          cancelAction={this.toggleShowTime}
          saveAction={this.setTime}
          saveBtnLabel="OK"
          classNameWrapper="dateTimeWrapper"
          draggable={false}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: 15,
              marginTop: 10,
            }}
          >
            <AutoComplete
              label={"Select Hour"}
              list={hrs}
              id="timeHr"
              style={{ width: "30%" }}
              onChange={this.onChangeHr}
              defaultValue={selectedHr}
              multiple={false}
              size="small"
            />
            <AutoComplete
              label={"Select Minutes"}
              list={mins}
              id="timeMin"
              style={{ width: "30%" }}
              onChange={this.onChangeMin}
              defaultValue={selectedMin}
              multiple={false}
              size="small"
            />
            <AutoComplete
              label={"Select Seconds"}
              list={secs}
              id="timeSec"
              style={{ width: "30%" }}
              onChange={this.onChangeSec}
              defaultValue={selectedSec}
              multiple={false}
              size="small"
            />
          </div>
        </UiDialog>
      </MuiPickersUtilsProvider>
    );
  }
}

export default UiDatePicker;
