import { MAP_ACTIONS } from "../actions/MapActions";
import { DEFAULTS, LOCAL_STORAGE } from "../common/consts";

let mapSettings = localStorage[LOCAL_STORAGE.MAP_SETTINGS];
if (mapSettings !== undefined) {
  mapSettings = JSON.parse(mapSettings);
}
let initialState = {
  zoom: mapSettings ? mapSettings.zoom : DEFAULTS.DEFAULT_ZOOM,
  initialLat: mapSettings ? mapSettings.lat : DEFAULTS.DEFAULT_LAT,
  initialLng: mapSettings ? mapSettings.lng : DEFAULTS.DEFAULT_LNG,
  mapType: DEFAULTS.DEFAULT_MAP,
  scale: true,
  mapPoints: [],
  tmpMarker: {},
};

const mapReducer = (state = initialState, action) => {
  const { type, values } = action;
  switch (type) {
    case MAP_ACTIONS.SET_MAP_POINTS:
      return { ...state, mapPoints: values.mapPoints };
    case MAP_ACTIONS.SET_TMP_MARKER:
      return { ...state, tmpMarker: values.tmpMarker };
    default:
      break;
  }
  return state;
};

export default mapReducer;
