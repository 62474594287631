import React, { Component } from "react";
import { connect } from "react-redux";

import appLogo from "../../images/logo/logo.png";

class WelcomePage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { userName } = this.props.loginReducer;
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      >
        <img src={appLogo} />
        <br />
        <br />
        <h1
          style={{
            textAlign: "center",
            width: "100%",
            textTransform: "capitalize",
          }}
        >
          Welcome {userName}
        </h1>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loginReducer: state.loginReducer,
    pageReducer: state.pageReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(WelcomePage);
