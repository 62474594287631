import { PLACE_ACTIONS } from "../actions/PlaceActions";

let initialState = {
  placeMaster: [],
  placeInfo: {},
  addressJson: [],
};

const placeReducer = (state = initialState, action) => {
  const { type, values } = action;
  switch (type) {
    case PLACE_ACTIONS.SET_PLACE_MASTER:
      return { ...state, placeMaster: values.placeMaster };
    case PLACE_ACTIONS.SET_PLACE_INFO:
      return { ...state, placeInfo: values.placeInfo };
    case PLACE_ACTIONS.SET_ADDRESS:
      return { ...state, addressJson: values.addressJson };
    default:
      break;
  }
  return state;
};

export default placeReducer;
