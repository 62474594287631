export const MAP_FORM_ACTIONS = {
  SET_STATES: "SET_STATES",
  SET_CITIES: "SET_CITIES",
  SET_CUSTOMERS: "SET_CUSTOMERS",
  SET_CONSIGNEES: "SET_CONSIGNEES",
  SET_SELECTED_STATE: "SET_SELECTED_STATE",
  SET_SELECTED_CITY: "SET_SELECTED_CITY",
  SET_SELECTED_CUSTOMER: "SET_SELECTED_CUSTOMER",
  SET_SELECTED_CONSIGNEE: "SET_SELECTED_CONSIGNEE",
  RESET_MAP_FORM: "RESET_MAP_FORM",
  SET_CITY_STATE: "SET_CITY_STATE",
  SET_SELECTED_VALUES: "SET_SELECTED_VALUES",
};
