import React, { Component } from "react";
import { withSnackbar } from "notistack";
import { FormControlLabel, Switch } from "@material-ui/core";

import AutoComplete from "../../ui/autocomplete/AutoComplete";
import Btn from "../../ui/button/Btn";
import Txt from "../../ui/textbox/Txt";

import {
  ERRORS,
  LABELS,
  NOTIFY,
  SUCCESS,
  CONFIG,
} from "../../../common/consts";
import { API_HANDLER } from "../../../apihandlers";
import { commonJs } from "../../../common/scripts";

class UserDialog extends Component {
  constructor(props) {
    super(props);

    const { employee } = this.props;

    let selectedEmployee = {};
    if (employee.empId) {
      selectedEmployee = { id: employee.empId, name: employee.empName };
    } else {
      selectedEmployee = {};
    }

    let userRoles = [];
    for (let urole in CONFIG.USER_ROLES) {
      userRoles.push({
        id: CONFIG.USER_ROLES[urole],
        name: urole,
      });
    }

    this.state = {
      selectedEmployee,
      isActive: employee.active === "T",
      userRoles,
      selectedRole: employee.userRole || 1,
    };
  }

  componentDidMount() {
    const { employee } = this.props;
    document.getElementById("userName").value = employee.userName || "";
  }

  onChangeEmployee = (selectedEmployee = {}) => {
    this.setState({
      selectedEmployee,
    });
  };

  saveRecord = () => {
    const { selectedEmployee, selectedRole, isActive } = this.state;
    const { employee } = this.props;
    const userName = document.getElementById("userName").value.trim();
    const userPwd = document.getElementById("userPwd").value.trim();

    if (userName === "") {
      commonJs.showNotify(
        this.props,
        NOTIFY.WARNING,
        "Please enter User Name..!"
      );
      return;
    }
    if (employee.userId === "") {
      if (userPwd === "") {
        commonJs.showNotify(
          this.props,
          NOTIFY.WARNING,
          "Please enter User Password..!"
        );
        return;
      }
      if (userPwd.length < 6) {
        commonJs.showNotify(
          this.props,
          NOTIFY.WARNING,
          "Password length should be greater than 6 characters..!"
        );
        return;
      }
    }
    if (!selectedEmployee.id) {
      commonJs.showNotify(
        this.props,
        NOTIFY.WARNING,
        "Please select employee..!"
      );
      return;
    }

    const args = {
      userId: employee.userId || -1,
      empId: selectedEmployee.id || null,
      userRole: selectedRole,
      userName,
      userPwd,
      active: isActive ? "T" : "F",
    };

    const self = this;
    API_HANDLER.saveUser(args).then((res) => {
      if (res.success) {
        commonJs.showNotify(
          self.props,
          NOTIFY.SUCCESS,
          SUCCESS.RECORD_SAVED_SUCCESSFUL
        );
        self.props.reloadGrid();
        self.props.onCloseDialog();
      } else {
        commonJs.showNotify(self.props, NOTIFY.ERROR, ERRORS.RECORD_NOT_SAVED);
      }
    });
  };

  onChangeIsActive = (isActive = false) => {
    this.setState({
      isActive,
    });
  };

  setRole = (selectedRole = 1) => {
    this.setState({
      selectedRole,
    });
  };

  render() {
    const { selectedEmployee, isActive, userRoles, selectedRole } = this.state;
    const { employee, employeeList } = this.props;

    return (
      <div style={{ display: "flex", flexDirection: "column" }}>
        {/* Username */}
        <div className="formGroup" style={{ width: "100%" }}>
          <div className="mB5">
            <label className="formLabel">
              {LABELS.USERNAME + " :"}
              <span className="red">{" *"}</span>
            </label>
          </div>
          <Txt id="userName" placeHolder={LABELS.USERNAME} />
        </div>

        {/* Password */}
        <div className="formGroup" style={{ width: "100%" }}>
          <div className="mB5">
            <label className="formLabel">
              {LABELS.PASSWORD + " :"}
              <span className="red">{" *"}</span>
            </label>
          </div>
          <Txt id="userPwd" placeHolder={LABELS.PASSWORD} />
        </div>

        {/* Employee */}
        <div className="formGroup" style={{ width: "100%" }}>
          <div className="mB5">
            <label className="formLabel">
              {LABELS.EMPLOYEE + " :"}
              <span className="red">{" *"}</span>
            </label>
          </div>
          <AutoComplete
            id="empList"
            style={{ width: "100%" }}
            list={employeeList}
            defaultValue={selectedEmployee}
            multiple={false}
            disabled={true}
            size="small"
            onChange={this.onChangeEmployee}
          />
        </div>

        <div className="formGroup" style={{ width: "100%" }}>
          <div className="mB5">
            <label className="formLabel">
              {"User Role :"}
              <span className="red">{" *"}</span>
            </label>
          </div>
          <div
            style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}
          >
            {userRoles.map((u) => {
              return (
                <div className="selectBtn" style={{ width: "25%" }}>
                  <Btn
                    className={
                      u.id === selectedRole ? "primaryBtn saveBtn" : "saveBtn"
                    }
                    disabled={
                      selectedRole === 4
                        ? u.id !== selectedRole
                          ? true
                          : false
                        : false
                    }
                    onClick={() => this.setRole(u.id)}
                  >
                    {u.name}
                  </Btn>
                </div>
              );
            })}
          </div>
        </div>

        <div
          className="formGroup"
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            marginTop: 5,
          }}
        >
          <div>
            <label className="formLabel" style={{ marginRight: 15 }}>
              {"Active"}
              <span className="red">{" *"}</span>
            </label>
          </div>
          <FormControlLabel
            control={
              <Switch
                classes={{
                  thumb: "switchThumb",
                  track: "switchTrack",
                  root: "switchRoot",
                }}
                defaultChecked={isActive}
                onChange={() => this.onChangeIsActive(!isActive)}
              />
            }
            label=""
            className="switch"
          />
        </div>

        <div
          className="formGroup"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            width: "100%",
            marginTop: 30,
          }}
        >
          <div>
            <Btn className={"primaryBtn saveBtn"} onClick={this.saveRecord}>
              {LABELS.SAVE}
            </Btn>
          </div>
        </div>
      </div>
    );
  }
}

export default withSnackbar(UserDialog);
