import React, { Component } from "react";
import {
  SortingState,
  IntegratedSorting,
  PagingState,
  CustomPaging,
} from "@devexpress/dx-react-grid";
import {
  Grid,
  VirtualTable,
  TableHeaderRow,
  TableColumnResizing,
  PagingPanel,
} from "@devexpress/dx-react-grid-material-ui";
import { IconButton, Tooltip, CircularProgress } from "@material-ui/core";
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Replay as ReloadIcon,
  AddBox as AddIcon,
} from "@material-ui/icons";
import { connect } from "react-redux";

import Txt from "../ui/textbox/Txt";

import { CONFIG, LABELS } from "../../common/consts";
import { API_HANDLER } from "../../apihandlers";

import "./styles.css";

class OwnVehicles extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageNo: 0,
      rows: [],
      columns: [
        {
          name: "slno",
          title: "Sl No.",
        },
        {
          name: "vehicleNumber",
          title: "Vehicle No.",
        },
        { name: "loadStatus", title: "Load Status" },
        { name: "currentLocation", title: "Current Location" },
        { name: "driverName", title: "Driver Name" },
        { name: "driverNumber", title: "Driver No." },
        { name: "indent", title: "Indent" },
        { name: "diesel", title: "Diesel" },
        { name: "lr", title: "LR" },
        { name: "arrival", title: "Arrival" },
        { name: "allocate", title: "Allocate" },
      ],
      loading: false,
      totalRows: 0,
      defaultSorting: [{ columnName: "slno", direction: "asc" }],
    };

    this._tableColumnWidths = [
      {
        columnName: "slno",
        width: 100,
      },
      { columnName: "vehicleNumber", width: 180 },
      { columnName: "loadStatus", width: 120 },
      { columnName: "currentLocation", width: 180 },
      { columnName: "driverName", width: 150 },
      { columnName: "driverNumber", width: 120 },
      { columnName: "indent", width: 80 },
      { columnName: "diesel", width: 80 },
      { columnName: "lr", width: 80 },
      { columnName: "arrival", width: 80 },
      { columnName: "allocate", width: 80 },
    ];

    this._tableColumnExtensions = [
      {
        columnName: "slno",
        align: "center",
      },
      { columnName: "vehicleNumber", align: "left" },
      { columnName: "loadStatus", align: "left" },
      { columnName: "currentLocation", align: "left" },
      { columnName: "driverName", align: "left" },
      { columnName: "driverNumber", align: "left" },
      { columnName: "indent", align: "left" },
      { columnName: "diesel", align: "center" },
      { columnName: "lr", align: "center" },
      { columnName: "arrival", align: "center" },
      { columnName: "allocate", align: "center" },
    ];

    this._sortingDisabledColumns = [
      { columnName: "currentLocation", sortingEnabled: false },
      { columnName: "driverNumber", sortingEnabled: false },
      { columnName: "indent", sortingEnabled: false },
      { columnName: "diesel", sortingEnabled: false },
      { columnName: "lr", sortingEnabled: false },
      { columnName: "arrival", sortingEnabled: false },
      { columnName: "allocate", sortingEnabled: false },
    ];

    this._timer = null;
  }

  componentDidMount() {
    this.loadRecords();
  }

  loadRecords = (reset = false) => {
    const { pageNo, defaultSorting } = this.state;
    let sortColumn = defaultSorting[0]["columnName"];
    if (sortColumn === "slno") {
      sortColumn = "vehicleNumber";
    }
    const args = {
      pageNo: reset ? 1 : pageNo + 1,
      pageLength: CONFIG.PAGE_LENGTH,
      searchQry: "",
      sortColumn: sortColumn,
      sortingOrder: defaultSorting[0]["direction"],
    };
    if (document.getElementById("ownVehiclesSearch")) {
      args.searchQry = document
        .getElementById("ownVehiclesSearch")
        .value.toUpperCase();
    }
    console.log("getOwnVehiclesInfo: ", args);
    const self = this;
    API_HANDLER.getOwnVehiclesInfo(args).then((res) => {
      console.log("getOwnVehiclesInfo: res:", res);
      if (res.success) {
        const { records, totalRows } = res.response;
        self.setState({
          rows: records,
          totalRows,
        });
      }
    });
  };

  reloadData = () => {
    // reloadData
  };

  onSearch = (e) => {
    clearTimeout(this._timer);
    const self = this;
    this._timer = setTimeout(() => {
      self.reloadData();
    }, 500);
  };

  setCurrentPage = (pageNo = 0) => {
    const self = this;
    this.setState(
      {
        pageNo: pageNo,
      },
      () => {
        self.loadRecords();
      }
    );
  };

  onGridSorting = (data) => {
    const self = this;
    this.setState(
      {
        defaultSorting: data,
      },
      () => {
        self.reloadData();
      }
    );
  };

  getIndentBtn = () => {
    return (
      <div className="options">
        <Tooltip title="Add" placement="bottom">
          <IconButton className="dataGridIcon">
            <AddIcon />
          </IconButton>
        </Tooltip>
      </div>
    );
  };

  getDieselBtn = () => {
    return (
      <div className="options">
        <Tooltip title="Add" placement="bottom">
          <IconButton className="dataGridIcon">
            <AddIcon />
          </IconButton>
        </Tooltip>
      </div>
    );
  };

  getLrBtn = () => {
    return (
      <div className="options">
        <Tooltip title="Add" placement="bottom">
          <IconButton className="dataGridIcon">
            <AddIcon />
          </IconButton>
        </Tooltip>
      </div>
    );
  };

  getArrivalBtn = () => {
    return (
      <div className="options">
        <Tooltip title="Add" placement="bottom">
          <IconButton className="dataGridIcon">
            <AddIcon />
          </IconButton>
        </Tooltip>
      </div>
    );
  };

  getAllocateBtn = () => {
    return (
      <div className="options">
        <Tooltip title="Add" placement="bottom">
          <IconButton className="dataGridIcon">
            <AddIcon />
          </IconButton>
        </Tooltip>
      </div>
    );
  };

  render() {
    const { loading, rows, columns, pageNo, totalRows, defaultSorting } =
      this.state;
    let gridHeight = window.innerHeight * 0.5;
    let finalRows = rows;
    for (let i = 0; i < rows.length; i++) {
      finalRows[i] = rows[i];
      finalRows[i].slno = pageNo * CONFIG.PAGE_LENGTH + (i + 1);
      finalRows[i].indent = this.getIndentBtn(rows[i]);
      finalRows[i].diesel = this.getDieselBtn(rows[i]);
      finalRows[i].lr = this.getLrBtn(rows[i]);
      finalRows[i].arrival = this.getArrivalBtn(rows[i]);
      finalRows[i].allocate = this.getAllocateBtn(rows[i]);
    }
    return loading ? (
      <div
        style={{
          height: 400,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress className={"primary"} size="1.5rem" />
      </div>
    ) : (
      <div className="dataRGrid" id="ownVehiclesWrapper">
        <h4 className="frmGroupTitle">Places</h4>
        <div style={{ display: "flex", marginBottom: 10, marginTop: 10 }}>
          <div style={{ width: "30%" }}>
            <Txt
              id="ownVehiclesSearch"
              placeHolder={LABELS.SEARCH_BY_VEHICLE_NO}
              onKeyPress={this.onSearch}
            />
          </div>
          <div style={{ width: "70%" }}>
            <Tooltip title={LABELS.RELOAD} placement="bottom">
              <IconButton className="iconBtn" onClick={this.reloadData}>
                <ReloadIcon />
              </IconButton>
            </Tooltip>
          </div>
        </div>

        <Grid rows={finalRows} columns={columns}>
          <PagingState
            currentPage={pageNo}
            onCurrentPageChange={this.setCurrentPage}
            pageSize={2}
          />
          <CustomPaging totalCount={totalRows} />

          <SortingState
            defaultSorting={defaultSorting}
            columnSortingEnabled={true}
            columnExtensions={this._sortingDisabledColumns}
            onSortingChange={this.onGridSorting}
          />
          <IntegratedSorting />

          <VirtualTable
            columnExtensions={this._tableColumnExtensions || []}
            height={gridHeight}
          />

          <TableColumnResizing
            defaultColumnWidths={this._tableColumnWidths || []}
          />

          <TableHeaderRow showSortingControls={true} />

          <PagingPanel />
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    // ownVehiclesReducer: state.ownVehiclesReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(OwnVehicles);
