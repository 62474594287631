import { Divider } from "@material-ui/core";
import React, { Component } from "react";

import TrackBox from "../MapForm/TrackBox";
import UiDataGrid from "./UiDataGrid";

class PlacesGrid extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { childRef } = this.props;
    childRef(this);
  }

  componentWillUnmount() {
    const { childRef } = this.props;
    childRef(undefined);
  }

  render() {
    const { focusTrack, updateRegion } = this.props;
    return (
      <div>
        <TrackBox focusTrack={focusTrack} />
        <Divider />
        <UiDataGrid updateRegion={updateRegion} />
      </div>
    );
  }
}

export default PlacesGrid;
