import React, { Component } from "react";
import { withSnackbar } from "notistack";

import AutoComplete from "../../ui/autocomplete/AutoComplete";
import Btn from "../../ui/button/Btn";

import { ERRORS, LABELS, NOTIFY, SUCCESS } from "../../../common/consts";
import { API_HANDLER } from "../../../apihandlers";
import { commonJs } from "../../../common/scripts";
import { FormControlLabel, Switch } from "@material-ui/core";
import Txt from "../../ui/textbox/Txt";

class EmpDialog extends Component {
  constructor(props) {
    super(props);
    const { employee, officeZones, managers } = this.props;
    let selectedZone = {};
    let selectedEmployee = {};
    let selectedManager = {};
    let isManager = false;
    if (employee.empId) {
      selectedEmployee = { id: employee.empId, name: employee.empName };
    } else {
      selectedEmployee = {};
    }
    if (employee.zone) {
      const sZone = officeZones.filter((z) => {
        return z.id === employee.zone;
      });
      if (sZone.length > 0) {
        selectedZone = sZone[0];
      }
    }
    if (employee.manager) {
      const sManager = managers.filter((z) => {
        return z.id === employee.manager;
      });
      if (sManager.length > 0) {
        selectedManager = sManager[0];
      }
    }
    if (employee.manager) {
      isManager = Number(employee.manager) === 0 ? true : false;
    }
    this.state = {
      selectedEmployee,
      selectedZone,
      selectedManager,
      geofenceOn: employee.geofenceOn === "Yes" ? true : false,
      isManager,
    };
  }

  onChangeEmployee = (selectedEmployee = {}) => {
    this.setState({
      selectedEmployee,
    });
  };

  onChangeZone = (selectedZone = {}) => {
    this.setState({
      selectedZone,
    });
  };

  saveRecord = () => {
    const {
      selectedEmployee,
      selectedZone,
      geofenceOn,
      isManager,
      selectedManager,
    } = this.state;
    const deviceId = document.getElementById("deviceId").value.trim();
    const args = {
      empId: selectedEmployee.id || null,
      zoneId: selectedZone.id || "",
      geofenceOn: geofenceOn ? "T" : "F",
      managerId: selectedManager.id || -1,
      deviceId,
    };
    if (isManager) {
      args.managerId = 0;
    }
    if (!selectedEmployee.id) {
      commonJs.showNotify(
        this.props,
        NOTIFY.WARNING,
        "Please select employee..!"
      );
      return;
    }
    if (!selectedZone.id && geofenceOn) {
      commonJs.showNotify(
        this.props,
        NOTIFY.WARNING,
        "Please select " + LABELS.OFFICE_LOCATION + "..!"
      );
      return;
    }
    const self = this;
    API_HANDLER.editEmployee(args).then((res) => {
      if (res.success) {
        commonJs.showNotify(
          self.props,
          NOTIFY.SUCCESS,
          SUCCESS.RECORD_SAVED_SUCCESSFUL
        );
        self.props.reloadGrid();
        self.props.onCloseDialog();
      } else {
        commonJs.showNotify(self.props, NOTIFY.ERROR, ERRORS.RECORD_NOT_SAVED);
      }
    });
  };

  onChangeGeofence = (geofenceOn) => {
    this.setState({
      geofenceOn,
    });
  };

  onChangeIsManager = (isManager) => {
    const { selectedManager } = this.state;
    const sManager = isManager ? {} : selectedManager;
    this.setState({
      isManager,
      selectedManager: sManager,
    });
  };

  onChangeManager = (selectedManager) => {
    this.setState({
      selectedManager,
    });
  };

  render() {
    const {
      selectedEmployee,
      selectedZone,
      selectedManager,
      geofenceOn,
      isManager,
    } = this.state;
    const { officeZones, employee, managers } = this.props;
    const list = officeZones.map((o) => {
      return { id: Number(o.id), name: o.name };
    });
    console.log("officeZones: ", employee);
    return (
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div className="formGroup" style={{ width: "100%" }}>
          <div className="mB5">
            <label className="formLabel">
              {LABELS.EMPLOYEE + " :"}
              <span className="red">{" *"}</span>
            </label>
          </div>
          <AutoComplete
            id="empList"
            style={{ width: "100%" }}
            list={[selectedEmployee]}
            defaultValue={selectedEmployee}
            multiple={false}
            size="small"
            disabled={true}
            onChange={this.onChangeEmployee}
          />
        </div>
        <div
          className="formGroup"
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            marginTop: 10,
          }}
        >
          <div>
            <label className="formLabel" style={{ marginRight: 15 }}>
              {"Geofence Compulsory"}
            </label>
          </div>
          <FormControlLabel
            control={
              <Switch
                classes={{
                  thumb: "switchThumb",
                  track: "switchTrack",
                  root: "switchRoot",
                }}
                defaultChecked={geofenceOn}
                onChange={() => this.onChangeGeofence(!geofenceOn)}
              />
            }
            label=""
            className="switch"
          />
        </div>
        <div className="formGroup" style={{ width: "100%" }}>
          <div className="mB5">
            <label className="formLabel">
              {LABELS.OFFICE_LOCATION + " :"}
              <span className="red">{" *"}</span>
            </label>
          </div>
          <AutoComplete
            id="empZones"
            style={{ width: "100%" }}
            list={list}
            defaultValue={selectedZone}
            multiple={false}
            size="small"
            onChange={this.onChangeZone}
          />
        </div>
        <div
          className="formGroup"
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            marginTop: 5,
          }}
        >
          <div>
            <label className="formLabel" style={{ marginRight: 15 }}>
              {"Is Manager"}
            </label>
          </div>
          <FormControlLabel
            control={
              <Switch
                classes={{
                  thumb: "switchThumb",
                  track: "switchTrack",
                  root: "switchRoot",
                }}
                defaultChecked={isManager}
                onChange={() => this.onChangeIsManager(!isManager)}
              />
            }
            label=""
            className="switch"
          />
        </div>
        <div className="formGroup" style={{ width: "100%" }}>
          <div className="mB5">
            <label className="formLabel">
              {LABELS.REPORTING_MANAGER + " :"}
              <span className="red">{" *"}</span>
            </label>
          </div>
          <AutoComplete
            id="empManager"
            style={{ width: "100%" }}
            list={managers}
            defaultValue={selectedManager}
            multiple={false}
            size="small"
            disabled={isManager}
            onChange={this.onChangeManager}
          />
        </div>
        <div className="formGroup" style={{ width: "100%" }}>
          <div className="mB5">
            <label className="formLabel">{LABELS.DEVICE_ID + " :"}</label>
          </div>
          <Txt id="deviceId" placeHolder={LABELS.DEVICE_ID} />
        </div>
        <div
          className="formGroup"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            width: "100%",
            marginTop: 30,
          }}
        >
          <div>
            <Btn className={"primaryBtn saveBtn"} onClick={this.saveRecord}>
              {LABELS.SAVE}
            </Btn>
          </div>
        </div>
      </div>
    );
  }
}

export default withSnackbar(EmpDialog);
