// Constants

export const DEVELOPMENT = false;

export const CONFIG = {
  SITE_NAME: "Unity Web",
  VERSION: "1.4.1",
  WEBSITE_URL: "https://unityfms.unitylogistics.co.in",
  BASE_URL: DEVELOPMENT
    ? "http://localhost:8000"
    : "https://api.unitylogistics.co.in",
  GOOGLE_MAP_REDIRECTION_LINK: "https://www.google.com/maps?q=",
  GOOGLE_KEY: "AIzaSyB6ePenbKPa9gakOoSWVks-_cqiNi-6AwY",
  MAP_BOX_TILE_URL_STREETS:
    "https://api.tiles.mapbox.com/v4/mapbox.streets/{z}/{x}/{y}.png?access_token=pk.eyJ1Ijoibml0aGluZG4iLCJhIjoiY2prYXBpaWgyMHB3NzNxcGQ4ZDQzaTEydCJ9.nL5PzNydbdVmrPUIPnGoCA",
  MAP_BOX_TILE_URL_SATELLITE:
    "https://api.tiles.mapbox.com/v4/mapbox.satellite/{z}/{x}/{y}.png?access_token=pk.eyJ1Ijoibml0aGluZG4iLCJhIjoiY2prYXBpaWgyMHB3NzNxcGQ4ZDQzaTEydCJ9.nL5PzNydbdVmrPUIPnGoCA",
  OSM_TILE_URL: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
  GOOGLE_STREETS_MAP_URL:
    "https://mt0.google.com/vt/lyrs=m&hl=en&x={x}&y={y}&z={z}&s=Ga",
  GOOGLE_STREETS_MAP_WITH_TRAFFIC_URL:
    "https://mt0.google.com/vt/lyrs=m,traffic&hl=en&x={x}&y={y}&z={z}&s=Ga",
  GOOGLE_SATELLITE_MAP_URL:
    "https://mt0.google.com/vt/lyrs=s&hl=en&x={x}&y={y}&z={z}&s=Ga",
  GOOGLE_SATELLITE_MAP_WITH_TRAFFIC_URL:
    "https://mt0.google.com/vt/lyrs=s,traffic&hl=en&x={x}&y={y}&z={z}&s=Ga",
  GOOGLE_TERRAIN_MAP_URL:
    "https://mt0.google.com/vt/lyrs=t&hl=en&x={x}&y={y}&z={z}&s=Ga",
  GOOGLE_TERRAIN_MAP_WITH_TRAFFIC_URL:
    "https://mt0.google.com/vt/lyrs=t,traffic&hl=en&x={x}&y={y}&z={z}&s=Ga",
  GOOGLE_HYBRID_MAP_URL:
    "https://mt0.google.com/vt/lyrs=y&hl=en&x={x}&y={y}&z={z}&s=Ga",
  GOOGLE_HYBRID_MAP_WITH_TRAFFIC_URL:
    "https://mt0.google.com/vt/lyrs=y,traffic&hl=en&x={x}&y={y}&z={z}&s=Ga",
  // https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}
  USER_ROLES: {
    SUPER_ADMIN: 0,
    ADMIN: 1,
    OFFICE_USER: 2,
    SUPERVISOR: 3,
    DRIVER: 4,
    CUSTOMER: 5,
    CONSIGNEE: 6,
    HR: 7,
    DIESEL: 8,
    VEHICLEFLEET: 9,
    INDENTLR: 10,
    INDENTLRDIESEL: 11,
    INFLLR: 16,
    PODHO: 12,
    FIELDSUP: 13,
    INLRHO: 14,
    INLRDIHO: 15,
    FLEETHEAD: 17,
  },
  PAGE_LENGTH: 15,
  API_TIMEOUT: 30 * 1000,
  API_METHODS: {
    GET: "GET",
    POST: "POST",
    PUT: "PUT",
    DELETE: "DELETE",
  },
  PAGES: {
    HOME: "/",
    LOGIN: "/login",
    PRIVACY_POLICY: "/privacy-policy",
    DASHBOARD: "/dashboard",
    HR_DASHBOARD: "/hr-dashboard",
    OWN_VEHICLES: "/own-vehicles",
    MARKET_VEHICLES: "/mkt-vehicles",
    PLACES: "/places",
    USERS: "/users",
    WELCOME: "/welcome",
  },
  FLEET_API_URL: "https://fleetapi.vaschan.com",
  FLEET_API_KEY: "dd2374e04b984ec8b5287ef399e61c59", // unitylogistics
  PRIVILEGES: {
    DASHBOARD: "dashboard",
    PLACE_MASTER: "placemaster",
    USERS: "users",
    HR_DASHBOARD: "hr_dashboard",
  },
  ATTENDANCE_STATUS_LIST: [
    {
      text: "Fullday Present",
      value: "Fullday Present",
    },
    {
      text: "Halfday Present",
      value: "Halfday Present",
    },
    {
      text: "Fullday Absent",
      value: "Fullday Absent",
    },
  ],
  LEAVE_REQUEST_STATUS: {
    LEAVE_APPLIED: "Leave Applied",
    LEAVE_APPROVED: "Leave Approved",
    LEAVE_CANCELLED: "Leave Cancelled",
  },
};

export const NOTIFY = {
  WARNING: "warning",
  SUCCESS: "success",
  ERROR: "error",
  AUTO_HIDE_DURATION: 3000,
};

export const FORM_TITLES = {
  LOG_IN: "USER LOGIN",
};

export const LABELS = {
  APPROVE: "Approve",
  CANCEL: "Cancel",
  LEAVE_REQUESTS: "Leave Requests",
  LOAD: "Load",
  SEARCH_BY_VEHICLE_NO: "Search By Vehicle No.",
  DISMISS: "Dismiss",
  HOME: "Home",
  MENU: "Menu",
  USERNAME: "Username",
  PASSWORD: "Password",
  REMEMBER_ME: "Remember me",
  LOGIN: "Login",
  LOGOUT: "Logout",
  LOCATION: "Location",
  CITY: "City",
  STATE: "State",
  PINCODE: "Pincode",
  CUSTOMER_NAME: "Customer Name",
  CONSIGNEE_NAME: "Consignee Name",
  SERVICE_TYPE: "Service Type",
  CONSIGNEE_OR_CONSIGNER: "Consignee/Consigner",
  ADDRESS: "Address",
  LATITUDE: "Latitude",
  LONGITUDE: "Longitude",
  MAP_POSITION: "Map Position",
  PLACE_NAME: "Place Name",
  PLACE_CODE: "Place Code",
  CUSTOMER_PLACE: "Customer Place",
  SAVE: "Save",
  OK: "OK",
  CANCEL: "Cancel",
  RELOAD: "Reload",
  FULL_SCREEN: "Full Screen",
  EXIT_FULL_SCREEN: "Exit Full Screen",
  SEARCH: "Search",
  ADD_NEW: "Add New",
  PLACES: "Places",
  DASHBOARD: "Dashboard",
  USERS: "Users",
  OWN_VEHICLES: "Own Vehicles",
  MARKET_VEHICLES: "Market Vehicles",
  AVAILABLE_FOR_LOADING: "Available for loading",
  LOAD_ASSIGNED_VEHICLES: "Load assigned vehicles",
  WAITING_FOR_UNLOAD: "Waiting for unload",
  IN_TRANSIT: "In Transit",
  RUNNING_DELAY: "Running Delay",
  ACCIDENT_OR_REPAIR: "Accident or Repair",
  VEHICLES_WITHOUT_DRIVER: "Vehicles without driver",
  DRIVERS_WITHOUT_VEHICLE: "Drivers without vehicle",
  READY_FOR_LOADING: "Ready for loading",
  WAITING_FOR_VEHICLES_DEPARTURE: "Waiting for vehicles departure",
  ALL_VEHICLES: "All Vehicles",
  OFFICE_LOCATION: "Office Location",
  EMPLOYEE: "Employee",
  REPORTING_MANAGER: "Reporting Manager",
  DEVICE_ID: "Device ID",
  MANAGE_ATTENDANCE: "Manage Attendance",
  ADD_ATTENDANCE: "Add Attendance",
  EDIT_ATTENDANCE: "Edit Attendance",
  ATTENDANCE_DATE: "Attendance Date",
  ATTENDANCE_STATUS: "Attendance Status",
  DATE_RANGE: "Date Range",
  EMP_ATTN_DETAILS: "Employee Attendance Details",
  IN_TIME: "In Time",
  OUT_TIME: "Out Time",
  IN_LAT_LNG: "In Lat,Lng",
  OUT_LAT_LNG: "Out Lat,Lng",
  IN_LOCATION: "In Location",
  OUT_LOCATION: "Out Location",
  IN_REASON: "In Reason",
  OUT_REASON: "Out Reason",
};

// export const COLORS = {};

export const BUTTON = {
  SEARCH: "Search",
  SAVE: "Save",
};

export const COLORS = {
  PRIMARY: "#037FF2", // Header
  PRIMARY_DIM: "#2883c51f",
  SECONDARY: "#1B2973", // Button
  WHITE: "#fff",
  BLACK: "#000",
  BLUE: "#037FF2",
  GREEN: "#2ab33d",
  RED: "#FF3F32",
  ORANGE: "#E08808",
  DARK_BLUE: "#1B60B5",
  LIGHT_GREY: "lightgrey",
  TOGGLE_THUMBCOLOR: "#3C5AFB",
  TOGGLE_TRACKCOLOR: "#9dacfd",
  ICON_COLOR: "#272525",
  DARK_GRAY: "#272525",
  DIM_GRAY: "#0000004D",
  SMOKY_GRAY: "#ebebeb",
  VEHICLES_ICON: "#8b8b2b",
  TRIP_ICON: "#72b2c7",
  CANCEL_ICON: "#5ea8bf",
  CONFIRMED_ICON: "#2fa88e",
};

export const DEFAULTS = {
  DEFAULT_LAT: 19.787380181986222,
  DEFAULT_LNG: 73.19091796875001,
  DEFAULT_ZOOM: 5,
  DEFAULT_MAP: 1,
  MIN_ZOOM: 4,
  MAX_ZOOM: 18,
  PLACE_ZOOM: 14,
  PAGE_LENGTH: 100000,
  SORTING_ORDER: "asc",
};

export const WARNINGS = {
  USER_NAME_OR_PASSWORD_MISSING:
    "Username & Password are mandatory to continue..!",
  PLEASE_ENTER_MANDATORY_FIELDS: "Please fill all the mandatory fields..!",
  PARTY_PLACE_CODE_NOT_FOUND: "Party place code not found..!",
};

export const SUCCESS = {
  LOGIN_SUCCESSFUL: "Login successful.!",
  RECORD_SAVED_SUCCESSFUL: "Record saved successfully.!",
  RECORD_UPDATED_SUCCESSFUL: "Record updated successfully.!",
};

export const ERRORS = {
  LOGIN_UNSUCCESSFUL: "Login failed: ",
  INTERNAL_ERROR: "Internal Server Error..!",
  RECORD_NOT_SAVED: "Record not saved successfully..!",
  NO_NETWROK: "Error: Please check your Internet Connection..!",
};

export const LOCAL_STORAGE = {
  USER_INFO: "user",
  USER_NAME: "uname",
  PASSWORD: "pwd",
  ACCESS_TOKEN: "accessToken",
  USER_ID: "userId",
  USER_ROLE: "userRole",
  MAP_SETTINGS: "mapSettings",
};

export const FORMATS = {
  SQL_DATE: "YYYY-MM-DD HH:mm:ss",
  SQL_DATE_ONLY: "YYYY-MM-DD",
  DATE: "DD-MM-YYYY HH:mm:ss",
  DATE_ONLY: "DD-MM-YYYY",
  DATE_PICKER_FORMAT: "dd-MM-yyyy",
  REPORT_TITLE_DATE: "MMM DD, YYYY",
  REPORT_DATE_WITH_TIME: "MMM DD, YYYY HH:mm:ss",
  MONTH_ONLY: "MMMM",
  MONTH_AND_YEAR_ONLY: "MMMM - YYYY",
  GRAPH_TIME: "HH:mm",
};
