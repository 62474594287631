import axios from "axios";
import jQuery from "jquery";

import { CONFIG, ERRORS, LABELS, LOCAL_STORAGE, NOTIFY } from "./consts";

var $ = jQuery;

export const commonJs = {
  hasPrivilege: (privilege, userRole) => {
    let result = false;
    switch (privilege) {
      case CONFIG.PRIVILEGES.USERS:
        if (userRole === CONFIG.USER_ROLES.ADMIN) {
          result = true;
        }
        break;
      case CONFIG.PRIVILEGES.DASHBOARD:
        if (
          userRole === CONFIG.USER_ROLES.ADMIN ||
          userRole === CONFIG.USER_ROLES.OFFICE_USER
        ) {
          result = true;
        }
        break;
      case CONFIG.PRIVILEGES.HR_DASHBOARD:
        if (
          userRole === CONFIG.USER_ROLES.ADMIN ||
          userRole === CONFIG.USER_ROLES.HR
        ) {
          result = true;
        }
        break;
      case CONFIG.PRIVILEGES.PLACE_MASTER:
        if (
          userRole === CONFIG.USER_ROLES.ADMIN ||
          userRole === CONFIG.USER_ROLES.SUPERVISOR ||
          userRole === CONFIG.USER_ROLES.OFFICE_USER
        ) {
          result = true;
        }
        break;
      default:
        break;
    }
    return result;
  },

  callApi: async (path = "/", method = CONFIG.API_METHODS.GET, args = {}) => {
    try {
      const bearerToken = localStorage[LOCAL_STORAGE.ACCESS_TOKEN] || "";
      const config = {
        timeout: CONFIG.API_TIMEOUT,
        headers: {
          Authorization: "Bearer " + bearerToken,
        },
      };
      let res = {};
      switch (method) {
        case CONFIG.API_METHODS.POST:
          res = await axios.post(CONFIG.BASE_URL + path, args, config);
          break;
        case CONFIG.API_METHODS.PUT:
          break;
        case CONFIG.API_METHODS.DELETE:
          break;
        default:
          // GET
          config.params = args;
          res = await axios.get(CONFIG.BASE_URL + path, config);
          break;
      }
      console.log("API: ", path, ": ", res);
      // 403 => Redirect to login
      if (res.status === 200) {
        return res.data;
      } else if (res.status === 403) {
        // Authentication Failed
        window.location = "/login";
        return {
          success: false,
          response: {},
          reason: "Authentication Failed: Please try login to continue..!",
        };
      }
      // Failed due to some other reason
      return {
        success: false,
        response: {},
        reason: ERRORS.INTERNAL_ERROR,
      };
    } catch (err) {
      console.log("err: ", err);
      if (err.response) {
        if (err.response.status === 403) {
          // Authentication Failed
          if (
            window.location.pathname !== "/login" &&
            window.location.pathname !== "/privacy-policy"
          ) {
            window.location = "/login";
          }
        }
      }
      return {
        success: false,
        response: {},
        reason: ERRORS.INTERNAL_ERROR,
      };
    }
  },

  getCityNameFromAddress: (addressArr = []) => {
    let cityName = "";
    let subLocality = addressArr.filter((a) => {
      return a.types.indexOf("sublocality") > -1;
    });
    console.log("addrs:subLocality:", addressArr, subLocality);
    if (subLocality.length > 0) {
      let level1 = subLocality.filter((s) => {
        return s.types.indexOf("sublocality_level_1") > -1;
      });
      console.log("addrs:subLocality:level1", addressArr, subLocality, level1);
      if (level1[0]) {
        cityName = level1[0].short_name;
      }
    } else {
      let locality = addressArr.filter((a) => {
        return a.types.indexOf("locality") > -1;
      });
      console.log("addrs:locality:", addressArr, locality);
      if (locality[0]) {
        cityName = locality[0].short_name;
      }
    }
    return cityName;
  },

  getAddressFromArray: (arr = [], str = "") => {
    let plusCode = arr.filter((a) => {
      return a.types.indexOf("plus_code") > -1;
    });
    if (plusCode[0]) {
      plusCode = plusCode[0]["short_name"];
    } else {
      plusCode = "";
    }
    return str.replace(plusCode, "");
  },

  openFullScreen: () => {
    var elem = document.getElementById("root");
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.mozRequestFullScreen) {
      /* Firefox */
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) {
      /* Chrome, Safari & Opera */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
      /* IE/Edge */
      elem.msRequestFullscreen();
    }
  },

  exitFullScreen: () => {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    }
  },

  showNotify: (props, variant, text, action, autoHideDuration) => {
    props.enqueueSnackbar(text, {
      variant: variant,
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "right",
      },
      action: <button className="snackbar-btn">{LABELS.DISMISS}</button>,
      autoHideDuration: autoHideDuration || NOTIFY.AUTO_HIDE_DURATION,
    });
  },

  getExportPrintCss: (win) => {
    $(win.document.body).find("h1").remove();
    $(win.document.body).find("table").attr("cellpadding", "3");
    $(win.document.body).find("table").css("font-size", "10px");
    $(win.document.body)
      .find("table")
      .css(
        "cssText",
        "border-bottom: none;border-left: none;border-right: none;"
      );
    $(win.document.body).find("td").css("font-size", "8px");
    $(win.document.body).find("th").css("font-size", "8px");
    $(win.document.body)
      .find("tbody td")
      .css("cssText", "border: 1px solid #000;border-top: 0;border-right: 0;");
    $(win.document.body)
      .find("tbody td:last-child")
      .css("cssText", "border: 1px solid #000;border-top: 0;");
    $(win.document.body)
      .find("table th")
      .css("cssText", "border: 1px solid #000;border-right: 0;");
    $(win.document.body)
      .find("table th:last-child")
      .css("cssText", "border: 1px solid #000;");
  },
};
