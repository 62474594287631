import React, { Component } from "react";
import {
  Map,
  ZoomControl,
  ScaleControl,
  TileLayer,
  FeatureGroup,
  Tooltip,
  Marker,
} from "react-leaflet";
import { connect } from "react-redux";
import L from "leaflet";
import axios from "axios";

import MapPopup from "./MapPopup";
import Trackline from "./Trackline";

import { CONFIG, DEFAULTS, LOCAL_STORAGE } from "../../common/consts";
import { MAP_ACTIONS } from "../../actions/MapActions";
import { API_HANDLER } from "../../apihandlers";
import { MAP_FORM_ACTIONS } from "../../actions/MapFormActions";
import { PLACE_ACTIONS } from "../../actions/PlaceActions";
import { commonJs } from "../../common/scripts";

import "./styles/map.css";
import "../../../node_modules/react-leaflet-markercluster/dist/styles.min.css";
import "../../../node_modules/leaflet/dist/leaflet.css";
import placeMarkerImg from "../../images/place-marker.png";

class UnityMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mapPosition: {
        lat: this.props.mapReducer.initialLat,
        lng: this.props.mapReducer.initialLng,
      },
      zoom: this.props.mapReducer.zoom,
    };
  }

  componentDidMount() {
    const { childRef } = this.props;
    childRef(this);
  }

  componentWillUnmount() {
    const { childRef } = this.props;
    childRef(undefined);
  }

  getTileUrl = (mapType) => {
    let url = CONFIG.OSM_TILE_URL;
    const { mapTraffic } = this.props.mapReducer;
    switch (mapType) {
      case 1:
        url = mapTraffic
          ? CONFIG.GOOGLE_STREETS_MAP_WITH_TRAFFIC_URL
          : CONFIG.GOOGLE_STREETS_MAP_URL;
        break;
      case 2:
        url = mapTraffic
          ? CONFIG.GOOGLE_SATELLITE_MAP_WITH_TRAFFIC_URL
          : CONFIG.GOOGLE_SATELLITE_MAP_URL;
        break;
      case 3:
        url = mapTraffic
          ? CONFIG.GOOGLE_HYBRID_MAP_WITH_TRAFFIC_URL
          : CONFIG.GOOGLE_HYBRID_MAP_URL;
        break;
      case 4:
        url = CONFIG.MAP_BOX_TILE_URL_STREETS;
        break;
      case 5:
        url = CONFIG.MAP_BOX_TILE_URL_SATELLITE;
        break;
      default:
        break;
    }
    return url;
  };

  getMapMarker = (type = "") => {
    let iconUrl = placeMarkerImg;
    let customMarker = null;
    let width = 20;

    const html =
      "<div class='movingMarker' style='transform: rotate(" +
      "0" +
      "deg);'><img src='" +
      iconUrl +
      "' alt=''  width='" +
      width +
      "px'/></div>";

    customMarker = L.divIcon({
      html: html,
      iconSize: new L.Point(16, 16),
      iconAnchor: null,
      popupAnchor: [0, -3],
      className: "mapMarker",
    });

    return customMarker;
  };

  mapClick = (e) => {
    // const { tmpMarker } = this.props.mapReducer;
    // if (tmpMarker.lat) {
    //   return;
    // }
    const { lat, lng } = e.latlng;
    if (document.getElementById("latitude")) {
      document.getElementById("latitude").value = lat;
      document.getElementById("longitude").value = lng;
      this.getAddress(lat, lng);
    }
  };

  markerClick = (marker, indx) => {
    const { mapPoints } = this.props.mapReducer;
    console.log("markerClick: ", mapPoints[indx]);
  };

  onMarkerDrag = (e) => {
    const { lat, lng } = e.target._latlng;
    if (document.getElementById("latitude")) {
      document.getElementById("latitude").value = lat;
      document.getElementById("longitude").value = lng;
    }
    this.getAddress(lat, lng);
  };

  getAddress = (lat, lng) => {
    const self = this;
    this.props.setTmpMarker({
      tmpMarker: {
        lat,
        lng,
      },
    });
    // get address => update address json & text
    axios
      .get(
        "https://maps.googleapis.com/maps/api/geocode/json?latlng=" +
          lat +
          "," +
          lng +
          "&key=" +
          CONFIG.GOOGLE_KEY
      )
      .then((res) => {
        const { results } = res.data;
        self.setLatLng({ lat, lng });
        if (results[0]) {
          self.setCityState(results[0].address_components);
        }
        if (document.getElementById("address")) {
          if (results[0]) {
            document.getElementById("address").value =
              results[0].formatted_address;
          }
        }
        self.props.setAddress({
          addressJson: results,
        });
      });
  };

  setLatLng = (addressObj = {}) => {
    const { lat, lng } = addressObj;
    if (document.getElementById("latitude")) {
      document.getElementById("latitude").value = lat;
      document.getElementById("longitude").value = lng;
    }
    this.props.setTmpMarker({ tmpMarker: { lat, lng } });
  };

  setCityState = (addressList = []) => {
    console.log("setCityState: ", addressList);
    let city = "";
    let state = "";
    let pincode = "";
    for (let i = 0; i < addressList.length; i++) {
      city = commonJs.getCityNameFromAddress(addressList);
      if (addressList[i].types.indexOf("administrative_area_level_1") > -1) {
        state = addressList[i].long_name;
      }
      if (addressList[i].types.indexOf("postal_code") > -1) {
        pincode = addressList[i].short_name;
      }
    }
    if (city === "") {
      city = state;
    }
    document.getElementById("gSearchByPincode").value = pincode;
    document.getElementById("customerPlace").value = city;

    const { stateList } = this.props.mapFormReducer;
    // get state id
    let selectedState = stateList.filter(
      (s) => s.name.toUpperCase().trim() === state.toUpperCase().trim()
    );
    if (selectedState.length > 0) {
      selectedState = selectedState[0];
      const self = this;
      API_HANDLER.getCitiesList({
        stateId: selectedState.id,
        pageNo: 1,
        pageLength: DEFAULTS.PAGE_LENGTH,
        searchQry: "",
        sortingOrder: DEFAULTS.SORTING_ORDER,
      }).then((res) => {
        if (res.success) {
          const { records } = res.response;
          const cityList = records;
          // get city id
          let selectedCity = cityList.filter(
            (c) => c.name.toUpperCase().trim() === city.toUpperCase().trim()
          );
          self.props.setCities({
            cityList: records,
          });
          self.props.setCityState({
            selectedCity,
            selectedState,
          });
        }
      });
    }
  };

  updateRegion = (lat, lng, zoom) => {
    this.setState({
      mapPosition: { lat, lng },
      zoom: zoom,
    });
  };

  trackFitBounds = () => {
    this._map.leafletElement.fitBounds(
      this._mapTracklineGroup.leafletElement.getBounds()
    );
  };

  onDragMap = (e) => {
    const center = e.target.getCenter();
    const zoom = e.target.getZoom();
    const obj = {
      lat: center.lat,
      lng: center.lng,
      zoom,
    };
    localStorage[LOCAL_STORAGE.MAP_SETTINGS] = JSON.stringify(obj);
  };

  render() {
    const { mapPosition, zoom } = this.state;
    const { mapType, scale, mapPoints, tmpMarker } = this.props.mapReducer;
    const { placeMaster, placeInfo } = this.props.placeReducer;
    const tileUrl = this.getTileUrl(mapType);
    const mPos = {
      lat: Number(mapPosition.lat),
      lng: Number(mapPosition.lng),
    };
    console.log("placeMaster: ", placeMaster, mapPoints);
    return (
      <Map
        center={mPos}
        zoom={zoom}
        maxZoom={DEFAULTS.MAX_ZOOM}
        minZoom={DEFAULTS.MIN_ZOOM}
        viewport={null}
        onClick={this.mapClick}
        className="fleetMap"
        onMoveend={this.onDragMap}
        onzoomend={this.onZoomEnd}
        ref={(ref) => {
          this._map = ref;
        }}
      >
        <ZoomControl position="bottomright" className="mapZoomControl" />

        {scale && (
          <ScaleControl position="topright" className="mapScaleControl" />
        )}

        <TileLayer url={tileUrl} />

        {/* <FeatureGroup
          ref={(ref) => {
            this._mapGroup = ref;
          }}
        >
          <MarkerClusterGroup
            iconCreateFunction={createClusterCustomIcon}
            showCoverageOnHover={true}
          >
            {placeMaster.map((marker, indx) => {
              return (
                <Marker
                  position={[marker.location.lat, marker.location.lng]}
                  icon={this.getMapMarker(marker)}
                  key={"mapMarker-" + indx}
                  ref={indx}
                  onclick={() => this.markerClick(marker, indx)}
                  className="mapMarker"
                >
                  <MapPopup data={marker} />
                  <Tooltip
                    direction="right"
                    offset={[10, -2]}
                    permanent
                    className={"makerLabel"}
                  >
                    <span>{marker.placeName}</span>
                  </Tooltip>
                </Marker>
              );
            })}
          </MarkerClusterGroup>
        </FeatureGroup> */}

        {tmpMarker.lat && (
          <Marker
            position={[tmpMarker.lat, tmpMarker.lng]}
            icon={this.getMapMarker()}
            key={"tmpMarker-1"}
            ref={1}
            className="mapMarker"
            draggable={true}
            ondragend={this.onMarkerDrag}
          >
            {placeInfo.placeId && <MapPopup data={placeInfo} />}
            {placeInfo.placeId && (
              <Tooltip
                direction="right"
                offset={[10, -2]}
                permanent
                className={"makerLabel"}
              >
                <span>{placeInfo.placeName}</span>
              </Tooltip>
            )}
          </Marker>
        )}

        <FeatureGroup
          ref={(ref) => {
            this._mapTracklineGroup = ref;
          }}
        >
          <Trackline />
        </FeatureGroup>
      </Map>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    mapReducer: state.mapReducer,
    placeReducer: state.placeReducer,
    mapFormReducer: state.mapFormReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setTmpMarker: (values) => {
      dispatch({
        type: MAP_ACTIONS.SET_TMP_MARKER,
        values,
      });
    },
    setCities: (values) => {
      dispatch({
        type: MAP_FORM_ACTIONS.SET_CITIES,
        values,
      });
    },
    setCityState: (values) => {
      dispatch({
        type: MAP_FORM_ACTIONS.SET_CITY_STATE,
        values,
      });
    },
    setAddress: (values) => {
      dispatch({
        type: PLACE_ACTIONS.SET_ADDRESS,
        values,
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UnityMap);
