import React, { Component } from "react";
import { connect } from "react-redux";
import { IconButton, Tooltip, CircularProgress } from "@material-ui/core";
import {
  CheckBox as ApproveIcon,
  Cancel as CancelIcon,
  Replay as ReloadIcon,
} from "@material-ui/icons";
import {
  SortingState,
  IntegratedSorting,
  PagingState,
  IntegratedPaging,
} from "@devexpress/dx-react-grid";
import {
  Grid,
  VirtualTable,
  TableHeaderRow,
  TableColumnResizing,
  PagingPanel,
} from "@devexpress/dx-react-grid-material-ui";
import { withSnackbar } from "notistack";

import Txt from "../../../ui/textbox/Txt";

import { API_HANDLER } from "../../../../apihandlers";
import {
  CONFIG,
  FORMATS,
  LABELS,
  NOTIFY,
  SUCCESS,
} from "../../../../common/consts";
import { dateFunctions } from "../../../../common/datefunctions";
import { commonJs } from "../../../../common/scripts";
import UiDialog from "../../../ui/dialogbox/UiDialog";

class LeaveRequests extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRecord: {},
      selectedStatus: {},
      columns: [
        {
          name: "slNo",
          title: "Sl No.",
        },
        {
          name: "empName",
          title: "Employee Name",
        },
        {
          name: "empCode",
          title: "Employee Code",
        },
        {
          name: "requestNo",
          title: "Request No.",
        },
        {
          name: "requestDt",
          title: "Request Date",
        },
        {
          name: "status",
          title: "Status",
        },
        {
          name: "options",
          title: "Options",
        },
      ],
      rows: null,
      filteredRows: [],
      defaultSorting: [{ columnName: "slNo", direction: "asc" }],
      fdt: dateFunctions.getDate("thismonth", "from", FORMATS.SQL_DATE),
      tdt: dateFunctions.getDate("thismonth", "to", FORMATS.SQL_DATE),
    };

    this._tableColumnWidths = [
      {
        columnName: "slNo",
        width: 100,
      },
      { columnName: "empName", width: 180 },
      { columnName: "empCode", width: 120 },
      { columnName: "requestNo", width: 200 },
      { columnName: "requestDt", width: 200 },
      { columnName: "status", width: 150 },
      { columnName: "options", width: 100 },
    ];

    this._tableColumnExtensions = [
      {
        columnName: "slNo",
        align: "center",
      },
      { columnName: "empName", align: "left" },
      { columnName: "empCode", align: "left" },
      { columnName: "requestNo", align: "center" },
      { columnName: "requestDt", align: "left" },
      { columnName: "status", align: "left" },
      { columnName: "options", align: "center" },
    ];

    this._sortingDisabledColumns = [
      { columnName: "options", sortingEnabled: false },
    ];

    this._timer = null;
  }

  componentDidMount() {
    this.reloadData();
  }

  reloadData = () => {
    const { fdt, tdt } = this.state;
    const { userInfo } = this.props.loginReducer;
    this.setState({
      rows: null,
      filteredRows: [],
    });
    const args = {
      fdt: dateFunctions.getFormatedDate(
        fdt,
        FORMATS.SQL_DATE,
        FORMATS.SQL_DATE_ONLY
      ),
      tdt: dateFunctions.getFormatedDate(
        tdt,
        FORMATS.SQL_DATE,
        FORMATS.SQL_DATE_ONLY
      ),
      role: userInfo.role,
      empId: userInfo.empId,
    };
    const self = this;
    API_HANDLER.getLeaveRequests(args).then((res) => {
      if (res.success) {
        const rows = res.response.records.map((r, i) => {
          r.slNo = i + 1;
          r.options = self.generateOptions(r);
          return r;
        });
        self.setState({
          rows,
          selectedRecord: {},
        });
      }
    });
  };

  generateOptions = (rec = {}) => {
    return (
      <div className="options">
        <Tooltip title={LABELS.APPROVE} placement="bottom">
          <IconButton
            className="dataGridIcon green"
            style={{ marginRight: 5 }}
            onClick={() =>
              this.onManageRequest(rec, "Leave Approved", "Approve")
            }
          >
            <ApproveIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title={LABELS.CANCEL} placement="bottom">
          <IconButton
            className="dataGridIcon red"
            onClick={() =>
              this.onManageRequest(rec, "Leave Cancelled", "Cancel")
            }
          >
            <CancelIcon />
          </IconButton>
        </Tooltip>
      </div>
    );
  };

  onManageRequest = (rec = {}, status = "", statusText = "") => {
    this.setState({
      selectedRecord: rec,
      selectedStatus: { value: status, text: statusText },
    });
  };

  manageRequest = (rec, status) => {
    const { userName } = this.props.loginReducer;
    const args = {
      requestNo: rec.requestNo,
      status,
      userName,
    };
    this.setState({
      rows: null,
      selectedRecord: {},
    });
    const self = this;
    API_HANDLER.manageLeaveRequest(args).then((res) => {
      if (res.success) {
        commonJs.showNotify(
          self.props,
          NOTIFY.SUCCESS,
          SUCCESS.RECORD_UPDATED_SUCCESSFUL
        );
        self.reloadData();
      } else {
        commonJs.showNotify(self.props, NOTIFY.ERROR, "Error: " + res.reason);
      }
    });
  };

  closeDialog = () => {
    this.setState({
      selectedRecord: {},
    });
  };

  onEmpSearch = (e) => {
    const searchValue = e.target.value.trim().toUpperCase();
    clearTimeout(this._timer);
    const self = this;
    this._timer = setTimeout(() => {
      const { rows } = self.state;
      let filteredRows = rows.filter((r) => {
        return r.empName.toUpperCase().startsWith(searchValue);
      });
      filteredRows = filteredRows.map((f, i) => {
        f.slNo = i + 1;
        return f;
      });
      if (filteredRows.length === 0) {
        filteredRows = null;
      }
      self.setState({
        filteredRows,
      });
    }, 500);
  };

  render() {
    const {
      rows,
      columns,
      defaultSorting,
      filteredRows,
      selectedRecord,
      selectedStatus,
    } = this.state;
    let gridHeight = window.innerHeight * 0.65;
    let finalRows = [];
    if (filteredRows) {
      finalRows = filteredRows.length > 0 ? filteredRows : rows;
    } else {
      finalRows = [];
    }
    return (
      <div className="tabContent" style={{ paddingTop: 0 }}>
        {rows ? (
          <div className="dataRGrid">
            <div
              style={{
                display: "flex",
                marginBottom: 10,
                marginTop: 12,
                alignItems: "center",
              }}
            >
              <div style={{ width: "30%" }}>
                <Txt
                  id="empSearch"
                  placeHolder={"Search By Employee Name"}
                  onKeyPress={this.onEmpSearch}
                />
              </div>
              <div>
                <Tooltip title={"Reload"} placement="bottom">
                  <IconButton className="iconBtn" onClick={this.reloadData}>
                    <ReloadIcon />
                  </IconButton>
                </Tooltip>
              </div>
            </div>
            <Grid rows={finalRows} columns={columns}>
              <PagingState
                onCurrentPageChange={this.setCurrentPage}
                pageSize={15}
              />
              <IntegratedPaging />

              <SortingState
                defaultSorting={defaultSorting}
                columnSortingEnabled={true}
                columnExtensions={this._sortingDisabledColumns}
                onSortingChange={this.onGridSorting}
              />
              <IntegratedSorting />

              <VirtualTable
                columnExtensions={this._tableColumnExtensions || []}
                height={gridHeight}
              />

              <TableColumnResizing
                defaultColumnWidths={this._tableColumnWidths || []}
              />

              <TableHeaderRow showSortingControls={true} />

              <PagingPanel />
            </Grid>
          </div>
        ) : (
          <div
            style={{
              height: 400,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress className={"primary"} size="1.5rem" />
          </div>
        )}
        {selectedRecord.requestNo && (
          <UiDialog
            id={"dashDialog"}
            title={"Manage Leave Request"}
            open={true}
            maxWidth={"sm"}
            draggable={false}
            saveButton={true}
            saveBtnLabel={LABELS.OK}
            cancelButton={true}
            onCloseDialog={this.closeDialog}
            cancelAction={this.closeDialog}
            saveAction={() =>
              this.manageRequest(selectedRecord, selectedStatus.value)
            }
          >
            <p>
              {'Are you sure want to "' +
                selectedStatus.text +
                '" the leave request no. ' +
                selectedRecord.requestNo +
                " ..?"}
            </p>
          </UiDialog>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loginReducer: state.loginReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(LeaveRequests));
