import React, { Component } from "react";
import jQuery from "jquery";
import { connect } from "react-redux";
import { CircularProgress } from "@material-ui/core";
import { LocalShipping as DashMenuIcon } from "@material-ui/icons";
import "datatables.net-bs4";
import "datatables.net-buttons-bs4";
import "datatables.net-buttons/js/buttons.html5";
import "datatables.net-buttons/js/buttons.print";
import "datatables.net-buttons/js/buttons.flash";

import UiDialog from "../ui/dialogbox/UiDialog";
import ReportGrid from "./ReportGrid";

import { API_HANDLER } from "../../apihandlers";
import { commonJs } from "../../common/scripts";
import { CONFIG, LABELS } from "../../common/consts";

import imgWaitingForLoad from "../../images/dashboard/available_for_loading.png";
import imgLoadAssignedVehicles from "../../images/dashboard/load_assigned_vehicles.png";
import imgReadyForLoading from "../../images/dashboard/ready_for_loading.png";
import imgWaitingForVehiclesDeparture from "../../images/dashboard/Vehicle_For_Vehicle_Departure.png";
import imgInTransit from "../../images/dashboard/In-Transit.png";
import imgWaitingForUnload from "../../images/dashboard/Waiting_For_Unload.png";
import imgAccidentOrRepair from "../../images/dashboard/Accident_Or_Repair.png";
import imgVehiclesWithoutDriver from "../../images/dashboard/Vehicles_Without_Driver.png";
import imgVehiclesWithDriver from "../../images/dashboard/Drivers_Without_Vehicle.png";
import imgAllVehicles from "../../images/dashboard/All_Vehicles.png";

import "./styles.css";
import "./reports.css";
import LeftBar from "../Layouts/leftbar/LeftBar";

var $ = jQuery;

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDialog: false,
      dashboardInfo: {},
      loading: true,
      reportTitle: "Report",
      columns: [],
      rows: null,
      tableColumnExtensions: [],
      tableColumnWidths: [],
    };
  }

  componentDidMount() {
    this.loadDashboardInfo();
    const self = this;
    setInterval(() => {
      self.loadDashboardInfo();
    }, 1000 * 60);
  }

  loadDashboardInfo = () => {
    const self = this;
    API_HANDLER.getDashboadInfo().then((res) => {
      console.log("getDashboadInfo: ", res.response);
      if (res.success) {
        self.setState({
          dashboardInfo: res.response,
          loading: false,
        });
      }
    });
  };

  openMenu = (menu = "", title = "") => {
    const self = this;
    this.setState({
      showDialog: true,
      reportTitle: title,
    });
    setTimeout(() => {
      self.loadReport(menu, title);
    }, 500);
  };

  loadReport = (menu = "", reportTitle = "") => {
    const { dashboardInfo } = this.state;
    if (!dashboardInfo[menu]) {
      return;
    }
    const { rows, cols } = dashboardInfo[menu];
    let tableColumnExtensions = [];
    let tableColumnWidths = [];
    let columns = cols.map((col, i) => {
      return {
        title: col,
      };
    });
    // let columns = cols.map((col, i) => {
    //   let cWidth = {
    //     columnName: col,
    //     width: 250,
    //   };
    //   let cExtension = {
    //     columnName: col,
    //     align: "left",
    //   };
    //   if (i === 0) {
    //     cWidth.width = 80;
    //     cExtension.align = "center";
    //   }
    //   tableColumnWidths.push(cWidth);
    //   tableColumnExtensions.push(cExtension);
    //   return {
    //     name: col,
    //     title: col,
    //     sortingEnabled: true,
    //   };
    // });

    // let rowList = [];
    // for (let i = 0; i < rows.length; i++) {
    //   let row = {};
    //   for (let c = 0; c < cols.length; c++) {
    //     if (c === 0) {
    //       row[cols[c]] = rows[i][c];
    //     } else {
    //       row[cols[c]] = (
    //         <a
    //           href="#"
    //           onClick={() =>
    //             this._reportGrid.valueFilterBy(rows[i][c], cols[c])
    //           }
    //         >
    //           {rows[i][c]}
    //         </a>
    //       );
    //     }
    //   }
    //   rowList.push(row);
    // }

    let title = "Report: " + reportTitle;
    // this.setState({
    //   columns,
    //   rows: rowList,
    //   reportTitle: title,
    //   tableColumnExtensions,
    //   tableColumnWidths,
    // });
    // return;

    if ($.fn.dataTable.isDataTable("#reportGrid")) {
      let table = $("#reportGrid").DataTable();
      table.destroy();
    }
    $("#reportGrid").empty();

    this.setState({});

    let tableObj = $("#reportGrid").DataTable({
      dom: "Bfrtip",
      buttons: [
        // {
        //   extend: "copy",
        //   className: "reportBtn",
        //   title: title,
        // },
        // {
        //   extend: "excel",
        //   className: "reportBtn",
        //   title: title,
        // },
        {
          extend: "csv",
          className: "reportBtn",
          title: title,
          text: "CSV",
        },
        {
          extend: "print",
          className: "reportBtn",
          title: title,
          customize: function (win) {
            commonJs.getExportPrintCss(win);
          },
        },
      ],
      paging: true,
      pageLength: 15,
      // scrollY: 400,
      columns: columns,
      data: rows,
      // initComplete: function () {
      //   // Apply the search
      //   this.api()
      //     .columns()
      //     .every(function () {
      //       // const self = this;
      //       // const indx = this.index();
      //       // console.log(
      //       //   $("table#reportGrid>thead th:nth-child(" + indx + ")>input")
      //       // );
      //       // $(document).on(
      //       //   "keyup change clear",
      //       //   "table#reportGrid>thead th:nth-child(" + indx + ")>input",
      //       //   function () {
      //       //     console.log("keyup:", this.value, self.search());
      //       //     if (self.search() !== this.value) {
      //       //       self.search(this.value).draw();
      //       //     }
      //       //   }
      //       // );
      //     });
      // },
    });

    const key = CONFIG.FLEET_API_KEY;
    const updateLocation = function (self, cellIdx1, lat1, lng1) {
      console.log("updateLocation: ", self, cellIdx1, lat1, lng1);
      let data = self.data();
      $.getJSON(
        "https://fleetlogix.vaschan.com/api/v1/getloc?lat=" +
          lat1 +
          "&lng=" +
          lng1 +
          "&key=" +
          key,
        function (resp) {
          if (
            resp.address == "false" ||
            resp.address == false ||
            resp == "false"
          ) {
            resp.city = "";
            resp.state = "";
            resp.address = "";
          }
          data[cellIdx1] =
            '<a style="white-space: nowrap;" class="geocodeLocation red" href="//maps.google.com/maps?q=' +
            lat1 +
            "," +
            lng1 +
            '&t=m" target="_blank">' +
            resp.address +
            "</a>";
          //data[cellIdx2] = resp.city;
          //data[cellIdx3] = resp.state;
          self.data(data);
        }
      );
    };

    tableObj.rows().every(function (rowIdx, tableLoop, rowLoop) {
      let data = this.data();
      let lat = data[cols.indexOf("Latitude")];
      let lng = data[cols.indexOf("Longitude")];
      let addressIndx = cols.indexOf("Address");
      if (lat && lng) {
        updateLocation(this, addressIndx, lat, lng);
      }
    });

    // $("#reportGrid thead th").each(function () {
    //   var title = $(this).text();
    //   $(this).html('<input type="text" placeholder="Search ' + title + '" />');
    // });
  };

  onCloseDialog = () => {
    this.setState({
      showDialog: false,
    });
    this.loadReport();
  };

  renderMenu = (title = "", count = 0, key = "", img) => {
    return (
      <div className="dashMenuWrapper">
        <div className="dashMenu" onClick={() => this.openMenu(key, title)}>
          <div className="dashMenuIcon">
            <img src={img} />
          </div>
          <div className="dashMenuCount">
            <h4>{count}</h4>
          </div>
          <div className="dashMenuDetails">
            <div className="dashMenuTextWrapper">
              <h4>{title}</h4>
            </div>
          </div>
        </div>
      </div>
    );
  };

  onChangeFile = (e) => {
    const { files } = e.target;
    console.log("uploadFile: File:", files[0]);
    const self = this;
    let reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = () => {
      self.uploadFile(files[0], reader.result);
    };
    reader.onerror = (error) => {
      console.log("uploadFile: Error:", error);
    };
  };

  uploadFile = (file, base64 = "") => {
    const args = {
      recordRefId: 123456,
      file: {
        name: file.name,
        data: base64.replace("data:", "").replace(/^.+,/, ""),
        type: file.name.split(".")[1],
        size: file.size,
      },
      category: "indent", // driver, lr, indent, pod, other
    };
    API_HANDLER.fileUpload(args).then((res) => {
      console.log("fileUpload: ");
    });
  };

  render() {
    const {
      showDialog,
      dashboardInfo,
      loading,
      rows,
      columns,
      tableColumnExtensions,
      tableColumnWidths,
      reportTitle,
    } = this.state;
    const { userName } = this.props.loginReducer;
    return (
      <div id="adminDashboard">
        <div style={{ width: "95%", margin: "auto" }}>
          <h4
            style={{
              fontSize: 24,
              margin: 0,
              paddingTop: 30,
              paddingBottom: 15,
            }}
          >
            {"Dashboard"}
          </h4>
          {/* <input type={"file"} onChange={this.onChangeFile} /> */}
        </div>

        {/* Menus */}
        {loading ? (
          <div
            style={{
              width: "100%",
              height: 300,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress className={"primary"} size="1.5rem" />
          </div>
        ) : (
          <div className="dashMenus">
            {/* Available For Loading */}
            {this.renderMenu(
              LABELS.AVAILABLE_FOR_LOADING,
              dashboardInfo.availableForLoading.rows.length,
              "availableForLoading",
              imgWaitingForLoad
            )}

            {/* Load Assigned Vehicles */}
            {this.renderMenu(
              LABELS.LOAD_ASSIGNED_VEHICLES,
              dashboardInfo.loadAssignedVehicles.rows.length,
              "loadAssignedVehicles",
              imgLoadAssignedVehicles
            )}

            {/* Ready for Loading */}
            {this.renderMenu(
              LABELS.READY_FOR_LOADING,
              dashboardInfo.readyForLoading.rows.length,
              "readyForLoading",
              imgReadyForLoading
            )}

            {/* Waiting for vehicle departure */}
            {this.renderMenu(
              LABELS.WAITING_FOR_VEHICLES_DEPARTURE,
              dashboardInfo.waitingForVehiclesDeparture.rows.length,
              "waitingForVehiclesDeparture",
              imgWaitingForVehiclesDeparture
            )}

            {/* In-Transit */}
            {this.renderMenu(
              LABELS.IN_TRANSIT,
              dashboardInfo.inTransit.rows.length,
              "inTransit",
              imgInTransit
            )}

            {/* Waiting For Unload */}
            {this.renderMenu(
              LABELS.WAITING_FOR_UNLOAD,
              dashboardInfo.waitingForUnload.rows.length,
              "waitingForUnload",
              imgWaitingForUnload
            )}

            {/* Running Delay */}
            {/* {this.renderMenu(
              LABELS.RUNNING_DELAY,
              dashboardInfo.runningDelay.rows.length,
              "runningDelay"
            )} */}

            {/* Accident or Repair */}
            {this.renderMenu(
              LABELS.ACCIDENT_OR_REPAIR,
              dashboardInfo.accidentOrRepair.rows.length,
              "accidentOrRepair",
              imgAccidentOrRepair
            )}

            {/* Vehicles without Driver */}
            {this.renderMenu(
              LABELS.VEHICLES_WITHOUT_DRIVER,
              dashboardInfo.vehiclesWithoutDriver.rows.length,
              "vehiclesWithoutDriver",
              imgVehiclesWithoutDriver
            )}

            {/* Drivers without Vehicle */}
            {this.renderMenu(
              LABELS.DRIVERS_WITHOUT_VEHICLE,
              dashboardInfo.vehiclesWithDriver.rows.length,
              "vehiclesWithDriver",
              imgVehiclesWithDriver
            )}

            {/* All Vehicle */}
            {this.renderMenu(
              LABELS.ALL_VEHICLES,
              dashboardInfo.allVehicles.rows.length,
              "allVehicles",
              imgAllVehicles
            )}
          </div>
        )}

        {showDialog && (
          <UiDialog
            id={"dashDialog"}
            open={true}
            maxWidth={"lg"}
            draggable={false}
            title={reportTitle}
            onCloseDialog={this.onCloseDialog}
          >
            {/* <ReportGrid
              childRef={(ref) => (this._reportGrid = ref)}
              rows={rows}
              columns={columns}
              tableColumnExtensions={tableColumnExtensions}
              tableColumnWidths={tableColumnWidths}
              title={reportTitle}
            /> */}

            <div className="reportGridWrapper">
              <table
                id="reportGrid"
                className="mdl-data-table width100 paginated"
                cellSpacing="0"
                cellPadding="3"
              />
            </div>
          </UiDialog>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loginReducer: state.loginReducer,
    pageReducer: state.pageReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
