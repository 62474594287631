import { LOGIN_ACTIONS } from "../actions/LoginActions";
import { LOCAL_STORAGE } from "../common/consts";

// Login & Major Settings Details
let initialState = {
  login: false,
  sessionId: localStorage[LOCAL_STORAGE.ACCESS_TOKEN] || "",
  userId: localStorage[LOCAL_STORAGE.USER_ID] || "",
  userName: localStorage[LOCAL_STORAGE.USER_NAME] || "",
  userRole: localStorage[LOCAL_STORAGE.USER_ROLE]
    ? Number(localStorage[LOCAL_STORAGE.USER_ROLE])
    : 0,
  userInfo: localStorage[LOCAL_STORAGE.USER_INFO]
    ? JSON.parse(localStorage[LOCAL_STORAGE.USER_INFO])
    : {},
};

const loginReducer = (state = initialState, action) => {
  const { type, values } = action;
  switch (type) {
    case LOGIN_ACTIONS.SET_LOGIN:
      return { ...state, login: values.login || false };
    case LOGIN_ACTIONS.SET_LOGIN_INFO:
      return {
        ...state,
        login: values.login || false,
        sessionId: values.sessionId || "",
        userId: values.userId || "",
        userName: values.userName || "",
        userRole: Number(values.userRole) || "",
        userInfo: values.userInfo || {},
      };
    default:
      break;
  }
  return state;
};

export default loginReducer;
