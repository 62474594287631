import React, { Component } from "react";
import { connect } from "react-redux";

import LeftBar from "../Layouts/leftbar/LeftBar";
import UnityMap from "../UnityMap";
import HrDashboard from "../Dashboard/HrDashboard";
import Dashboard from "../Dashboard";
import OwnVehicles from "../OwnVehicles";
import MarketVehicles from "../MarketVehicles";
import Users from "../Dashboard/Users";
import WelcomePage from "../WelcomePage";
import LoadingDiv from "../ui/loaders/LoadingDiv";

import { CONFIG } from "../../common/consts";

class HomePage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  updateRegion = (lat, lng, zoom) => {
    if (this._unityMap) {
      this._unityMap.updateRegion(lat, lng, zoom);
    }
  };

  focusTrack = () => {
    if (this._unityMap) {
      this._unityMap.trackFitBounds();
    }
  };

  render() {
    const { userRole } = this.props.loginReducer;
    const { activeMenu, loading } = this.props.pageReducer;
    console.log("activeMenu: ", activeMenu);
    return (
      <div style={{ height: "100%", backgroundColor: "#fff" }}>
        {loading && <LoadingDiv />}
        <LeftBar
          childRef={(ref) => (this._leftMenu = ref)}
          updateRegion={this.updateRegion}
          focusTrack={this.focusTrack}
        />
        {activeMenu === CONFIG.PAGES.PLACES && (
          <UnityMap
            childRef={(ref) => (this._unityMap = ref)}
            updateRegion={this.updateRegion}
            focusTrack={this.focusTrack}
          />
        )}
        {activeMenu === CONFIG.PAGES.HR_DASHBOARD && <HrDashboard />}
        {activeMenu === CONFIG.PAGES.DASHBOARD && <Dashboard />}
        {activeMenu === CONFIG.PAGES.OWN_VEHICLES && <OwnVehicles />}
        {activeMenu === CONFIG.PAGES.MARKET_VEHICLES && <MarketVehicles />}
        {activeMenu === CONFIG.PAGES.USERS && <Users />}
        {activeMenu === CONFIG.PAGES.WELCOME && <WelcomePage />}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loginReducer: state.loginReducer,
    pageReducer: state.pageReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
