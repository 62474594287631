import { MAP_FORM_ACTIONS } from "../actions/MapFormActions";

let initialState = {
  stateList: [],
  cityList: [],
  customerList: [],
  consigneeList: [],
  selectedState: {},
  selectedCity: {},
  selectedCustomer: {},
  selectedConsignee: {},
};

const mapFormReducer = (state = initialState, action) => {
  const { type, values } = action;
  switch (type) {
    case MAP_FORM_ACTIONS.SET_STATES:
      return { ...state, stateList: values.stateList };
    case MAP_FORM_ACTIONS.SET_CITIES:
      return { ...state, cityList: values.cityList };
    case MAP_FORM_ACTIONS.SET_CUSTOMERS:
      return { ...state, customerList: values.customerList };
    case MAP_FORM_ACTIONS.SET_CONSIGNEES:
      return { ...state, consigneeList: values.consigneeList };
    case MAP_FORM_ACTIONS.SET_SELECTED_STATE:
      return { ...state, selectedState: values.selectedState };
    case MAP_FORM_ACTIONS.SET_SELECTED_CITY:
      return { ...state, selectedCity: values.selectedCity };
    case MAP_FORM_ACTIONS.SET_SELECTED_CUSTOMER:
      return { ...state, selectedCustomer: values.selectedCustomer };
    case MAP_FORM_ACTIONS.SET_SELECTED_CONSIGNEE:
      return { ...state, selectedConsignee: values.selectedConsignee };
    case MAP_FORM_ACTIONS.RESET_MAP_FORM:
      return {
        ...state,
        selectedCustomer: values.selectedCustomer,
        selectedConsignee: values.selectedConsignee,
      };
    case MAP_FORM_ACTIONS.SET_CITY_STATE:
      return {
        ...state,
        selectedCity: values.selectedCity,
        selectedState: values.selectedState,
      };
    case MAP_FORM_ACTIONS.SET_SELECTED_VALUES:
      return {
        ...state,
        selectedCity: values.selectedCity,
        selectedState: values.selectedState,
        selectedCustomer: values.selectedCustomer,
      };
    default:
      break;
  }
  return state;
};

export default mapFormReducer;
