import React, { Component } from "react";
import { Chip, CircularProgress, IconButton, Tooltip } from "@material-ui/core";
import {
  ArrowForward,
  Cancel as DeleteIcon,
  CloudDownload as ExportIcon,
} from "@material-ui/icons";
import {
  // SummaryState,
  SortingState,
  IntegratedSorting,
  // GroupingState,
  // IntegratedGrouping,
  // DataTypeProvider,
  PagingState,
  IntegratedPaging,
} from "@devexpress/dx-react-grid";
import {
  Grid,
  VirtualTable,
  TableHeaderRow,
  // TableGroupRow,
  // GroupingPanel,
  TableColumnResizing,
  ColumnChooser,
  TableColumnVisibility,
  Toolbar,
  // DragDropProvider,
  TableColumnReordering,
  // ExportPanel,
  // TableFixedColumns,
  // TableSummaryRow,
  PagingPanel,
} from "@devexpress/dx-react-grid-material-ui";

import "./styles.css";

const getRowId = (row) => row.id;

class ReportGrid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filteredRows: [],
      valueFilter: [],
      pageNo: 0,
    };
  }

  componentDidMount() {
    const { childRef } = this.props;
    childRef(this);
  }

  componentWillUnmount() {
    const { childRef } = this.props;
    childRef(undefined);
  }

  valueFilterBy = (value = "", column = "") => {
    const { rows, columns } = this.props;
    const { filteredRows, valueFilter } = this.state;
    let rowData = filteredRows.length > 0 ? filteredRows : rows;
    value = value.toUpperCase().trim();
    let oldList = valueFilter.filter((v) => {
      if (v.value) {
        return v.value.toUpperCase().trim() === value;
      } else {
        return false;
      }
    });
    let isExists = oldList.length > 0;
    if (!isExists) {
      let fRows = rowData.filter((r) => {
        if (r[column]) {
          if (r[column].props.children) {
            return r[column].props.children.toUpperCase().trim() === value;
          }
          return false;
        } else {
          return false;
        }
      });
      valueFilter.push({ column, value });
      fRows = fRows.map((r, i) => {
        r[columns[0].title] = i + 1;
        return r;
      });
      this.setState({
        filteredRows: fRows,
        valueFilter,
      });
    }
  };

  startExport = () => {
    const { filteredRows } = this.state;
    const { rows, columns, title } = this.props;
    let rowData = filteredRows.length > 0 ? filteredRows : rows;
    let csvContent = "data:text/csv;charset=utf-8,";
    // Generate Columns
    for (let c = 0; c < columns.length; c++) {
      csvContent += columns[c].title + ",";
    }
    csvContent += "\n";
    // Generate Rows
    for (let i = 0; i < rowData.length; i++) {
      let row = rowData[i];
      for (let c = 0; c < columns.length; c++) {
        if (row[columns[c].title].props) {
          csvContent += row[columns[c].title].props.children + ",";
        } else {
          csvContent += row[columns[c].title] + ",";
        }
      }
      csvContent += ",\n";
    }
    let encodedUri = encodeURI(csvContent);
    let link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", title + ".csv");
    document.body.appendChild(link); // Required for FF
    link.click(); // This will download the data file named "my_data.csv".
  };

  clearFilter = (index = 0, groupValue = {}) => {
    const { rows, columns } = this.props;
    const { valueFilter } = this.state;
    let fRows = rows;
    let finalValueFilter = valueFilter.filter(
      (v) => v.value !== groupValue.value
    );
    for (let i = 0; i < finalValueFilter.length; i++) {
      fRows = fRows.filter((r) => {
        if (r[finalValueFilter[i]["column"]] && finalValueFilter[i]["value"]) {
          if (r[finalValueFilter[i]["column"]].props.children) {
            return (
              r[finalValueFilter[i]["column"]].props.children
                .toUpperCase()
                .trim() === finalValueFilter[i]["value"].toUpperCase().trim()
            );
          } else {
            return false;
          }
        } else {
          return false;
        }
      });
    }
    fRows = fRows.map((r, i) => {
      r[columns[0].title] = i + 1;
      return r;
    });
    this.setState({
      valueFilter: finalValueFilter,
      filteredRows: fRows,
    });
  };

  setPageNumber = (pageNo = 0) => {
    this.setState({
      pageNo,
    });
  };

  render() {
    const { filteredRows, valueFilter, pageNo } = this.state;
    const { columns, rows, tableColumnExtensions, tableColumnWidths } =
      this.props;
    const gridHeight = window.innerHeight / 2;
    console.log("report grid: ", columns, rows);
    return (
      <div className="fleetGrid" style={{ position: "relative" }}>
        {filteredRows.length > 0 && (
          <div className="valueFilters">
            <div>
              <h4>Column Filter:</h4>
            </div>
            <div className="chips">
              {valueFilter.map((chip, i) => {
                return (
                  <Chip
                    key={"filterchip-" + i}
                    label={chip.value}
                    onDelete={() => this.clearFilter(i, chip)}
                    deleteIcon={<DeleteIcon />}
                  />
                );
              })}
            </div>
          </div>
        )}
        <div
          style={{
            zIndex: 99999,
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
          }}
        >
          <Tooltip title={"Export to CSV"} placement="bottom">
            <IconButton className="iconBtn" onClick={this.startExport}>
              <ExportIcon />
            </IconButton>
          </Tooltip>
        </div>
        {rows ? (
          <Grid
            rows={filteredRows.length > 0 ? filteredRows : rows}
            columns={columns}
          >
            <PagingState
              currentPage={pageNo}
              defaultPageSize={15}
              onCurrentPageChange={this.setPageNumber}
            />
            <IntegratedPaging />

            <SortingState
              columnSortingEnabled={true}
              onSortingChange={this.onSorting}
            />
            <IntegratedSorting />

            <VirtualTable
              columnExtensions={tableColumnExtensions || []}
              height={gridHeight}
            />

            <TableColumnResizing
              defaultColumnWidths={tableColumnWidths || []}
            />

            <TableHeaderRow showSortingControls={true} />

            {/* <Toolbar /> */}

            <PagingPanel />
          </Grid>
        ) : (
          <div
            style={{
              height: gridHeight,
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress className="loader" />
          </div>
        )}
      </div>
    );
  }
}

export default ReportGrid;
