import React, { Component } from "react";
import {
  SortingState,
  IntegratedSorting,
  PagingState,
  CustomPaging,
  IntegratedPaging,
} from "@devexpress/dx-react-grid";
import {
  Grid,
  VirtualTable,
  TableHeaderRow,
  TableColumnResizing,
  PagingPanel,
} from "@devexpress/dx-react-grid-material-ui";
import { IconButton, Tooltip, CircularProgress } from "@material-ui/core";
import {
  AddBox as AddIcon,
  Edit as EditIcon,
  Replay as ReloadIcon,
  Settings as ManageAttnIcon,
} from "@material-ui/icons";

import UiDialog from "../../ui/dialogbox/UiDialog";
import Txt from "../../ui/textbox/Txt";
import UserDialog from "./UserDialog";

import { API_HANDLER } from "../../../apihandlers";
import { CONFIG, LABELS } from "../../../common/consts";

class UsersGrid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [
        {
          name: "slNo",
          title: "Sl No.",
        },
        {
          name: "empName",
          title: "Employee Name",
        },
        {
          name: "empCode",
          title: "Employee Code",
        },
        {
          name: "userName",
          title: "User Name",
        },
        {
          name: "userId",
          title: "User ID",
        },
        {
          name: "userRoleText",
          title: "User Role",
        },
        {
          name: "activeText",
          title: "Active",
        },
        {
          name: "options",
          title: "Options",
        },
      ],
      rows: null,
      filteredRows: [],
      pageNo: 0,
      totalRows: 0,
      defaultSorting: [{ columnName: "slNo", direction: "asc" }],
      officeZones: [],
      employeeList: [],
      selectedEmployee: {},
      showEmpDialog: false,
      zonesJson: {},
      managers: [],
      managersJson: {},
    };

    this._tableColumnWidths = [
      {
        columnName: "slNo",
        width: 100,
      },
      { columnName: "empName", width: 200 },
      { columnName: "empCode", width: 100 },
      { columnName: "userName", width: 200 },
      { columnName: "userId", width: 200 },
      { columnName: "userRoleText", width: 200 },
      { columnName: "activeText", width: 150 },
      { columnName: "options", width: 150 },
    ];

    this._tableColumnExtensions = [
      {
        columnName: "slNo",
        align: "center",
      },
      { columnName: "empName", align: "left" },
      { columnName: "empCode", align: "left" },
      { columnName: "userName", align: "left" },
      { columnName: "userId", align: "left" },
      { columnName: "userRoleText", align: "left" },
      { columnName: "activeText", align: "left" },
      { columnName: "options", align: "center" },
    ];

    this._sortingDisabledColumns = [
      { columnName: "options", sortingEnabled: false },
    ];

    this._timer = null;

    this._rolesJson = {};
    for (let key in CONFIG.USER_ROLES) {
      this._rolesJson[CONFIG.USER_ROLES[key]] = key;
    }
  }

  componentDidMount() {
    this.reloadData();
  }

  reloadData = () => {
    this.setState({
      rows: null,
      filteredRows: [],
    });
    this.loadEmployeesList();
  };

  loadEmployeesList = () => {
    const self = this;
    API_HANDLER.getAllEmployees().then((res) => {
      if (res.success) {
        const { records } = res.response;
        const rows = records.map((r, i) => {
          r.slNo = i + 1;
          r.options = self.generateOptions(r);
          r.userRoleText = self._rolesJson[r.userRole];
          r.activeText = r.active === "T" ? "Yes" : "No";
          return r;
        });
        const employeeList = records.map((r) => {
          return {
            id: r.empId,
            name: r.empName,
          };
        });
        self.setState({ employeeList, rows, totalRows: rows.length });
      }
    });
  };

  generateOptions = (rec = {}) => {
    return (
      <div className="options">
        <Tooltip title="Edit" placement="bottom">
          <IconButton
            className="dataGridIcon"
            onClick={() => this.showEmployeeDialog(rec)}
          >
            <EditIcon />
          </IconButton>
        </Tooltip>
      </div>
    );
  };

  showEmployeeDialog = (selectedEmployee = {}) => {
    this.setState({
      selectedEmployee,
      showEmpDialog: true,
    });
  };

  onCloseEmpDialog = () => {
    this.setState({
      showEmpDialog: false,
    });
  };

  showAttendanceDialog = (rec = {}) => {
    this.setState({
      selectedEmployee: rec,
      showAttendanceDialog: true,
    });
  };

  onCloseAttnDialog = () => {
    this.setState({
      showAttendanceDialog: false,
    });
  };

  onEmpSearch = (e) => {
    const searchValue = e.target.value.trim().toUpperCase();
    clearTimeout(this._timer);
    const self = this;
    this._timer = setTimeout(() => {
      const { rows } = self.state;
      let filteredRows = rows.filter((r) => {
        return r.empName.toUpperCase().startsWith(searchValue);
      });
      filteredRows = filteredRows.map((f, i) => {
        f.slNo = i + 1;
        return f;
      });
      if (filteredRows.length === 0) {
        filteredRows = null;
      }
      if (searchValue === "") {
        filteredRows = [];
      }
      self.setState({
        filteredRows,
      });
    }, 500);
  };

  render() {
    const {
      rows,
      columns,
      pageNo,
      totalRows,
      defaultSorting,
      officeZones,
      showEmpDialog,
      employeeList,
      selectedEmployee,
      managers,
      filteredRows,
      showAttendanceDialog,
    } = this.state;
    let gridHeight = window.innerHeight * 0.65;
    let finalRows = [];
    if (filteredRows) {
      finalRows = filteredRows.length > 0 ? filteredRows : rows;
    } else {
      finalRows = [];
    }
    return (
      <div className="tabContent" style={{ paddingTop: 0 }}>
        {rows ? (
          <div className="dataRGrid">
            <div
              style={{
                display: "flex",
                marginBottom: 10,
                marginTop: 12,
                alignItems: "center",
              }}
            >
              <div style={{ width: "30%" }}>
                <Txt
                  id="empSearch"
                  placeHolder={"Search User"}
                  onKeyPress={this.onEmpSearch}
                />
              </div>
              <div>
                <Tooltip title={"Reload"} placement="bottom">
                  <IconButton className="iconBtn" onClick={this.reloadData}>
                    <ReloadIcon />
                  </IconButton>
                </Tooltip>
              </div>
            </div>
            <Grid rows={finalRows} columns={columns}>
              <PagingState
                // currentPage={pageNo}
                onCurrentPageChange={this.setCurrentPage}
                pageSize={15}
              />
              <IntegratedPaging />
              {/* <CustomPaging totalCount={totalRows} /> */}

              <SortingState
                defaultSorting={defaultSorting}
                columnSortingEnabled={true}
                columnExtensions={this._sortingDisabledColumns}
                onSortingChange={this.onGridSorting}
              />
              <IntegratedSorting />

              <VirtualTable
                columnExtensions={this._tableColumnExtensions || []}
                height={gridHeight}
              />

              <TableColumnResizing
                defaultColumnWidths={this._tableColumnWidths || []}
              />

              <TableHeaderRow showSortingControls={true} />

              <PagingPanel />
            </Grid>
          </div>
        ) : (
          <div
            style={{
              height: 400,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress className={"primary"} size="1.5rem" />
          </div>
        )}

        {showEmpDialog && (
          <UiDialog
            id={"dashDialog"}
            open={true}
            maxWidth={"sm"}
            title={selectedEmployee.userId ? "Edit User" : "Add User"}
            onCloseDialog={this.onCloseEmpDialog}
          >
            <UserDialog
              employeeList={employeeList}
              employee={selectedEmployee}
              onCloseDialog={this.onCloseEmpDialog}
              reloadGrid={this.reloadData}
            />
          </UiDialog>
        )}
      </div>
    );
  }
}

export default UsersGrid;
