import React, { Component } from "react";
import {
  SortingState,
  IntegratedSorting,
  PagingState,
  CustomPaging,
  IntegratedPaging,
} from "@devexpress/dx-react-grid";
import {
  Grid,
  VirtualTable,
  TableHeaderRow,
  TableColumnResizing,
  PagingPanel,
} from "@devexpress/dx-react-grid-material-ui";
import { IconButton, Tooltip, CircularProgress } from "@material-ui/core";
import {
  AddBox as AddIcon,
  Edit as EditIcon,
  Replay as ReloadIcon,
} from "@material-ui/icons";

import Btn from "../../../ui/button/Btn";
import UiDialog from "../../../ui/dialogbox/UiDialog";
import ManageAttnDialog from "./ManageAttnDialog";

import { LABELS } from "../../../../common/consts";
import { API_HANDLER } from "../../../../apihandlers";

class AttnGrid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      addAttnDialog: false,
      record: {},
    };
    this._timer = null;
  }

  componentDidMount() {
    const { childRef } = this.props;
    childRef(this);
  }

  reloadGrid = () => {
    this.props.reloadGrid();
  };

  componentWillUnmount() {
    const { childRef } = this.props;
    childRef(undefined);
  }

  showAddAttnDialog = (record = {}) => {
    this.setState({
      addAttnDialog: true,
      record,
    });
  };

  closeAddAttnDialog = () => {
    this.setState({
      addAttnDialog: false,
    });
  };

  render() {
    const { addAttnDialog, record } = this.state;
    const {
      rows,
      columns,
      filteredRows,
      sortingDisabledColumns,
      tableColumnExtensions,
      tableColumnWidths,
      defaultSorting,
      selectedEmployee,
    } = this.props;
    let gridHeight = window.innerHeight * 0.4;
    let finalRows = [];
    if (filteredRows) {
      finalRows = filteredRows.length > 0 ? filteredRows : rows;
    } else {
      finalRows = [];
    }
    console.log("AttnGrid: rows: ", rows);
    return (
      <div>
        <div className="mB5">
          <label className="formLabel">{LABELS.EMP_ATTN_DETAILS + " :"}</label>
        </div>
        <div className="gridBtns">
          <div>
            <Btn className={"primaryBtn"} onClick={this.showAddAttnDialog}>
              <span className="btnTxt">{"ADD "}</span>
              <AddIcon />
            </Btn>
          </div>
          <div>
            <Btn className={"primaryBtn"} onClick={this.reloadGrid}>
              <span className="btnTxt">{"Reload "}</span>
              <ReloadIcon />
            </Btn>
          </div>
        </div>
        {rows && (
          <div className="dataRGrid">
            <Grid rows={finalRows} columns={columns}>
              <PagingState
                // currentPage={pageNo}
                onCurrentPageChange={this.setCurrentPage}
                pageSize={10}
              />
              <IntegratedPaging />
              {/* <CustomPaging totalCount={totalRows} /> */}

              <SortingState
                defaultSorting={defaultSorting}
                columnSortingEnabled={true}
                columnExtensions={sortingDisabledColumns}
                onSortingChange={this.onGridSorting}
              />
              <IntegratedSorting />

              <VirtualTable
                columnExtensions={tableColumnExtensions || []}
                height={gridHeight}
              />

              <TableColumnResizing
                defaultColumnWidths={tableColumnWidths || []}
              />

              <TableHeaderRow showSortingControls={true} />

              <PagingPanel />
            </Grid>
          </div>
        )}
        {addAttnDialog && (
          <UiDialog
            id={"dashDialog"}
            open={true}
            draggable={false}
            maxWidth={"sm"}
            title={
              record.attnBasicId
                ? LABELS.EDIT_ATTENDANCE
                : LABELS.ADD_ATTENDANCE
            }
            onCloseDialog={this.closeAddAttnDialog}
          >
            <ManageAttnDialog
              selectedEmployee={selectedEmployee}
              record={record}
              onCloseDialog={this.closeAddAttnDialog}
              reloadGrid={this.reloadGrid}
            />
          </UiDialog>
        )}
      </div>
    );
  }
}

export default AttnGrid;
