import React, { Component } from "react";

import UiDialog from "../../ui/dialogbox/UiDialog";

import { LABELS } from "../../../common/consts";

class ConfirmDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDialog: false,
      id: "",
      name: "",
    };
  }

  componentDidMount() {
    const { childRef } = this.props;
    childRef(this);
  }

  componentWillUnmount() {
    const { childRef } = this.props;
    childRef(undefined);
  }

  setData = ({ id, name }) => {
    this.setState({
      id,
      name,
    });
  };

  closeDialog = () => {
    this.setState({
      showDialog: false,
    });
  };

  showDialog = () => {
    this.setState({
      showDialog: true,
    });
  };

  render() {
    const { id, name, showDialog } = this.state;
    const { onDelete } = this.props;
    return (
      <UiDialog
        id={"dashDialog"}
        title={"Delete Place"}
        open={showDialog}
        maxWidth={"sm"}
        draggable={false}
        saveButton={true}
        saveBtnLabel={LABELS.OK}
        cancelButton={true}
        onCloseDialog={this.closeDialog}
        cancelAction={this.closeDialog}
        saveAction={() => onDelete(id)}
      >
        <p>{'Are you sure want to delete "' + name + '"..?'}</p>
      </UiDialog>
    );
  }
}

export default ConfirmDialog;
