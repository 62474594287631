import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";

import Login from "./components/Login";
import Error404 from "./components/error/Error404";
import PageLoader from "./components/ui/loaders/PageLoader";
import HomePage from "./components/HomePage";

import { PrivacyPolicy } from "./components/privacypolicy";
import { messaging } from "./firebase";
import { CONFIG, LOCAL_STORAGE } from "./common/consts";
import { LOGIN_ACTIONS } from "./actions/LoginActions";
import { PAGE_ACTIONS } from "./actions/PageActions";
import { API_HANDLER } from "./apihandlers";

class App extends Component {
  constructor() {
    super();
    this.state = {
      loading: true,
    };
  }

  componentDidMount() {
    this.checkLogin();
  }

  checkLogin = () => {
    const self = this;
    API_HANDLER.checklogin().then((res) => {
      if (res.success) {
        self.setState({
          loading: false,
        });
        self.props.setLogin({
          login: localStorage.getItem(LOCAL_STORAGE.ACCESS_TOKEN)
            ? true
            : false,
        });
        if (localStorage.getItem(LOCAL_STORAGE.ACCESS_TOKEN)) {
          self.firebaseToken();
        }
      }
    });
  };

  firebaseToken = () => {
    messaging
      .requestPermission()
      .then(async () => {
        const token = await messaging.getToken();
        console.log("[Web Push]: Login: token: ", token);
        API_HANDLER.setFcmToken({
          fcmToken: token,
          platform: "web",
        }).then((res) => {
          console.log("[Web Push]: Login: settoken: ", res);
        });
      })
      .catch((err) => {
        console.log("[Web Push]: Login: ", err);
      });
  };

  showLoadingDiv = () => {
    return <PageLoader />;
  };

  redirectToLoginPage = () => {
    const { loading } = this.state;
    return <Login loading={loading} setPrivileges={this.setPrivileges} />;
  };

  isLoggedin = () => {
    const { login } = this.props.loginReducer;
    if (login) {
      window.location = "/";
    }
    return <Login />;
  };

  openPage = (page = CONFIG.PAGES.HOME) => {
    const { login, userRole } = this.props.loginReducer;
    const { loading } = this.state;
    if (!loading) {
      if (login) {
        if (page === CONFIG.PAGES.HOME) {
          if (userRole === CONFIG.USER_ROLES.ADMIN) {
            this.props.setActiveMenu({
              activeMenu: CONFIG.PAGES.DASHBOARD,
            });
          } else if (userRole === CONFIG.USER_ROLES.HR) {
            this.props.setActiveMenu({
              activeMenu: CONFIG.PAGES.HR_DASHBOARD,
            });
          } else if (userRole === CONFIG.USER_ROLES.DRIVER) {
            this.props.setActiveMenu({
              activeMenu: CONFIG.PAGES.WELCOME,
            });
          } else {
            this.props.setActiveMenu({
              activeMenu: CONFIG.PAGES.PLACES,
            });
          }
        } else if (page === CONFIG.PAGES.DASHBOARD) {
          this.props.setActiveMenu({
            activeMenu: CONFIG.PAGES.DASHBOARD,
          });
        } else if (page === CONFIG.PAGES.HR_DASHBOARD) {
          this.props.setActiveMenu({
            activeMenu: CONFIG.PAGES.HR_DASHBOARD,
          });
        }
        return <HomePage />;
      } else {
        return this.redirectToLoginPage();
      }
    } else {
      return this.showLoadingDiv();
    }
  };

  render() {
    const siteTitle = CONFIG.SITE_NAME + " v" + CONFIG.VERSION;
    return (
      <React.Fragment>
        <Helmet>
          <title>{siteTitle}</title>
        </Helmet>
        <BrowserRouter>
          <Switch>
            <Route
              path={CONFIG.PAGES.HOME}
              render={() => this.openPage(CONFIG.PAGES.HOME)}
              exact
            />
            <Route path={CONFIG.PAGES.LOGIN} render={this.isLoggedin} />
            <Route
              path={CONFIG.PAGES.PRIVACY_POLICY}
              component={PrivacyPolicy}
              exact
            />
            <Route component={Error404} />
          </Switch>
        </BrowserRouter>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loginReducer: state.loginReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setLogin: (values) => {
      dispatch({
        type: LOGIN_ACTIONS.SET_LOGIN,
        values,
      });
    },
    setLoginInfo: (values) => {
      dispatch({
        type: LOGIN_ACTIONS.SET_LOGIN_INFO,
        values,
      });
    },
    setLeftMenu: (values) => {
      dispatch({
        type: PAGE_ACTIONS.SET_LEFT_MENU,
        values,
      });
    },
    setActiveMenu: (values) => {
      dispatch({
        type: PAGE_ACTIONS.SET_ACTIVE_MENU,
        values,
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
