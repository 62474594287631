import React, { Component } from "react";
import {
  TextField,
  FormControlLabel,
  Checkbox,
  IconButton,
} from "@material-ui/core";
import {
  Visibility as EyeOnIcon,
  VisibilityOff as EyeOffIcon,
} from "@material-ui/icons";
import { connect } from "react-redux";
import { withSnackbar } from "notistack";

import Btn from "../ui/button/Btn";
import Img from "../ui/image/Img";

import Logo from "../../images/logo/logo.png";

import {
  ERRORS,
  FORM_TITLES,
  LABELS,
  LOCAL_STORAGE,
  NOTIFY,
  SUCCESS,
  WARNINGS,
} from "../../common/consts";
import { LOGIN_ACTIONS } from "../../actions/LoginActions";
import { API_HANDLER } from "../../apihandlers";
import { commonJs } from "../../common/scripts";

import "./login.css";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rememberMe: localStorage[LOCAL_STORAGE.USER_NAME] ? true : false,
      showPassword: false,
    };
  }

  handleRememberMe = (rememberMe = true) => {
    this.setState({
      rememberMe,
    });
  };

  onLogin = () => {
    const self = this;
    const { rememberMe } = this.state;
    const args = {
      username: document.getElementById("uname").value.trim(),
      password: document.getElementById("pwd").value,
    };
    // validations
    if (args.username === "" || args.password === "") {
      commonJs.showNotify(
        self.props,
        NOTIFY.WARNING,
        WARNINGS.USER_NAME_OR_PASSWORD_MISSING
      );
      return;
    }
    API_HANDLER.login(args)
      .then((res) => {
        if (rememberMe) {
          localStorage.setItem(LOCAL_STORAGE.USER_NAME, args.username);
          localStorage.setItem(LOCAL_STORAGE.PASSWORD, args.password);
        } else {
          localStorage.removeItem(LOCAL_STORAGE.USER_NAME);
          localStorage.removeItem(LOCAL_STORAGE.PASSWORD);
          localStorage.removeItem(LOCAL_STORAGE.ACCESS_TOKEN);
          localStorage.removeItem(LOCAL_STORAGE.USER_ID);
          localStorage.removeItem(LOCAL_STORAGE.USER_ROLE);
        }
        if (res.success) {
          const { accessToken, user } = res.response;
          const { id, name, role } = user;
          localStorage[LOCAL_STORAGE.USER_INFO] = JSON.stringify(user);
          localStorage[LOCAL_STORAGE.ACCESS_TOKEN] = accessToken;
          localStorage[LOCAL_STORAGE.USER_ID] = id.toString();
          localStorage[LOCAL_STORAGE.USER_ROLE] = role;
          self.props.setLoginInfo({
            login: true,
            sessionId: accessToken,
            userId: id,
            userName: name,
            userRole: Number(role),
            userInfo: user,
          });
          commonJs.showNotify(
            self.props,
            NOTIFY.SUCCESS,
            SUCCESS.LOGIN_SUCCESSFUL
          );
        } else {
          commonJs.showNotify(
            self.props,
            NOTIFY.ERROR,
            ERRORS.LOGIN_UNSUCCESSFUL + res.reason
          );
        }
      })
      .catch((err) => {
        commonJs.showNotify(self.props, NOTIFY.ERROR, ERRORS.INTERNAL_ERROR);
      });
  };

  toggleShowPassword = () => {
    const { showPassword } = this.state;
    this.setState({
      showPassword: !showPassword,
    });
  };

  onPasswordKeyPress = (e) => {
    if (e.which === 13) {
      this.onLogin();
    }
  };

  render() {
    const { rememberMe, showPassword } = this.state;
    const { userName } = this.props.loginReducer;
    return (
      <div id="container" style={{ backgroundColor: "#f2f9ff" }}>
        <div className="subContainer">
          <center>
            <Img src={Logo} width="150" className="logo" />
            <p className="titleText">{FORM_TITLES.LOG_IN}</p>
          </center>
          <TextField
            id="uname"
            defaultValue={userName || ""}
            size="small"
            className="mB15"
            label={LABELS.USERNAME}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              position: "relative",
            }}
          >
            <TextField
              id="pwd"
              type={showPassword ? "text" : "password"}
              defaultValue={localStorage.pwd || ""}
              size="small"
              className="mB15"
              label={LABELS.PASSWORD}
              style={{ width: "100%" }}
              onKeyPress={this.onPasswordKeyPress}
            />
            <IconButton
              onClick={this.toggleShowPassword}
              style={{ position: "absolute", right: 0 }}
            >
              {showPassword ? (
                <EyeOnIcon className="icon" />
              ) : (
                <EyeOffIcon className="icon" />
              )}
            </IconButton>
          </div>
          <FormControlLabel
            control={<Checkbox checked={rememberMe} className="checkboxClr" />}
            label={LABELS.REMEMBER_ME}
            onChange={() => this.handleRememberMe(!rememberMe)}
          />
          <Btn id="loginBtn" variant="contained" onClick={this.onLogin}>
            {LABELS.LOGIN}
          </Btn>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loginReducer: state.loginReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setLogin: (values) => {
      dispatch({
        type: LOGIN_ACTIONS.SET_LOGIN,
        values,
      });
    },
    setLoginInfo: (values) => {
      dispatch({
        type: LOGIN_ACTIONS.SET_LOGIN_INFO,
        values,
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(Login));
