import { PAGE_ACTIONS } from "../actions/PageActions";
import { CONFIG } from "../common/consts";

let initialState = {
  leftBar: true,
  loading: false,
  activeMenu: CONFIG.PAGES.PLACES,
};

const pageReducer = (state = initialState, action) => {
  const { type, values } = action;
  switch (type) {
    case PAGE_ACTIONS.SET_LEFT_MENU:
      return { ...state, leftBar: values.leftBar };
    case PAGE_ACTIONS.SET_LOADING:
      return { ...state, loading: values.loading };
    case PAGE_ACTIONS.SET_ACTIVE_MENU:
      return { ...state, activeMenu: values.activeMenu };
    default:
      break;
  }
  return state;
};

export default pageReducer;
