import moment from "moment";
import { FORMATS } from "./consts";

export const dateFunctions = {
  getFromToVal: (type, dtformat = FORMATS.DATE) => {
    var frmval = "";
    var toval = "";
    switch (type) {
      case "lasthour":
        // Last Hour
        frmval = dateFunctions.getDate("lasthour", "from", dtformat);
        toval = dateFunctions.getDate("lasthour", "to", dtformat);
        break;
      case "today":
        // Today
        frmval = dateFunctions.getDate("today", "from", dtformat);
        toval = dateFunctions.getDate("today", "to", dtformat);
        break;
      case "yesterday":
        // Yesterday
        frmval = dateFunctions.getDate("yesterday", "from", dtformat);
        toval = dateFunctions.getDate("yesterday", "to", dtformat);
        break;
      case "twodays":
        // 2 days
        frmval = dateFunctions.getDate("2days", "from", dtformat);
        toval = dateFunctions.getDate("2days", "to", dtformat);
        break;
      case "threedays":
        // 3 days
        frmval = dateFunctions.getDate("3days", "from", dtformat);
        toval = dateFunctions.getDate("3days", "to", dtformat);
        break;
      case "thisweek":
        // This week
        frmval = dateFunctions.getDate("thisweek", "from", dtformat);
        toval = dateFunctions.getDate("thisweek", "to", dtformat);
        break;
      case "lastweek":
        // Last week
        frmval = dateFunctions.getDate("lastweek", "from", dtformat);
        toval = dateFunctions.getDate("lastweek", "to", dtformat);
        break;
      case "thismonth":
        // This month
        frmval = dateFunctions.getDate("thismonth", "from", dtformat);
        toval = dateFunctions.getDate("thismonth", "to", dtformat);
        break;
      case "lastmonth":
        // Last month
        frmval = dateFunctions.getDate("lastmonth", "from", dtformat);
        toval = dateFunctions.getDate("lastmonth", "to", dtformat);
        break;
      default:
        // Today
        frmval = dateFunctions.getDate("today", "from", dtformat);
        toval = dateFunctions.getDate("today", "to", dtformat);
        break;
    }
    return { frmval, toval };
  },

  /* Returns the "formated date" as string */
  getFormatedDate: function (
    dt,
    fromFormat = FORMATS.SQL_DATE,
    toFormat = FORMATS.DATE
  ) {
    if (dt === "") {
      return "";
    }
    var cdt = moment(dt).format(toFormat);
    if (typeof dt === "string") {
      cdt = moment(dt, fromFormat).format(toFormat);
    }
    return cdt;
  },

  /*  Returns the "fromdate/todate" as string for "Filters" */
  getDate: function (filter = "now", ftype = "from", dtformat = FORMATS.DATE) {
    var dt = new Date();
    switch (filter) {
      case "lasthour":
        if (ftype === "from") {
          dt = moment().subtract(1, "hour").startOf("hour");
        } else {
          dt = moment().subtract(1, "hour").endOf("hour");
        }
        break;
      case "today":
        if (ftype === "from") {
          dt = moment().startOf("day");
        } else {
          dt = moment().endOf("day");
        }
        break;
      case "yesterday":
        if (ftype === "from") {
          dt = moment().subtract(1, "days").startOf("day");
        } else {
          dt = moment().subtract(1, "days").endOf("day");
        }
        break;
      case "2days":
        if (ftype === "from") {
          dt = moment().subtract(2, "days").startOf("day");
        } else {
          dt = moment().subtract(2, "days").endOf("day");
        }
        break;
      case "3days":
        if (ftype === "from") {
          dt = moment().subtract(3, "days").startOf("day");
        } else {
          dt = moment().subtract(3, "days").endOf("day");
        }
        break;
      case "thisweek":
        if (ftype === "from") {
          dt = moment().startOf("week");
        } else {
          dt = moment().endOf("week");
        }
        break;
      case "lastweek":
        if (ftype === "from") {
          dt = moment().subtract(1, "week").startOf("week");
        } else {
          dt = moment().subtract(1, "week").endOf("week");
        }
        break;
      case "thismonth":
        if (ftype === "from") {
          dt = moment().startOf("month");
        } else {
          dt = moment().endOf("month");
        }
        break;
      case "lastmonth":
        if (ftype === "from") {
          dt = moment().subtract(1, "month").startOf("month");
        } else {
          dt = moment().subtract(1, "month").endOf("month");
        }
        break;
      case "thisyear":
        if (ftype === "from") {
          dt = moment().startOf("year");
        } else {
          dt = moment().endOf("year");
        }
        break;
      case "lastyear":
        if (ftype === "from") {
          dt = moment().subtract(1, "year").startOf("month");
        } else {
          dt = moment().subtract(1, "year").endOf("month");
        }
        break;
      default:
        dt = moment();
        break;
    }
    dt = moment(dt).format(dtformat);
    return dt;
  },

  /* Returns the date difference as formatedobject */
  getDateDiff: function (fdt, tdt, seconds = false, oneString = false) {
    var frmdt = new Date(fdt);
    var todt = new Date();
    if (tdt !== undefined) {
      // Consider "now" as todate
      todt = new Date(tdt);
    }
    if (frmdt === "Invalid Date" || todt === "Invalid Date") {
      return "";
    }
    var datediff = moment(todt).diff(frmdt, "seconds");
    var rslt = this.convDateToDiffObject(datediff);
    if (seconds) {
      if (oneString) {
        return this.getDateDiffString(rslt);
      }
      return datediff;
    }
    return rslt;
  },

  /* Converts seconds to string like "10h 20m 0 s" */
  convSecondsToString: function (secs) {
    let obj = this.convDateToDiffObject(secs);
    return this.getDateDiffString(obj);
  },

  /* Converts seconds to object of days,hours,minutes & secongs */
  convDateToDiffObject: function (totsecs) {
    var days = Math.floor(totsecs / 86400);
    var tothrs = totsecs % 86400;
    var hrs = Math.floor(tothrs / 3600);
    var totmins = tothrs % 3600;
    var mins = Math.floor(totmins / 60);
    var secs = totmins % 60;
    var rslt = {
      days: days,
      hrs: hrs,
      mins: mins,
      secs: secs,
    };
    return rslt;
  },

  /* Returns the "since date" as string */
  getDateSince: function (fdt, tdt) {
    var rslt = this.getDateDiff(fdt, tdt);
    if (rslt === "") {
      return rslt;
    }
    if (rslt.days > 0) {
      return rslt.days + " days";
    } else if (rslt.hrs > 0) {
      return rslt.hrs + " hours";
    } else if (rslt.mins > 0) {
      return rslt.mins + " minutes";
    } else {
      return rslt.secs + " seconds";
    }
  },

  /* Returns the "datediff" as string */
  getDateDiffOneString: function (obj) {
    const d = obj.days;
    const h = obj.hrs;
    const m = obj.mins;
    if (d > 0) {
      if (d === 1) {
        return "yesterday";
      } else {
        return d + " days ago";
      }
    } else if (h > 0) {
      return h + " hours ago";
    } else if (m > 0) {
      return m + " mins ago";
    } else {
      return "Just now";
    }
  },

  /* Returns the "datediff" as string */
  getDateDiffString: function (obj) {
    const d = obj.days;
    const h = obj.hrs;
    const m = obj.mins;
    const s = Math.round(Number(obj.secs));
    if (d > 0) {
      return d + "d " + h + "h " + m + "m " + (s > 0 ? s + "s" : "");
    } else if (h > 0) {
      return h + "h " + m + "m " + (s > 0 ? s + "s" : "");
    } else if (m > 0) {
      return m + "m " + s + "s";
    } else {
      return s + "s";
    }
  },

  getTimeInSeconds: (dt, miliseconds = false) => {
    let denominator = miliseconds ? 1 : 1000;
    return new Date(dt).getTime() / denominator;
  },

  getDateFromSeconds: (seconds, format = FORMATS.SQL_DATE) => {
    return moment(seconds).format(format);
  },

  getDaysOfMonth: () => {
    let yr = new Date().getFullYear();
    return new Date(yr, 2, 0).getDate();
  },

  getSubtractMonth: (dt, monthNum = 1, type = "from") => {
    if (type === "from") {
      return moment(dt)
        .subtract(monthNum, "month")
        .startOf("month")
        .format(FORMATS.SQL_DATE);
    } else {
      return moment(dt)
        .subtract(monthNum, "month")
        .endOf("month")
        .format(FORMATS.SQL_DATE);
    }
  },
};
