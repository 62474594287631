import { TRACK_ACTIONS } from "../actions/TrackActions";

let initialState = {
  trackInfo: {},
  placeInfo: {},
};

const trackReducer = (state = initialState, action) => {
  const { type, values } = action;
  switch (type) {
    case TRACK_ACTIONS.SET_TRACK_INFO:
      return { ...state, trackInfo: values.trackInfo };
    case TRACK_ACTIONS.SET_PLACE_INFO:
      return { ...state, placeInfo: values.placeInfo };
    default:
      break;
  }
  return state;
};

export default trackReducer;
