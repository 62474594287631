import React, { Component } from "react";
import { SwipeableDrawer, IconButton, Tooltip } from "@material-ui/core";
import { connect } from "react-redux";
import {
  AccountBoxSharp as DashboardIcon,
  LocalShipping as OwnVehiclesIcon,
  LocalShipping as MarketVehiclesIcon,
  LocationOn as PlacesIcon,
  PowerSettingsNew as LogoutIcon,
  Group as UsersIcon,
  Dashboard as AdminDashboardIcon,
} from "@material-ui/icons";

import UiDialog from "../../ui/dialogbox/UiDialog";
import PlacesGrid from "../PlacesGrid";

import { CONFIG, LABELS, LOCAL_STORAGE } from "../../../common/consts";
import { PAGE_ACTIONS } from "../../../actions/PageActions";
import { LOGIN_ACTIONS } from "../../../actions/LoginActions";
import { commonJs } from "../../../common/scripts";
import { PLACE_ACTIONS } from "../../../actions/PlaceActions";
import { API_HANDLER } from "../../../apihandlers";

class LeftBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      logoutDialog: false,
      fullScreen: false,
      activeTab: 0,
    };
  }

  componentDidMount() {
    const { childRef } = this.props;
    childRef(this);
    document.addEventListener(
      "fullscreenchange",
      this.onFullScreenChange,
      false
    );
    document.addEventListener(
      "mozfullscreenchange",
      this.onFullScreenChange,
      false
    );
    document.addEventListener(
      "MSFullscreenChange",
      this.onFullScreenChange,
      false
    );
    document.addEventListener(
      "webkitfullscreenchange",
      this.onFullScreenChange,
      false
    );
  }

  componentWillUnmount() {
    const { childRef } = this.props;
    childRef(undefined);
    document.removeEventListener(
      "fullscreenchange",
      this.onFullScreenChange,
      false
    );
    document.removeEventListener(
      "mozfullscreenchange",
      this.onFullScreenChange,
      false
    );
    document.removeEventListener(
      "MSFullscreenChange",
      this.onFullScreenChange,
      false
    );
    document.removeEventListener(
      "webkitfullscreenchange",
      this.onFullScreenChange,
      false
    );
  }

  onToggleLeftBar = () => {
    const { leftBar } = this.props.pageReducer;
    this.props.onToggleLeftBar({
      leftBar: !leftBar,
    });
  };

  toggleLogoutDialog = () => {
    const { logoutDialog } = this.state;
    this.setState({
      logoutDialog: !logoutDialog,
    });
  };

  toggleFullScreen = () => {
    const { fullScreen } = this.state;
    if (!fullScreen) {
      commonJs.openFullScreen();
    } else {
      commonJs.exitFullScreen();
    }
    this.setState({
      fullScreen: !fullScreen,
    });
  };

  onFullScreenChange = (e) => {
    const { fullScreen } = this.state;
    if (fullScreen && window.innerHeight !== window.screen.height) {
      this.setState({
        fullScreen: false,
      });
    } else {
      this.setState({
        fullScreen: true,
      });
    }
  };

  logout = () => {
    const self = this;
    API_HANDLER.logout().then((res) => {
      if (res.success) {
        self.props.setLogin({ login: false });
        localStorage.removeItem(LOCAL_STORAGE.ACCESS_TOKEN);
      }
    });
  };

  handleChangeTab = (event, val = 0) => {
    this.setState({
      activeTab: val,
    });
  };

  onMenuClick = (menu = CONFIG.PAGES.HOME) => {
    const { leftBar } = this.props.pageReducer;
    if (menu === CONFIG.PAGES.PLACES) {
      this.onToggleLeftBar();
    } else if (menu === CONFIG.PAGES.DASHBOARD) {
      const { userRole } = this.props.loginReducer;
      if (userRole === CONFIG.USER_ROLES.HR) {
        menu = CONFIG.PAGES.HR_DASHBOARD;
      }
    }
    this.props.setActiveMenu({
      activeMenu: menu,
    });
  };

  render() {
    const { logoutDialog } = this.state;
    const { varient, pageReducer, updateRegion, focusTrack, loginReducer } =
      this.props;
    const { leftBar, activeMenu } = pageReducer;
    const { userRole } = loginReducer;
    let dashboardMenu = commonJs.hasPrivilege(
      CONFIG.PRIVILEGES.DASHBOARD,
      userRole
    );
    let hrDashboardMenu = commonJs.hasPrivilege(
      CONFIG.PRIVILEGES.HR_DASHBOARD,
      userRole
    );

    const ownVehiclesMenu = false;
    const mktVehiclesMenu = false;
    const placesMenu = commonJs.hasPrivilege(
      CONFIG.PRIVILEGES.PLACE_MASTER,
      userRole
    );
    const hideDrawer =
      userRole === CONFIG.USER_ROLES.DRIVER
        ? true
        : activeMenu !== CONFIG.PAGES.PLACES;
    let usersMenu = commonJs.hasPrivilege(CONFIG.PRIVILEGES.USERS, userRole);
    console.log("Leftbar: ", dashboardMenu, placesMenu, activeMenu);
    return (
      <React.Fragment>
        <div className={"leftFixedMenu"}>
          <ul className="topmenu">
            {/* Dashboard */}
            {dashboardMenu && (
              <li onClick={() => this.onMenuClick(CONFIG.PAGES.DASHBOARD)}>
                <Tooltip title={LABELS.DASHBOARD} placement="right">
                  <IconButton
                    classes={{ root: "menucolor" }}
                    className={
                      activeMenu === CONFIG.PAGES.DASHBOARD ? "active" : ""
                    }
                  >
                    <AdminDashboardIcon />
                  </IconButton>
                </Tooltip>
              </li>
            )}

            {/* HR Dashboard */}
            {hrDashboardMenu && (
              <li onClick={() => this.onMenuClick(CONFIG.PAGES.HR_DASHBOARD)}>
                <Tooltip title={LABELS.DASHBOARD} placement="right">
                  <IconButton
                    classes={{ root: "menucolor" }}
                    className={
                      activeMenu === CONFIG.PAGES.HR_DASHBOARD ? "active" : ""
                    }
                  >
                    <DashboardIcon />
                  </IconButton>
                </Tooltip>
              </li>
            )}

            {/* Users */}
            {usersMenu && (
              <li onClick={() => this.onMenuClick(CONFIG.PAGES.USERS)}>
                <Tooltip title={LABELS.USERS} placement="right">
                  <IconButton
                    classes={{ root: "menucolor" }}
                    className={
                      activeMenu === CONFIG.PAGES.USERS ? "active" : ""
                    }
                  >
                    <UsersIcon />
                  </IconButton>
                </Tooltip>
              </li>
            )}

            {/* Own Vehicles */}
            {ownVehiclesMenu && (
              <li onClick={() => this.onMenuClick(CONFIG.PAGES.OWN_VEHICLES)}>
                <Tooltip title={LABELS.OWN_VEHICLES} placement="right">
                  <IconButton
                    classes={{ root: "menucolor" }}
                    className={
                      activeMenu === CONFIG.PAGES.OWN_VEHICLES ? "active" : ""
                    }
                  >
                    <OwnVehiclesIcon />
                  </IconButton>
                </Tooltip>
              </li>
            )}
            {/* Market Vehicles */}
            {mktVehiclesMenu && (
              <li
                onClick={() => this.onMenuClick(CONFIG.PAGES.MARKET_VEHICLES)}
              >
                <Tooltip title={LABELS.MARKET_VEHICLES} placement="right">
                  <IconButton
                    classes={{ root: "menucolor" }}
                    className={
                      activeMenu === CONFIG.PAGES.MARKET_VEHICLES
                        ? "active"
                        : ""
                    }
                  >
                    <MarketVehiclesIcon />
                  </IconButton>
                </Tooltip>
              </li>
            )}
            {/* Places */}
            {placesMenu && (
              <li onClick={() => this.onMenuClick(CONFIG.PAGES.PLACES)}>
                <Tooltip title={LABELS.PLACES} placement="right">
                  <IconButton
                    classes={{ root: "menucolor" }}
                    className={
                      activeMenu === CONFIG.PAGES.PLACES
                        ? leftBar
                          ? "active hasLeftBar"
                          : "active"
                        : ""
                    }
                  >
                    <PlacesIcon />
                  </IconButton>
                </Tooltip>
              </li>
            )}
          </ul>
          <ul className="bottommenu">
            <li>
              <Tooltip title={LABELS.LOGOUT} placement="right">
                <IconButton
                  classes={{ root: "menucolor" }}
                  onClick={this.toggleLogoutDialog}
                >
                  <LogoutIcon />
                </IconButton>
              </Tooltip>
            </li>
          </ul>
        </div>

        <SwipeableDrawer
          open={!hideDrawer}
          onClose={this.onToggleLeftBar}
          onOpen={this.onToggleLeftBar}
          variant={varient}
          className={"leftdrawer"}
          classes={{ paper: "leftdrawer-menu" }}
          BackdropProps={{
            invisible: true,
          }}
          hideBackdrop={true}
          tabIndex={9}
        >
          <PlacesGrid
            childRef={(ref) => (this._placesGrid = ref)}
            focusTrack={focusTrack}
            updateRegion={updateRegion}
          />
        </SwipeableDrawer>

        <UiDialog
          open={logoutDialog}
          onCloseSettings={this.toggleLogoutDialog}
          title={LABELS.LOGOUT}
          maxWidth="sm"
          fullWidth={true}
          saveButton={true}
          cancelButton={true}
          cancelAction={this.toggleLogoutDialog}
          saveAction={this.logout}
          saveBtnLabel="OK"
          classNameWrapper="logoutWrapper"
        >
          <p>{"Are you sure want to logout..?"}</p>
        </UiDialog>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    mapReducer: state.mapReducer,
    loginReducer: state.loginReducer,
    pageReducer: state.pageReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onToggleLeftBar: (values) => {
      dispatch({
        type: PAGE_ACTIONS.SET_LEFT_MENU,
        values,
      });
    },
    setLogin: (values) => {
      dispatch({
        type: LOGIN_ACTIONS.SET_LOGIN,
        values,
      });
    },
    setPlaceMaster: (values) => {
      dispatch({
        type: PLACE_ACTIONS.SET_PLACE_MASTER,
        values,
      });
    },
    setActiveMenu: (values) => {
      dispatch({
        type: PAGE_ACTIONS.SET_ACTIVE_MENU,
        values,
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LeftBar);
