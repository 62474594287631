import React, { Component } from "react";
import {
  SortingState,
  IntegratedSorting,
  PagingState,
  CustomPaging,
} from "@devexpress/dx-react-grid";
import {
  Grid,
  VirtualTable,
  TableHeaderRow,
  TableColumnResizing,
  PagingPanel,
} from "@devexpress/dx-react-grid-material-ui";
import { IconButton, Tooltip, CircularProgress } from "@material-ui/core";
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Replay as ReloadIcon,
  AddCircle as AddIcon,
} from "@material-ui/icons";
import { connect } from "react-redux";

import ConfirmDialog from "./ConfirmDialog";
import FormDialog from "./FormDialog";
import Txt from "../../ui/textbox/Txt";

import { API_HANDLER } from "../../../apihandlers";
import { CONFIG, DEFAULTS } from "../../../common/consts";
import { PLACE_ACTIONS } from "../../../actions/PlaceActions";
import { MAP_ACTIONS } from "../../../actions/MapActions";
import { PAGE_ACTIONS } from "../../../actions/PageActions";
import { MAP_FORM_ACTIONS } from "../../../actions/MapFormActions";

class UiDataGrid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [
        {
          name: "slno",
          title: "Sl No.",
          sortingEnabled: true,
        },
        {
          name: "placeName",
          title: "Place Name",
          sortingEnabled: true,
        },
        { name: "options", title: "Options", sortingEnabled: false },
      ],
      defaultSorting: [{ columnName: "slno", direction: "asc" }],
      pageNo: 0,
      loading: true,
      totalRows: 0,
    };

    this._tableColumnWidths = [
      {
        columnName: "slno",
        width: 100,
      },
      { columnName: "placeName", width: 200 },
      { columnName: "options", width: 80 },
    ];

    this._tableColumnExtensions = [
      {
        columnName: "slno",
        align: "center",
      },
      { columnName: "placeName", align: "left" },
      { columnName: "options", align: "center" },
    ];

    this._sortingDisabledColumns = [
      // { columnName: "jd_doc_description", sortingEnabled: false },
    ];

    this._timer = null;
  }

  componentDidMount() {
    this.getStateList();
  }

  componentWillUnmount() {
    this._timer = null;
    clearTimeout(this._timer);
  }

  getStateList = () => {
    const self = this;
    const args = {
      pageNo: 1,
      pageLength: DEFAULTS.PAGE_LENGTH,
      searchQry: "",
      sortingOrder: DEFAULTS.SORTING_ORDER,
    };
    API_HANDLER.getStatesList(args).then((res) => {
      if (res.success) {
        const { records } = res.response;
        self.props.setStates({
          stateList: records,
        });
      }
      self.getCustomersList();
    });
  };

  getCustomersList = () => {
    const self = this;
    const args = {
      pageNo: 1,
      pageLength: DEFAULTS.PAGE_LENGTH,
      searchQry: "",
      sortingOrder: DEFAULTS.SORTING_ORDER,
    };
    API_HANDLER.getCustomersList(args).then((res) => {
      if (res.success) {
        const { records } = res.response;
        self.props.setCustomers({
          customerList: records.map((r) => {
            return {
              id: r.customerId,
              name: r.customerName,
              code: r.customerCode,
              prefix: r.partyPlaceCode,
            };
          }),
        });
      }
      self.loadRecords();
      self.setState({
        loading: false,
      });
    });
  };

  reloadData = () => {
    // reload states, customers
    this.getStateList();
  };

  loadRecords = (reset = false) => {
    const { pageNo, defaultSorting } = this.state;
    const args = {
      pageNo: reset ? 1 : pageNo + 1,
      pageLength: CONFIG.PAGE_LENGTH,
      searchQry: "", // "" => Dont Search (Search by place name)
      sortingOrder: defaultSorting[0].direction, // ( Sort by place name )
    };
    if (document.getElementById("placeSearch")) {
      args.searchQry = document
        .getElementById("placeSearch")
        .value.toUpperCase();
    }

    this.props.setLoading({
      loading: true,
    });
    const self = this;
    API_HANDLER.getPlaceMaster(args).then((res) => {
      self.props.setLoading({
        loading: false,
      });
      console.log("getPlaceMaster: ", args, res);
      if (res.success) {
        const { records, totalRows } = res.response;
        self.props.setPlaceMaster({
          placeMaster: records,
        });
        self.setState({
          totalRows: totalRows,
        });
      }
    });
  };

  generateOptions = (record) => {
    return (
      <div className="options">
        <Tooltip
          title="Edit"
          placement="bottom"
          onClick={() => this.editRecord(record)}
        >
          <IconButton className="dataGridIcon">
            <EditIcon />
          </IconButton>
        </Tooltip>
        {/* <Tooltip
          title="Delete"
          placement="bottom"
          onClick={() => this.deleteRecord(record)}
        >
          <IconButton className="dataGridIcon">
            <DeleteIcon />
          </IconButton>
        </Tooltip> */}
      </div>
    );
  };

  editRecord = (rec = {}) => {
    console.log("editRecord: zoom:", rec);
    const { lat, lng } = rec.location;
    const self = this;
    this.props.setLoading({ loading: true });
    this.props.setTmpMarker({ tmpMarker: { lat, lng } });
    this.props.setPlaceInfo({ placeInfo: rec });
    this.props.updateRegion(lat, lng, DEFAULTS.PLACE_ZOOM);
    this.props.setConsignee({
      selectedConsignee: {},
    });
    // Get Consignee List
    if (rec.customerId) {
      API_HANDLER.getConsigneeList({
        customerId: rec.customerId,
      }).then((res) => {
        if (res.success) {
          self.props.setConsignees({
            consigneeList: res.response,
          });
        }
        // show dialog => loading false
        self._formDialog.showDialog();
        setTimeout(() => {
          self.props.setLoading({ loading: false });
        }, 1000);
      });
    } else {
      // show dialog => loading false
      self._formDialog.showDialog();
      setTimeout(() => {
        self.props.setLoading({ loading: false });
      }, 1000);
    }
  };

  deleteRecord = ({ placeId, placeName }) => {
    this._confirmDialog.setData({
      id: placeId,
      name: placeName,
    });
    this._confirmDialog.showDialog();
  };

  onDelete = (id = -1) => {
    console.log("onDelete: ", id);
  };

  onAddPlace = () => {
    // Reset MapForm
    this.props.resetMapForm({
      selectedCity: {},
      selectedState: {},
      selectedCustomer: {},
      selectedConsignee: {},
    });
    this.props.setTmpMarker({ tmpMarker: {} });
    this.props.setPlaceInfo({ placeInfo: {} });

    const self = this;
    setTimeout(() => {
      self._formDialog.showDialog();
    }, 250);
  };

  onPlaceSearch = (e) => {
    // const searchValue = e.target.value.trim();
    clearTimeout(this._timer);
    const self = this;
    this._timer = setTimeout(() => {
      // reload list
      self.reloadData();
    }, 500);
  };

  onGridSorting = (data) => {
    const self = this;
    this.setState(
      {
        defaultSorting: data,
      },
      () => {
        self.reloadData();
      }
    );
  };

  setCurrentPage = (pageNo = 0) => {
    const self = this;
    this.setState(
      {
        pageNo: pageNo,
      },
      () => {
        self.loadRecords();
      }
    );
  };

  render() {
    const { columns, pageNo, loading, defaultSorting, totalRows } = this.state;
    const { updateRegion, placeReducer } = this.props;
    const { placeMaster } = placeReducer;
    let rows = placeMaster;
    for (let i = 0; i < rows.length; i++) {
      rows[i].slno = pageNo * CONFIG.PAGE_LENGTH + (i + 1);
      rows[i].options = this.generateOptions(rows[i]);
    }
    let gridHeight = window.innerHeight * 0.5;

    return loading ? (
      <div className="loadingGif">
        <CircularProgress />
      </div>
    ) : (
      <div className="dataRGrid">
        <h4 className="frmGroupTitle">Places</h4>
        <div style={{ display: "flex", marginBottom: 10, marginTop: 10 }}>
          <div style={{ width: "80%" }}>
            <Txt
              id="placeSearch"
              placeHolder={"Search Place"}
              onKeyPress={this.onPlaceSearch}
            />
          </div>
          <div>
            <Tooltip title={"Reload"} placement="bottom">
              <IconButton className="iconBtn" onClick={this.reloadData}>
                <ReloadIcon />
              </IconButton>
            </Tooltip>
          </div>
          <div>
            <Tooltip title={"Add Place"} placement="bottom">
              <IconButton className="iconBtn" onClick={this.onAddPlace}>
                <AddIcon />
              </IconButton>
            </Tooltip>
          </div>
        </div>

        <Grid rows={rows} columns={columns}>
          <PagingState
            currentPage={pageNo}
            onCurrentPageChange={this.setCurrentPage}
            pageSize={2}
          />
          <CustomPaging totalCount={totalRows} />

          <SortingState
            defaultSorting={defaultSorting}
            columnSortingEnabled={true}
            columnExtensions={[
              { columnName: "options", sortingEnabled: false },
            ]}
            onSortingChange={this.onGridSorting}
          />
          <IntegratedSorting />

          <VirtualTable
            columnExtensions={this._tableColumnExtensions || []}
            height={gridHeight}
          />

          <TableColumnResizing
            defaultColumnWidths={this._tableColumnWidths || []}
          />

          <TableHeaderRow showSortingControls={true} />

          <PagingPanel />
        </Grid>

        <ConfirmDialog
          childRef={(ref) => (this._confirmDialog = ref)}
          onDelete={this.onDelete}
        />

        <FormDialog
          childRef={(ref) => (this._formDialog = ref)}
          updateRegion={updateRegion}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    placeReducer: state.placeReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setPlaceMaster: (values) => {
      dispatch({
        type: PLACE_ACTIONS.SET_PLACE_MASTER,
        values,
      });
    },
    setPlaceInfo: (values) => {
      dispatch({
        type: PLACE_ACTIONS.SET_PLACE_INFO,
        values,
      });
    },
    setTmpMarker: (values) => {
      dispatch({
        type: MAP_ACTIONS.SET_TMP_MARKER,
        values,
      });
    },
    setLoading: (values) => {
      dispatch({
        type: PAGE_ACTIONS.SET_LOADING,
        values,
      });
    },
    setStates: (values) => {
      dispatch({
        type: MAP_FORM_ACTIONS.SET_STATES,
        values,
      });
    },
    setCustomers: (values) => {
      dispatch({
        type: MAP_FORM_ACTIONS.SET_CUSTOMERS,
        values,
      });
    },
    setConsignees: (values) => {
      dispatch({
        type: MAP_FORM_ACTIONS.SET_CONSIGNEES,
        values,
      });
    },
    setConsignee: (values) => {
      dispatch({
        type: MAP_FORM_ACTIONS.SET_SELECTED_CONSIGNEE,
        values,
      });
    },
    setCityState: (values) => {
      dispatch({
        type: MAP_FORM_ACTIONS.SET_CITY_STATE,
        values,
      });
    },
    resetMapForm: (values) => {
      dispatch({
        type: MAP_FORM_ACTIONS.RESET_MAP_FORM,
        values,
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UiDataGrid);
