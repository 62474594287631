import React, { Component } from "react";
import { CircularProgress, Tabs, Tab } from "@material-ui/core";

import LeftBar from "../../Layouts/leftbar/LeftBar";
import UsersGrid from "../Users/UsersGrid";

class Users extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      activeTab: 0,
    };
  }

  componentDidMount() {
    console.log("Mount");
  }

  onTabChange = (e, val) => {
    this.setState({
      activeTab: val,
    });
  };

  render() {
    const { loading, activeTab } = this.state;
    return (
      <div id="adminDashboard">
        <div style={{ width: "95%", margin: "auto" }}>
          <h4
            style={{
              fontSize: 24,
              margin: 0,
              paddingTop: 25,
              paddingBottom: 15,
            }}
          >
            {"Users"}
          </h4>
        </div>

        {/* Menus */}
        {loading ? (
          <div
            style={{
              width: "100%",
              height: 300,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress className={"primary"} size="1.5rem" />
          </div>
        ) : (
          <div style={{ width: "100%", margin: "auto", paddingLeft: "2.5%" }}>
            <Tabs
              value={activeTab}
              indicatorColor="primary"
              textColor="primary"
              onChange={this.onTabChange}
              className="uiTab"
            >
              <Tab label="Users" />
            </Tabs>
            {activeTab === 0 && <UsersGrid />}
          </div>
        )}
      </div>
    );
  }
}

export default Users;
