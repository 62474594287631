import React from "react";

import "./style.css";

export const PrivacyPolicy = () => {
  return (
    <div
      id="container"
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
      }}
    >
      <h1 style={{ textAlign: "center", width: "100%" }}>Privacy Policy</h1>
      <p style={{ marginTop: 15 }}>
        We, regards your information significant and release this Privacy
        Statement to inform you what information we collect and how we use it to
        personalize and continually improve you experience.
      </p>

      <p>
        <strong>
          <span>Information We Collect</span>
        </strong>
      </p>

      <ol type="a">
        <li>
          Personal Information. We do not collect Personal Information "Personal
          Information" is information that identifies you or another person,
          such as your first name and last name, physical addresses, email
          addresses, telephone, fax, SSN, information stored within your device.
        </li>

        <li>
          Non-personal Information. We collect your non-personal information
          when you visit our website. Information you provide. We may collect
          your information when you communicate with us or you give us.
        </li>

        <li>We are only getting your mobile device Id.</li>
      </ol>

      <p>
        <strong>
          <span>How We Use Information</span>
        </strong>
      </p>

      <ol type="a">
        <li>
          Personal Information. We do not store Personal Information and
          therefore we do not disclose your Personal Information.
        </li>
        <li>
          Non-Personal Information. We do not sell, trade, or otherwise transfer
          to outside parties your information. We do not combine Non-Personal
          Information with Personal Information (such as combining your name
          with your unique User Device number).
        </li>
        <li>
          We will use Device Number for updating/deleting GCMkey from our app's
          database.
        </li>
        <li>
          Legal Reasons We will access, use or disclose your information with
          other organizations or entities for reasons that required by any
          applicable law, regulation, legal process or enforceable governmental
          request; detect, prevent, or otherwise fraud, security or technical
          issues; protect against harm to the rights, property or safety of
          <b>our company</b>, our users or the public as required or permitted
          by law.
        </li>
      </ol>

      <p>
        <strong>
          <span>Security</span>
        </strong>
      </p>
      <p>
        <b>Our company</b> is very concerned about safeguarding the
        confidentiality of your information. We do not collect Personal
        Information, and we employ administrative, physical and electronic
        measures designed to protect your Non-Personal Information from
        unauthorized access and use. Please be aware that no security measures
        that we take to protect your information is absolutely guaranteed to
        avoid unauthorized access or use of your Non-Personal Information which
        is impenetrable.
      </p>

      <p>
        <strong>
          <span>Sensitive Information</span>
        </strong>
      </p>
      <p>
        We ask that you not send us, and you not disclose, any sensitive
        Personal Information (e.g., information related to racial or ethnic
        origin, political opinions, religion or other beliefs, health, sexual
        orientation, criminal background or membership in past organizations,
        including trade union memberships) on or through an Application, the
        Services or the Site or otherwise to us.
      </p>

      <p>
        <strong>
          <span>Children</span>
        </strong>
      </p>
      <p>
        We do not provide service focus on Children. If you are under 18, you
        may use our website when you are with a parent or guardian.
      </p>

      <p>
        <strong>
          <span>Changes</span>
        </strong>
      </p>
      <p>
        Our Privacy Policy may change which will not reduce your rights under
        this Privacy Policy from time to time, we will post any privacy policy
        changes on this page, so please review it periodically. If you do not
        agree to any modifications to this Policy, your could immediately stop
        all use of all the Services. Your continued use of the Site following
        the posting of any modifications to this Policy will constitute your
        acceptance of the revised Policy. Please note that none of our employees
        or agents has the authority to vary any of our Policies.
      </p>
      <br />
      <br />
    </div>
  );
};
