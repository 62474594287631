import React, { Component } from "react";
import { connect } from "react-redux";
import { withSnackbar } from "notistack";

import Btn from "../../ui/button/Btn";
import AutoComplete from "../../ui/autocomplete/AutoComplete";

import { TRACK_ACTIONS } from "../../../actions/TrackActions";
import { API_HANDLER } from "../../../apihandlers";
import { PAGE_ACTIONS } from "../../../actions/PageActions";
import { commonJs } from "../../../common/scripts";
import { NOTIFY } from "../../../common/consts";

class TrackBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      vehicleList: [],
      selectedVehicle: {},
    };
  }

  componentDidMount() {
    const self = this;
    API_HANDLER.getVehicleList().then((res) => {
      if (res.success) {
        self.setState({
          vehicleList: res.result,
        });
      }
    });
  }

  onChangeVehicle = (val) => {
    let v = val;
    if (!val) {
      v = {};
    }
    this.setState({
      selectedVehicle: v,
    });
  };

  getTrack = () => {
    const { selectedVehicle } = this.state;
    const args = {
      cmd: "gettrack",
      key: "65482a2f0a1b49718195999500f96df2",
      fdt: document.getElementById("trackFdt").value,
      tdt: document.getElementById("trackTdt").value,
      imei: selectedVehicle.id || "",
    };

    if (args.imei === "") {
      commonJs.showNotify(
        this.props,
        NOTIFY.WARNING,
        "Please select vehicle..!"
      );
      return false;
    }

    if (args.fdt === "" || args.tdt === "") {
      commonJs.showNotify(
        this.props,
        NOTIFY.WARNING,
        "Please select date range..!"
      );
      return false;
    }

    const self = this;
    this.props.setLoading({ loading: true });
    API_HANDLER.getTrack(args)
      .then((res) => {
        self.props.setLoading({ loading: false });
        if (res.success) {
          if (res.result.route.length === 0) {
            commonJs.showNotify(self.props, NOTIFY.ERROR, "Track not found..!");
          }
          self.props.setTrackInfo({
            trackInfo: res.result,
          });
          // Focus in Map
          self.props.focusTrack();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  resetTrack = () => {
    this.props.setTrackInfo({
      trackInfo: {},
    });
  };

  render() {
    const { vehicleList, selectedVehicle } = this.state;
    return (
      <div
        className="formGroup"
        style={{ marginTop: 10, paddingLeft: 5, paddingRight: 5 }}
      >
        <h4 className="frmGroupTitle">{"Vehicle Track"}</h4>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: 10,
          }}
        >
          <div style={{ width: "100%" }}>
            <AutoComplete
              id="vehicleList"
              label={"Select Vehicle"}
              style={{ width: "100%" }}
              list={vehicleList}
              defaultValue={selectedVehicle}
              multiple={false}
              size="small"
              onChange={this.onChangeVehicle}
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: 10,
            marginBottom: 15,
          }}
        >
          <div
            style={{
              width: "45%",
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            <input type={"date"} id="trackFdt" />
          </div>
          <div
            style={{
              width: "45%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <input type={"date"} id="trackTdt" />
          </div>
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-start",
          }}
        >
          <Btn className={"primaryBtn mR15"} onClick={this.getTrack}>
            {"Track"}
          </Btn>
          <Btn className={"defaultBtn"} onClick={this.resetTrack}>
            {"Reset"}
          </Btn>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    setTrackInfo: (values) => {
      dispatch({
        type: TRACK_ACTIONS.SET_TRACK_INFO,
        values,
      });
    },
    setLoading: (values) => {
      dispatch({
        type: PAGE_ACTIONS.SET_LOADING,
        values,
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(TrackBox));
